import { Component, NgZone, AfterViewInit, EventEmitter, Output, Input } from '@angular/core';
// Record token
import { AuthService } from 'app/shared/auth/auth.service';

// Pop Up Alert
import Swal from 'sweetalert2';
import { ResizeService } from 'app/shared/services/resize.service';
import { CashierNumberPadInfo } from '../model/pos';

@Component({
    selector: 'app-cashier-number-pad',
    templateUrl: './cashier-number-pad.component.html',
    styleUrls: ['./cashier-number-pad.component.scss']
})

export class CashierNumberPadComponent implements AfterViewInit {
    @Input() total: number = 0;
    @Input() tenderAmount: number = 0;
    @Input() balance: number = 0;
    @Output() checkout: EventEmitter<CashierNumberPadInfo>;
    isEnableCheckout = false;

    constructor() {
        this.checkout = new EventEmitter<CashierNumberPadInfo>();
    }

    ngAfterViewInit() {

        console.log('CashierNumberPadComponent total: ' + this.total);
    }

    onCheckout(data) {
        let cashierInfo = new CashierNumberPadInfo({
            Tender: data,
            Total: this.total,
            Balance: -(this.total - this.tenderAmount)
        });
        this.checkout.next(cashierInfo);
    }

    updateTenderAmount(newAmount) {
        this.tenderAmount = newAmount
        this.balance = -(this.total - this.tenderAmount);   // Show balance in Negative

        if(this.balance < 0) {
            this.isEnableCheckout = false;
        } else {
            this.isEnableCheckout = true;
        }
    }
}
