import { Component, Output, EventEmitter, OnDestroy, OnInit, AfterViewInit, ChangeDetectorRef, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from '../services/layout.service';
import { Subscription, Observable } from 'rxjs';
import { ConfigService } from '../services/config.service';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { CompanyInfo } from '../model/company';
import { CompanyService } from '../services/company.service';
import { LocalStorageType } from '../model/system';
import { MemberService } from '../services/member.service';
import { ResizeService } from '../services/resize.service';

@Component({
  selector: "app-navbar-shop",
  templateUrl: "./navbar-shop.component.html",
  styleUrls: ["./navbar-shop.component.scss"]
})
export class NavbarShopComponent implements OnInit, AfterViewInit, OnDestroy {
  currentLang = "en";
  toggleClass = "ft-maximize";
  placement = "bottom-right";
  public isCollapsed = true;
  layoutSub: Subscription;
  @Output()
  toggleHideSidebar = new EventEmitter<Object>();

  // Accept input trigger
  @Input()
  showSidebar = false;

  public companies: CompanyInfo[];
  selectedCompanyName: string;
  username: string;

  browserLang: string;

  public config: any = {};

  constructor(public translate: TranslateService, private layoutService: LayoutService, private configService: ConfigService, private router: Router, private authService: AuthService,
    private cmpServ: CompanyService,
    private mmbrServ: MemberService,
    private cdr: ChangeDetectorRef,
    public resizeService: ResizeService) {
    // const browserLang: string = translate.getBrowserLang();

    this.browserLang = localStorage.getItem(LocalStorageType.Language)

    if (!this.browserLang) {
      this.browserLang = "en";
    }

    translate.use(this.browserLang.match(/en|es|pt|de|cn|my/) ? this.browserLang : "en");

    this.layoutSub = layoutService.changeEmitted$.subscribe(
      direction => {
        const dir = direction.direction;
        if (dir === "rtl") {
          this.placement = "bottom-left";
        } else if (dir === "ltr") {
          this.placement = "bottom-right";
        }
      });

    this.username = this.authService.getMember() ? this.authService.getMember().MemberName : null;
  }

  ngOnInit() {
    this.config = this.configService.templateConf;
  }

  ngAfterViewInit() {
    if (this.config.layout.dir) {
      setTimeout(() => {
        const dir = this.config.layout.dir;
        if (dir === "rtl") {
          this.placement = "bottom-left";
        } else if (dir === "ltr") {
          this.placement = "bottom-right";
        }
      }, 0);

    }

    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
  }

  ChangeLanguage(language: string) {
    localStorage.setItem(LocalStorageType.Language, language)

    this.browserLang = language;

    this.translate.use(language);
  }

  ToggleClass() {
    if (this.toggleClass === "ft-maximize") {
      this.toggleClass = "ft-minimize";
    } else {
      this.toggleClass = "ft-maximize";
    }
  }

  toggleNotificationSidebar() {
    this.layoutService.emitNotiSidebarChange(true);
  }

  toggleSidebar() {
    const appSidebar = document.getElementsByClassName("app-sidebar")[0];
    if (appSidebar.classList.contains("hide-sidebar")) {
      this.toggleHideSidebar.emit(false);
    } else {
      this.toggleHideSidebar.emit(true);
    }
  }

  Logout() {
    this.authService.shopLogout();
  }
}
