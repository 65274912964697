// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  environmentName: 'Shop',
  firebase: {
    apiKey: "AIzaSyA4VfEyBv_e8XltqW3G7tJV9gsNC1sbSB8",
    authDomain: "nextdatasolutionsfree.firebaseapp.com",
    databaseURL: "https://nextdatasolutionsfree.firebaseio.com",
    projectId: "nextdatasolutionsfree",
    storageBucket: "nextdatasolutionsfree.appspot.com",
    messagingSenderId: "508925957990",
    appId: "1:508925957990:web:f525c0d769d9034a489aa7"
  },
  serverURL:  "https://app.cloudbiz.asia",//"http://localhost:8085", //
  eCommLocalCheck: ":4700",
  isoLocalCheck: ":4701",
  devLocalCheck: ":4702",
  appLocalCheck: ":4703",
  eCommWebCheck: "https://shop.cloudbiz.asia",
  isoWebCheck: "https://iso.cloudbiz.asia",
  devWebCheck: "https://dorsett68.ddns.net",
  appWebCheck: "https://app.cloudbiz.asia",
  paypalID: "Af_b5PV1RZlVA1QwLUlY-XEnm0FH7tYqyfZWkfIt2gFAsbdB8yquMDTfdp1R7QSSFDXYP68fqwJpq5eW"
};
