import { Component, Output, EventEmitter, Input, ViewChild, NgZone } from '@angular/core';
import { GlobalService } from 'app/shared/services/global.service';
import { CompanyService } from 'app/shared/services/company.service';
import { AuthService } from 'app/shared/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyInfo, CompanyMember, Customer } from 'app/shared/model/company';
import { Member } from '../model/member';
import { MemberService } from '../services/member.service';
import { CommonFunction } from '../common/common_function';
import { LocalStorageType } from '../model/system';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-mobile-join-member',
  templateUrl: './mobile-join-member.component.html',
  styleUrls: ['./mobile-join-member.component.scss']
})

export class MobileJoinMemberComponent {
  member: Member = new Member();

  @Input() company: CompanyInfo;

  isLogging = false;

  ngOnInit() {
  }

  constructor(private router: Router,
    private globalSrv: GlobalService,
    private companyService: CompanyService,
    public authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private memberService: MemberService,
    private translate: TranslateService,
    private ngZone: NgZone) {

  }

  // On submit button click
  async onSubmit() {
    try {
      if (!this.authService.getMember().MemberName) {
        throw 'Please login.'
      }

      if (!this.company.CompanyID) {
        throw 'Invalid company info. Please retry.'
      }

      this.isLogging = true;
      var newMember = new CompanyMember({
        MemberInfo: this.authService.getMember(),
        CompanyID: this.company.CompanyID,
      });

      let addMemberResult = await this.companyService.AddMember(newMember).toPromise().catch(error => {
        throw error;
      })

      if(!addMemberResult?.CompanyMemberID) {
        throw "Failed to join member. Please try again."
      }
      
      await Swal.fire({
        icon: 'success',
        text: this.translate.instant("Member joined successfully!"),
        timer: 1000,
        timerProgressBar: true,
        confirmButtonText: this.translate.instant('Button.Confirm')
      });

      this.ngZone.run(() => window.location.reload())

    } catch (e) {
      Swal.fire({
        icon: 'error',
        title: this.translate.instant('Error'),
        html: this.translate.instant(e)
      });

      this.isLogging = false;
    }
  }
}