import { Component, Output, EventEmitter, Input, ViewChild, NgZone, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { CommonFunction } from '../common/common_function';
import { LanguageData, LocalStorageType } from '../model/system';

@Component({
  selector: 'app-gallery-uploader',
  templateUrl: './gallery-uploader.component.html',
  styleUrls: ['./gallery-uploader.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GalleryUploaderComponent),
      multi: true
    }
  ]
})

// https://medium.com/@majdasab/implementing-control-value-accessor-in-angular-1b89f2f84ebf
export class GalleryUploaderComponent implements ControlValueAccessor {

  croppedImage: any = '';
  data: any;
  isImageUpdate = false;
  hasImage = false;
  filePickerData: any;
  filePickerURL: string;

  isLoadingImage = false;

  imageSequence: number;
  imageTitle: string;
  imageDescription: string;

  imageCropped(event: ImageCroppedEvent) {
    this.data = event.base64
  }

  resetImageCropper() {
    this.currentImage = null;
    this.data = null;
    this.filePickerURL = null;
    this.filePickerData = null;
    this.hasImage = false;
    this.isImageUpdate = false;
    this.imageSequence = this.val.length + 1;
    this.imageTitle = null;
    this.imageDescription = null;
  }
  

  currentImage: ImageModel;
  // @Input() imageList: ImageModel[] = [];
  @Input() aspectRatio: number;
  @Input() resizeToWidth: number;
  @Input() resizeToHeight: number;
  @Input() label: string;
  @Input() canEdit: boolean;
  @Input() isCirclePreview: boolean = true;
  @Input() maxImageCount: number = 20;
  @Input() enableImageTitle: boolean = true;
  @Input() enableImageDescription: boolean = true;
  @Input() isFullscreenThumbnail: boolean = false;

  disabled: boolean;

  constructor() {
  }

  onChange: any = () => { }
  onTouch: any = () => { }

  val: ImageModel[] = [] // this is the updated value that the class accesses
  set value(val) {  // this value is updated by programmatic changes if( val !== undefined && this.val !== val){
    if (val !== undefined && this.val !== val) {
      this.val = val

      this.onChange(val)
      this.onTouch(val)
    }
  }

  // this method sets the value programmatically
  writeValue(value: any): void {
    this.value = value
  }

  // upon UI element value changes, this method gets triggered
  registerOnChange(fn: any): void {
    this.onChange = fn
  }

  // upon touching the element, this method gets triggered
  registerOnTouched(fn: any): void {
    this.onTouch = fn
  }

  /**    
  * This function is called when the control status changes to or from "DISABLED".
  * Depending on the value, it will enable or disable the appropriate DOM element.
  * @param isDisabled
  */
  setDisabledState?(isDisabled: boolean): void {
  }

  fileChangeListener($event) {
    this.resetImageCropper();

    this.isLoadingImage = true;

    var image: any = new Image();
    var file: File = $event.target.files[0];

    this.isImageUpdate = true;

    var myReader: FileReader = new FileReader();
    var that = this;
    myReader.onloadend = function (loadEvent: any) {

      image.src = loadEvent.target.result;
      that.filePickerData = image.src;

      if (JSON.stringify(image.src) != '{}') {
        that.hasImage = true;
      }
    };

    myReader.readAsDataURL(file);
  }

  loadImageFailed() {
    this.isLoadingImage = false;
  }

  imageLoaded() {
    this.isLoadingImage = false;
  }

  deleteImage() {
    let index = this.val.findIndex(x => x.ImageID == this.currentImage.ImageID);

    this.val.splice(index, 1);

    this.resetImageCropper();
  }

  addImageToList() {
    if (this.imageSequence <= 0) {
      this.imageSequence = 1;
    } else if (this.imageSequence > this.val.length + 1) {
      this.imageSequence = this.val.length + 1;
    }

    if (this.currentImage) {
      let foundImage = this.val.find(x => x.ImageID == this.currentImage.ImageID);
      let foundImageIndex = this.val.findIndex(x => x.ImageID == this.currentImage.ImageID);

      foundImage.ImageData = this.data
      foundImage.Sequence = this.imageSequence;
      foundImage.Title = this.imageTitle;
      foundImage.Description = this.imageDescription;

      // Check sequence if need to shuffle the sequence
      if ((foundImageIndex + 1) != foundImage.Sequence) {
        this.val.splice(foundImageIndex, 1);

        // Put image at desired location
        this.val.splice(this.imageSequence - 1, 0, foundImage);
      } else {
        this.val.push(foundImage);
      }

      // Update image sequence
      this.updateImagesSequence();

    } else {
      let newImage = new ImageModel({
        ImageData: this.data,
        Title: this.imageTitle,
        Description: this.imageDescription,
        Sequence: this.imageSequence
      })

      if (this.imageSequence != this.val.length + 1) {
        // Put image at desired location
        this.val.splice(this.imageSequence - 1, 0, newImage);

        // Update image sequence
        this.updateImagesSequence();
      } else {
        this.val.push(newImage);
      }

    }

    this.onChange(this.val);

    // Clear out the preview data
    this.resetImageCropper();
  }

  updateImagesSequence() {
    let seq = 1;

    this.val.forEach(x => {
      x.Sequence = seq;
      seq++;
    });
  }

  editImage(img: ImageModel) {
    this.isImageUpdate = true;
    this.currentImage = img;
    this.imageSequence = img.Sequence;
    this.imageTitle = img.Title;
    this.imageDescription = img.Description;

    if (img.ImageData) {
      this.filePickerData = img.ImageData;
      this.filePickerURL = null;
    } else {
      this.filePickerData = null;
      this.filePickerURL = img.URL;
    }
  }
}

export class ImageModel {
  ImageID: string = CommonFunction.GenGUID();
  URL: string;
  ImageData: any;
  Sequence: number;
  Title: string;
  Description: string;

  public constructor(init?: Partial<ImageModel>) {
     Object.assign(this, init);
  }
}