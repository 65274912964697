import { Component, Output, EventEmitter, Input, ViewChild, NgZone, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { LanguageData, LocalStorageType } from '../model/system';

@Component({
  selector: 'app-span-multi-language',
  templateUrl: './span-multi-language.component.html',
  styleUrls: ['./span-multi-language.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SpanMultiLanguageComponent),
      multi: true
    }
  ]
})

// https://medium.com/@majdasab/implementing-control-value-accessor-in-angular-1b89f2f84ebf
export class SpanMultiLanguageComponent implements ControlValueAccessor {
  languageType: string = 'en'
  languageEn: string;
  languageCn: string;
  languageMy: string;

  // @Input() ngModel: string;
  // @Output() ngModelChange = new EventEmitter<string>();

  data: LanguageData = new LanguageData();

  // ngOnInit() {
  //   if (this.ngModel) {
  //     try {
  //       this.data = JSON.parse(this.ngModel);
  //     } catch (e) {
  //       this.data.en = this.ngModel;
  //       this.data.cn = this.ngModel;
  //       this.data.my = this.ngModel;
  //     }
  //   }

  //   this.extractLanguageFromInput();
  // }

  constructor(private translate: TranslateService) {
    translate.onLangChange.subscribe(event => {
      this.languageType = event.lang;
    })
  }

  onChange: any = () => { }
  onTouch: any = () => { }

  val = "" // this is the updated value that the class accesses
  set value(val) {  // this value is updated by programmatic changes if( val !== undefined && this.val !== val){
    if (val !== undefined && this.val !== val) {
      this.val = val

      if (this.val) {
        try {
          this.data = JSON.parse(this.val);

          if (!this.data.cn && !this.data.en && !this.data.my) {
            this.data = new LanguageData();

            throw 'Not valid data'
          }

        } catch (e) {
          // console.log('textarea: error: ' + this.val)
          this.data.en = this.val;
          this.data.cn = this.val;
          this.data.my = this.val;
        }
      }

      this.extractLanguageFromInput();
      this.onChange(JSON.stringify(this.data))
      this.onTouch(val)
    }
  }

  // this method sets the value programmatically
  writeValue(value: any): void {
    this.value = value
  }

  // upon UI element value changes, this method gets triggered
  registerOnChange(fn: any): void {
    this.onChange = fn
  }

  // upon touching the element, this method gets triggered
  registerOnTouched(fn: any): void {
    this.onTouch = fn
  }

  /**    
  * This function is called when the control status changes to or from "DISABLED".
  * Depending on the value, it will enable or disable the appropriate DOM element.
  * @param isDisabled
  */
  setDisabledState?(isDisabled: boolean): void {
  }

  ngOnDestroy(): void {
  }

  extractLanguageFromInput() {
    this.languageCn = this.data.cn;
    this.languageEn = this.data.en;
    this.languageMy = this.data.my;
  }

  constructLanguage() {
    this.data.en = this.languageEn;
    this.data.cn = this.languageCn;
    this.data.my = this.languageMy;

    // this.ngModelChange.emit(JSON.stringify(this.data));
  }
}