<!-- <div #wrapper class="wrapper" [ngClass]="{
    'nav-collapsed': iscollapsed,
    'menu-collapsed': iscollapsed,
    'sidebar-sm': isSidebar_sm,
    'sidebar-lg': isSidebar_lg
  }" [dir]="options.direction">
  <app-navbar-visitor (toggleHideSidebar)="toggleHideSidebar($event)"></app-navbar-visitor>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="container-fluid">
        <router-outlet></router-outlet>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
  <app-notification-sidebar></app-notification-sidebar>
  <app-top-scroller></app-top-scroller>
</div> -->
<!-- <div class="app-splash-screen"
  [style.background]="company?.PhotoBackgroundColor ? company.PhotoBackgroundColor : '#FFFFFF'"
  [ngStyle]="{ left: windowWidth }" *ngIf="!(!showSplash && finishLoad)">
  <div class="app-splash-inner">
    <div class="app-logo" *ngIf="company"
      [style.background-image]="company?.Photo ? 'url(data:image/jpeg;base64,' + company.Photo + ')' : ''"></div>
    <div class="app-loader"></div>
  </div>
</div>

<app-mobile-login [company]="company" *ngIf="showLogin"></app-mobile-login>

<app-mobile-join-member [company]="company" *ngIf="!showLogin && showJoinMember"></app-mobile-join-member> -->

<div #wrapper class="wrapper"><!-- *ngIf="!showSplash && finishLoad && !showLogin && !showJoinMember">-->
  <div #appSidebar appSidebar class="app-sidebar" (toggleHideSidebar)="toggleHideSidebar($event)"
    [ngClass]="{ 'hide-sidebar': hideSidebar }" data-active-color="white" [attr.data-background-color]="bgColor"
    [attr.data-image]="'assets/img/sidebar-bg/01.jpg'"><!--[attr.data-image]="company?.CoverPhoto ? 'data:image/jpeg;base64,' + company.CoverPhoto : 'assets/img/sidebar-bg/01.jpg'">-->
    <app-sidebar-shop></app-sidebar-shop>
    <div class="sidebar-background" #sidebarBgImage></div>
  </div>
  <app-navbar-shop (toggleHideSidebar)="toggleHideSidebar($event)"></app-navbar-shop>
  <div class="main-panel">
    <div class="main-content">
      <div class="content-wrapper">
        <div class="container-fluid">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
  <!-- <app-mobile-top-bar (toggleHideSidebar)="toggleHideSidebar($event)" [pageTitle]="company?.CompanyName" *ngIf="resizeService.isMobileResolution"></app-mobile-top-bar> -->
  <app-mobile-bar *ngIf="resizeService.isMobileResolution"></app-mobile-bar>
</div>