import { Component, ViewContainerRef, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

import * as firebase from 'firebase/app';
import 'firebase/messaging';
import { environment } from 'environments/environment.prod';
import { TranslateService } from '@ngx-translate/core';

const Fingerprint2 = require("fingerprintjs2")
import { AccessDevice, MemberLocation } from 'app/shared/model/member';
import { MemberService } from './shared/services/member.service';
import { CovidService } from './shared/services/covid.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
    subscription: Subscription;

    constructor(private router: Router,
        private translate: TranslateService,
        private mmbrService: MemberService,
        private covidService: CovidService) {
        //console.log(environment.environmentName);

        let that = this;
        setTimeout(function () {
            Fingerprint2.get(async function (components) {
                let ipDetailRes = await that.mmbrService.GetExternal().toPromise()

                var values = components.map(function (component) { return component.value })
                var murmur = Fingerprint2.x64hash128(values.join(''), 31)

                // Update token to member
                let memberLoc = new MemberLocation({
                    MemberDeviceID: murmur,
                    IPAddress: ipDetailRes.ip,
                    Longitude: ipDetailRes.longitude,
                    Latitude: ipDetailRes.latitude,
                    Address: ipDetailRes.city,
                    CreatedDateTime: new Date()
                });

                await that.covidService.VisitCount(memberLoc).toPromise();
            })
        }, 500)

        const browserLang: string = translate.getBrowserLang();
        const preferredLang = localStorage.getItem('plks_lang');
        if (!!preferredLang && preferredLang.match(/(en|cn|my)/))
            this.translate.use(preferredLang);
        else
            translate.use(browserLang.match(/en|cn|my/) ? browserLang : 'en');

        try {

            if (!firebase.apps.length) {
                firebase.initializeApp(environment.firebase);
                navigator.serviceWorker.getRegistration().then(swr => firebase.messaging().useServiceWorker(swr));
            }
        } catch (e) {
            ////console.log('Firebase exception: ' + e);
        }
    }

    ngOnInit() {
        this.subscription = this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd)
            )
            .subscribe(() => window.scrollTo(0, 0));
    }


    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }



}