import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LayoutService {

    private emitChangeSource = new Subject<any>();
    changeEmitted$ = this.emitChangeSource.asObservable();
    emitChange(change: any) {
        this.emitChangeSource.next(change);
    }


    //Customizer

    private emitCustomizerSource = new Subject<any>();
    customizerChangeEmitted$ = this.emitCustomizerSource.asObservable();
    emitCustomizerChange(change: any) {
        this.emitCustomizerSource.next(change);
    }

    //customizer - compact menu

    private emitCustomizerCMSource = new Subject<any>();
    customizerCMChangeEmitted$ = this.emitCustomizerCMSource.asObservable();
    emitCustomizerCMChange(change: any) {
        this.emitCustomizerCMSource.next(change);
    }

    //customizer - compact menu

    private emitNotiSidebarSource = new Subject<any>();
    notiSidebarChangeEmitted$ = this.emitNotiSidebarSource.asObservable();
    emitNotiSidebarChange(change: any) {
        this.emitNotiSidebarSource.next(change);
    }

    // Home - modal

    private emitHomeModal = new Subject<any>();
    modalChangeEmitted$ = this.emitHomeModal.asObservable();
    emitHomeModalChange(change: any) {
      this.emitHomeModal.next(change);
    }

    //Mobile Floating button
    private emitFloatingButton = new Subject<any>();
    floatingButtonChangeEmitted$ = this.emitFloatingButton.asObservable();
    emitFloatingButtonChange(change: any) {
        this.emitFloatingButton.next(change);
    }

    //Mobile Floating button
    private emitCreateButton = new Subject<any>();
    createButtonChangeEmitted$ = this.emitCreateButton.asObservable();
    emitCreateButtonChange(change: any) {
        this.emitCreateButton.next(change);
    }
}