import {
    Component,
    ElementRef,
    Renderer2,
    HostListener,
    Input
} from "@angular/core";
import { LayoutService } from "../services/layout.service";
import { ConfigService } from "../services/config.service";

@Component({
    selector: "app-mobile-floating-button",
    templateUrl: "./mobile-floating-button.component.html",
    styleUrls: ["./mobile-floating-button.component.scss"]
})
export class MobileFloatingButtonComponent {
    @Input() showButton = false;

    constructor(private layoutService: LayoutService) {
    }

    create() {
        this.layoutService.emitCreateButtonChange(true);
    }
}
