import { Component, Output, EventEmitter, Input, ViewChild, NgZone } from '@angular/core';
import { GlobalService } from 'app/shared/services/global.service';
import { CompanyService } from 'app/shared/services/company.service';
import { AuthService } from 'app/shared/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { Member } from 'app/shared/model/member';
import { MemberService } from 'app/shared/services/member.service';

@Component({
  selector: 'app-activation',
  templateUrl: './activation.component.html',
  styleUrls: ['./activation.component.scss']
})

export class ActivationComponent {
  isLogging = false;

  email: string;
  code: string;
  shopName: string;

  ngOnInit() {
  }

  constructor(private router: Router,
    private globalSrv: GlobalService,
    private companyService: CompanyService,
    public authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private memberService: MemberService,
    private translate: TranslateService,
    private ngZone: NgZone) {

    this.email = this.activatedRoute.snapshot.paramMap.get('email');
    this.code = this.activatedRoute.snapshot.paramMap.get('code');
    this.shopName = this.activatedRoute.snapshot.paramMap.get('shopName');
  }

  async ngAfterViewInit() {
    try {
      if (this.email && this.code) {
        this.isLogging = true;

        let activateResult = await this.memberService.Activate(new Member({
          Email: this.email,
          ActivationCode: this.code
        })).toPromise().catch(error => {
          throw error;
        })

        if (!activateResult) {
          throw "Failed to activate account";
        }

        await Swal.fire({
          icon: 'success',
          title: this.translate.instant('Information'),
          html: 'Account activated successfully.'
        });

        if (this.shopName) {
          this.router.navigateByUrl('/' + this.shopName);
        } else {
          // Go to Default routing
          this.router.navigateByUrl('/');
        }
      }
    } catch (e) {
      Swal.fire({
        icon: 'error',
        title: this.translate.instant('Error'),
        html: this.translate.instant(e)
      });

      this.isLogging = false;
    }
  }
}