import { Subject } from 'rxjs/Subject';
import { Injectable } from '@angular/core';

@Injectable()
export class GlobalService {
  public menuCategory = new Subject<string>();
 
  setMenuCategory(value: string) {
    this.menuCategory.next(value); // this will make sure to tell every subscriber about the change.
    // localStorage.setItem(LocalStorageType.MenuCategory, value);
  }
 
  // get theMenuCategory() {
  //   return localStorage.getItem(LocalStorageType.MenuCategory);
  // }
}