import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-alert',
  templateUrl: './page-alert.component.html',
  styleUrls: ['./page-alert.component.scss'],
})

export class PageAlertComponent implements OnInit {
  @Input() alertType: string;
  @Input() message: string;
  constructor() {
    // console.log('access denied page constructor')
  }

  ngOnInit() {
    // console.log('access denied page constructor ngOnInit')
  }
}