<div
  #wrapper
  class="wrapper"
  [ngClass]="{
    'nav-collapsed': iscollapsed,
    'menu-collapsed': iscollapsed,
    'sidebar-sm': isSidebar_sm,
    'sidebar-lg': isSidebar_lg
  }"
  [dir]="options.direction"
>
  <div 
    #appSidebar
    appSidebar
    class="app-sidebar"
    (toggleHideSidebar)="toggleHideSidebar($event)"
    [ngClass]="{ 'hide-sidebar': hideSidebar }"
    data-active-color="white"
    [attr.data-background-color]="bgColor"
    [attr.data-image]="bgImage"
  >
    <app-sidebar></app-sidebar>
    <div class="sidebar-background" #sidebarBgImage></div>
  </div>
  <app-navbar (toggleHideSidebar)="toggleHideSidebar($event)"></app-navbar>
  <div class="main-panel">
    <div class="main-content">
      <div [class]="resizeService.isMobileResolution ? 'mobile-content-wrapper' : 'content-wrapper'">
        <ngb-alert type="success" (close)="closeAlert()" *ngIf="authService.getAnnouncement()">
          <div [innerHTML]="authService.getAnnouncement()"></div>
            <!-- <strong>Success!</strong> We make announcement over here for {{authService.getAnnouncement()}} ! -->
        </ngb-alert>
        <div class="container-fluid">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
  <!-- <app-mobile-top-bar *ngIf="resizeService.isMobileResolution"></app-mobile-top-bar> -->
  <app-mobile-bar *ngIf="resizeService.isMobileResolution" [showQROnly]="true"></app-mobile-bar>
  <app-notification-sidebar></app-notification-sidebar>
  <app-top-scroller></app-top-scroller>
  <app-mobile-floating-button [showButton]="showFloating"></app-mobile-floating-button>
</div>
