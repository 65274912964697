import { Component, NgZone, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";

// Import Web Services, Model
import { MemberService } from 'app/shared/services/member.service';
import { Member } from 'app/shared/model/member';
import { CompanyService } from 'app/shared/services/company.service';

// Handle image returned from web api
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

// Record token
import { AuthService } from 'app/shared/auth/auth.service';

// Pop Up Alert
import Swal from 'sweetalert2';
import { LocalStorageType } from 'app/shared/model/system';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LayoutService } from 'app/shared/services/layout.service';
import { environment } from 'environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { CommonFunction } from 'app/shared/common/common_function';
import { interval, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import * as bcrypt from 'bcryptjs';

@Component({
    selector: 'app-login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.scss']
})

export class LoginPageComponent implements AfterViewInit {
    loginForm: FormGroup;
    // imagePath: SafeUrl = "assets/img/portrait/small/avatar-s-12.png";
    errorMessage = '';
    isLogging = false;

    userType = 'seller';
    isLandingLogin = false;

    currentType = 1;
    confirmPassword: string;
    tncChecked = false;

    isSubmitting = false;
    member: Member = new Member();
    
    countdown: any;
    isWaiting = false;
    
    private _unsubscribeAll: Subject<any>;

    constructor(private router: Router,
        private formBuilder: FormBuilder,
        private memberService: MemberService,
        private companyService: CompanyService,
        private _sanitizer: DomSanitizer,
        private ngZone: NgZone,
        private route: ActivatedRoute,
        private authService: AuthService,
        public activeModal: NgbActiveModal,
        private layoutService: LayoutService,
        private translate: TranslateService,
        private activatedRoute: ActivatedRoute) {
        // this.userType = this.activatedRoute.snapshot.paramMap.get('userType') ? this.activatedRoute.snapshot.paramMap.get('userType') : 'seller'

        // Preset login
        this.loginForm = this.formBuilder.group({
            username: ['', [Validators.required, Validators.maxLength(40)]],
            password: ['', [Validators.required, Validators.minLength(CommonFunction.IsISOPlatform() ? 4 : 6), Validators.maxLength(50)]] // TODO: remove after updated ISMS password
        });
        // username: 'ismsimp',
        // password: 'isms'
    }

    openForgetPasswordModal() {
        this.activeModal.close();
        this.layoutService.emitHomeModalChange('ResetPassword')
    }

    openRegisterModal() {
        this.activeModal.close();
        this.layoutService.emitHomeModalChange('Register')
    }

    ngAfterViewInit() {

        // ////console.log('Delete login session')
        // localStorage.removeItem('access_token');
        const prevMmbr: Member = this.authService.getMember();

        if (prevMmbr != null && prevMmbr.MemberName != '') {
            localStorage.setItem(LocalStorageType.PreviousUser, prevMmbr.MemberName)
        }

        ////console.log('ngAfterViewInit remove access token')
        localStorage.removeItem(LocalStorageType.AccessToken);
        localStorage.removeItem(LocalStorageType.CompanyID);
    }

    // On submit button click
    async onSubmit() {
      // Block until action completed
      if (this.isSubmitting) {
        return;
      }
  
      try {
        switch (this.currentType) {
          // Login
          case 1:
            if (!this.member.MemberName) {
              throw 'Please fill in username / email.'
            }
  
            if (!this.member.Password) {
              throw 'Please fill in password.'
            }
  
            if (this.member.Password.length < 6) {
              throw 'Password minimum 6 characeters.'
            }
  
            this.isSubmitting = true;
  
            let loginResult = await this.memberService.Login(this.member).toPromise().catch(error => {
              throw error;
            })
  
            // Check for Development environment
            if (CommonFunction.IsDevPlatform()) {
              if (loginResult.MemberName != 'evardlim') {
                //
                throw 'This is testing environment. Please login at https://shop.cloudbiz.asia/'
              }
            }
  
            // To keep Shopping Cart
            var ShoppingCart = localStorage.getItem(LocalStorageType.ShoppingCart);
            var eCommerceHomeProduct = localStorage.getItem(LocalStorageType.eCommerceHomeProduct);
            var eCommerceProductList = localStorage.getItem(LocalStorageType.eCommerceProductList);
            var currentLanguage = localStorage.getItem(LocalStorageType.Language);
            var LandingSelectedSystem = localStorage.getItem(LocalStorageType.Landing_SelectedSystem);
            localStorage.clear();
            localStorage.setItem(LocalStorageType.ShoppingCart, ShoppingCart);
            localStorage.setItem(LocalStorageType.eCommerceHomeProduct, eCommerceHomeProduct);
            localStorage.setItem(LocalStorageType.eCommerceProductList, eCommerceProductList);
            localStorage.setItem(LocalStorageType.Language, currentLanguage);
            localStorage.setItem(LocalStorageType.Landing_SelectedSystem, LandingSelectedSystem);
  
            let sessionResult = await this.authService.SetSession(loginResult.Token)
  
            this.authService.setToken(loginResult.AccessToken);
            this.authService.setMember(loginResult);
  
            // Update location
            let ipDetailRes = await this.memberService.GetExternal().toPromise().catch(error => {
              //throw error;
              // Bypass throwing error because this is not important;
  
              return;
            })
  
            if (ipDetailRes) {
              loginResult.LastLoginIPAddress = ipDetailRes.ip;
              loginResult.LastLoginLocation = ipDetailRes.postal + ' ' + ipDetailRes.city + ' ' + ipDetailRes.country;
              loginResult.IPDetail = JSON.stringify(ipDetailRes);
  
              await this.memberService.UpdateMember(loginResult).toPromise().catch(error => {
  
                return;
              });
            }
  
            // Get Company list from server
            let companyInfo = await this.companyService.GetCompany().toPromise().catch(error => {
              throw error;
            })
  
            if (companyInfo) {
              let temp = companyInfo.filter(x => x.IsDeleted == false)
              if (temp && temp.length > 0) {
                // To populate the companyID first, therefore Dashbaord can show the data according to Selected Company
                this.authService.setCompany(temp[0].CompanyID);
              }
            }
  
            localStorage.setItem(LocalStorageType.CompanyList, JSON.stringify(companyInfo));
  
            await Swal.fire({
              icon: 'success',
              text: this.translate.instant("Welcome back") + `, ${loginResult.MemberName}!`,
              timer: 1300,
              timerProgressBar: true,
              confirmButtonText: this.translate.instant('Button.Confirm')
            });
  
            this.isSubmitting = false;
  
            this.ngZone.run(() => window.location.reload())
            break;
  
          // Forgot
          case 2:
            if (this.isWaiting) {
              return;
            }
  
            if (!this.member.Email) {
              throw 'Please fill in email.'
            }
  
            this.isSubmitting = true;
  
            let resetResult = await this.memberService.RequestReset(this.member).toPromise().catch(error => {
              if ((error + "").indexOf("Please request after ") >= 0) {
                let remainingSecStr = (error + "").replace("Please request after ", "").replace(" seconds.", "");
  
                let remainingSec = Number(remainingSecStr);
  
                if (!isNaN(remainingSec)) {
                  this.isWaiting = true;
  
                  // Create a subscribable interval
                  const countDown = interval(1000)
                    .pipe(
                      map(value => {
                        return remainingSec = remainingSec - 1;
                      }),
                      map(value => {
                        return this.calculateRemainingTime(value);
                      })
                    );
  
                  // Subscribe to the countdown interval
                  countDown
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe(value => {
                      this.countdown = value;
                    });
                }
              }
              throw error;
            })
  
            if (!resetResult) {
              throw 'Failed to send out reset email.';
            }
  
            Swal.fire({
              icon: 'success',
              title: this.translate.instant('Information'),
              html: 'Reset code has send to your email.'
            });
  
            this.currentType = 4;
            break;
  
          // Create
          case 3:
            if (!this.member.MemberName) {
              throw 'Please fill in Username.'
            }
  
            if (!this.member.Email) {
              throw 'Please fill in Email.'
            }
  
            // Email validator
            if (!this.member.IsValidEmail()) {
              throw 'Invalid Email.'
            }
  
            if (!this.member.Password) {
              throw 'Please fill in Password.'
            }
  
            if (!this.member.IsValidPassword()) {
              throw 'Password minimum 6 characeters.'
            }
  
            if (!this.confirmPassword) {
              throw 'Please fill in Confirm Password.'
            }
  
            if (this.confirmPassword.length < 6) {
              throw 'Confirm Password minimum 6 characeters.'
            }
  
            if (this.member.Password != this.confirmPassword) {
              throw 'Password and Confirm Password not match.'
            }
  
            this.isSubmitting = true;
  
            let registerResult = await this.memberService.Register(new Member({
              Email: this.member.Email,
              MemberName: this.member.MemberName,
              Password: bcrypt.hashSync(this.member.Password, bcrypt.genSaltSync(10))
            })).toPromise().catch(error => {
              throw error;
            })
  
            if (!registerResult) {
              throw 'Failed to register.';
            }
  
            Swal.fire({
              icon: 'success',
              title: this.translate.instant('Information'),
              html: 'Registered successfully.'
            });
  
            // Clear all cached info
            this.setType(1);
  
            break;
  
          // Reset Password Verify Code
          case 4:
            if (!this.member.ResetToken) {
              throw 'Please fill in Reset Code.'
            }
  
            if (!this.member.Password) {
              throw 'Please fill in Password.'
            }
  
            if (this.member.Password.length < 6) {
              throw 'Password minimum 6 characeters.'
            }
  
            if (!this.confirmPassword) {
              throw 'Please fill in Confirm Password.'
            }
  
            if (this.confirmPassword.length < 6) {
              throw 'Confirm Password minimum 6 characeters.'
            }
  
            if (this.member.Password != this.confirmPassword) {
              throw 'Password and Confirm Password not match.'
            }
  
            this.isSubmitting = true;
  
            let resetPasswordResult = await this.memberService.ResetPassword(new Member({
              Email: this.member.Email,
              ResetToken: this.member.ResetToken,
              Password: bcrypt.hashSync(this.member.Password, bcrypt.genSaltSync(10))
            })).toPromise().catch(error => {
              throw error;
            })
  
            if (!resetPasswordResult) {
              throw 'Failed to reset password.';
            }
  
            Swal.fire({
              icon: 'success',
              title: this.translate.instant('Information'),
              html: 'Your password has reset successfully.'
            });
  
            // Clear all cached info
            this.setType(1);
            break;
        }
  
        this.isSubmitting = false;
      } catch (e) {
        Swal.fire({
          icon: 'error',
          title: this.translate.instant('Error'),
          html: this.translate.instant(e)
        });
  
        this.isSubmitting = false;
      }
    }


    private calculateRemainingTime(seconds): any {
      if (seconds == 0) {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
  
        this.isWaiting = false;
  
        return {
          weeks: '',
          days: '',
          hours: '',
          minutes: '',
          seconds: ''
        };
      }
  
      const timeLeft = moment.duration(seconds, 'seconds');
  
      return {
        weeks: timeLeft.asWeeks().toFixed(0),
        days: timeLeft.asDays().toFixed(0),
        hours: timeLeft.hours(),
        minutes: timeLeft.minutes(),
        seconds: timeLeft.seconds()
      };
    }

    // // On submit button click
    // async onSubmit() {
    //     if (this.loginForm.invalid) {
    //         Object.keys(this.loginForm.controls).forEach(field => { // {1}
    //             const control = this.loginForm.get(field);            // {2}
    //             control.markAsTouched({ onlySelf: true });       // {3}
    //         });

    //         return;
    //     }

    //     this.isLogging = true;

    //     const memberInfo = new Member();
    //     memberInfo.MemberName = this.loginForm.controls['username'].value;
    //     memberInfo.Password = this.loginForm.controls['password'].value;

    //     try {
    //         let loginResult = await this.memberService.Login(memberInfo).toPromise().catch(error => {
    //             throw error;
    //         })

    //         if (typeof loginResult.AccessToken === 'undefined' || !loginResult.AccessToken) {
    //             throw 'Wrong password! Please try again.'
    //         }

    //         // Check for Development environment
    //         if (CommonFunction.IsDevPlatform()) {
    //             if (loginResult.MemberName != 'evardlim') {
    //                 //
    //                 throw 'This is testing environment. Please login at https://shop.cloudbiz.asia/'
    //             }
    //         }

    //         // To keep Shopping Cart
    //         var ShoppingCart = localStorage.getItem(LocalStorageType.ShoppingCart);
    //         var eCommerceHomeProduct = localStorage.getItem(LocalStorageType.eCommerceHomeProduct);
    //         var eCommerceProductList = localStorage.getItem(LocalStorageType.eCommerceProductList);
    //         var currentLanguage = localStorage.getItem(LocalStorageType.Language);
    //         var LandingSelectedSystem = localStorage.getItem(LocalStorageType.Landing_SelectedSystem);
    //         localStorage.clear();
    //         localStorage.setItem(LocalStorageType.ShoppingCart, ShoppingCart);
    //         localStorage.setItem(LocalStorageType.eCommerceHomeProduct, eCommerceHomeProduct);
    //         localStorage.setItem(LocalStorageType.eCommerceProductList, eCommerceProductList);
    //         localStorage.setItem(LocalStorageType.Language, currentLanguage);
    //         localStorage.setItem(LocalStorageType.Landing_SelectedSystem, LandingSelectedSystem);


    //         let sessionResult = await this.authService.SetSession(loginResult.Token)
    //         console.log('set session sessionResult: ' + JSON.stringify(sessionResult))

    //         this.authService.setToken(loginResult.AccessToken);
    //         this.authService.setMember(loginResult);

    //         // Update location
    //         let ipDetailRes = await this.memberService.GetExternal().toPromise().catch(error => {
    //             //throw error;
    //             // Bypass throwing error because this is not important;

    //             return;
    //         })

    //         if (ipDetailRes) {
    //             loginResult.LastLoginIPAddress = ipDetailRes.ip;
    //             loginResult.LastLoginLocation = ipDetailRes.postal + ' ' + ipDetailRes.city + ' ' + ipDetailRes.country;
    //             loginResult.IPDetail = JSON.stringify(ipDetailRes);

    //             await this.memberService.UpdateMember(loginResult).toPromise().catch(error => {
    //                 //throw error;
    //                 // Bypass throwing error because this is not important;

    //                 return;
    //             });
    //         }

    //         // // If login with different user, clear data
    //         // if(localStorage.getItem(LocalStorageType.PreviousUser) !== res.MemberName)
    //         // {

    //         // }


    //         // this.activeModal.close();

    //         // if (!res.Activate) {
    //         //     this.goToActivation();
    //         //     return;
    //         // }

    //         ////console.log('memberInfo res: ' + JSON.stringify(loginResult))
    //         ////console.log('loginResult.AccessToken res: ' + JSON.stringify(loginResult.AccessToken))

    //         // Get Company list from server
    //         let companyInfo = await this.companyService.GetCompany().toPromise().catch(error => {
    //             throw error;
    //         })

    //         if (companyInfo) {
    //             let temp = companyInfo.filter(x => x.IsDeleted == false)
    //             if (temp && temp.length > 0) {
    //                 // To populate the companyID first, therefore Dashbaord can show the data according to Selected Company
    //                 this.authService.setCompany(temp[0].CompanyID);
    //             }
    //         }

    //         localStorage.setItem(LocalStorageType.CompanyList, JSON.stringify(companyInfo));

    //         // if (this.userType == 'seller') {
    //         //     this.ngZone.run(() => this.router.navigateByUrl('/dashboard/dashboard'))
    //         // }
    //         // else {
    //         //     this.ngZone.run(() => this.router.navigateByUrl('/pages/home'))
    //         // }
    //         // *** NO NEED RELOAD, because sidebar will get latest from ngAfterViewChecked()
    //         // https://pusher.com/tutorials/lifecycle-hooks-angular

    //         await Swal.fire({
    //             icon: 'success',
    //             text: this.translate.instant("Welcome back") + `, ${loginResult.MemberName}!`,
    //             timer: 1300,
    //             timerProgressBar: true,
    //             confirmButtonText: this.translate.instant('Button.Confirm')
    //         });

    //         this.isLogging = false;

    //         this.ngZone.run(() => window.location.reload())

    //         // // Get Member Info
    //         // this.memberService.GetMember().subscribe(mmbrRes => {
    //         //     ////console.log('Logging in! Member List: ' + JSON.stringify(mmbrRes))
    //         //     localStorage.setItem(LocalStorageType.MemberList, JSON.stringify(mmbrRes))
    //         //     // localStorage.setItem(LocalStorageType.AccessToken, JSON.stringify(res.AccessToken));

    //         //     var imageData = 'data:image/png;base64,' + res.Photo;
    //         //     // this.imagePath = this._sanitizer.bypassSecurityTrustUrl(imageData);

    //         //     Swal.fire({
    //         //         icon: 'success',
    //         //         title: this.translate.instant('Information'),
    //         //         html: 'Logged in successfully!',
    //         //         timer: 500
    //         //     });
    //         //     this.isLogging = false;

    //         //     ////console.log('Get Company list from server')
    //         //     // Get Company list from server
    //         //     this.companyService.GetCompany().subscribe(res => {
    //         //         ////console.log('Return Get Company list from server')
    //         //         if (res == null || typeof res == 'undefined') {
    //         //             ////console.log('Failed to get company')
    //         //         }
    //         //         else {
    //         //             ////console.log('Logging in! Company count: ' + res.length)
    //         //             localStorage.setItem(LocalStorageType.CompanyList, JSON.stringify(res));

    //         //             let temp = res.filter(x => x.IsDelete == false)
    //         //             if (temp.length > 0) {
    //         //                 // To populate the companyID first, therefore Dashbaord can show the data according to Selected Company
    //         //                 this.authService.setCompany(temp[0].CompanyID);
    //         //             }
    //         //         }

    //         //         this.ngZone.run(() => this.router.navigateByUrl('/dashboard/isodashboard'))
    //         //     });

    //         //     // this.ngZone.run(() => this.router.navigateByUrl('/dashboard'))
    //         //     // this.router.navigate(['full-layout'], { relativeTo: this.route.parent });
    //         // });
    //     } catch (e) {
    //         Swal.fire({
    //             icon: 'error',
    //             title: this.translate.instant('Error'),
    //             html: this.translate.instant(e)
    //         });

    //         this.isLogging = false;
    //     }
    // }

    goToActivation() {
        this.router.navigateByUrl('/pages/activation');
    }

    // On Forgot password link click
    onForgotPassword() {
        this.openForgetPasswordModal();
        // this.router.navigate(['forgotpassword'], { relativeTo: this.route.parent });
    }

    // On registration link click
    onRegister() {
        this.openRegisterModal();
        // this.router.navigate(['register'], { relativeTo: this.route.parent });
    }

    onCloseModal() {
        this.activeModal.close();

        if (this.isLandingLogin) {
            this.router.navigateByUrl('/pages/landing');
        } else {
            this.router.navigateByUrl('/pages/home');   // Always redirect user to Home page as user might be in Authorized page
        }
    }

    setType(typeNumber: number) {
      // Reset all parameters
      this.member = new Member();
      this.confirmPassword = null;
  
      this.currentType = typeNumber;
    }
}
