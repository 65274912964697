<section id="login"
    [style.background-image]="company?.CoverPhoto ? 'url(' + company.CoverPhoto + ')' : 'assets/img/sidebar-bg/06.jpg'">
    <div class="row auth-height full-height-vh m-0">
        <div class="col-12 d-flex align-items-center justify-content-center">
            <!-- Login section-->
            <div class="card overflow-hidden" *ngIf="currentType == 1">
                <div class="card-content">
                    <div class="card-body auth-img">
                        <div class="row m-0">
                            <div
                                class="col-lg-6 d-none d-lg-flex justify-content-center align-items-center auth-img-bg p-3">
                                <img src="assets/img/gallery/login.png" alt="" width="300" height="230"
                                    class="img-fluid"></div>
                            <div class="col-lg-6 col-12 px-4 py-3">
                                <h4 class="mb-2 card-title">Welcome back</h4>
                                <p>Login to your account.</p>
                                <form class="form">
                                    <div class="form-body">
                                        <div class="form-group">
                                            <input type="text" [(ngModel)]="member.MemberName" id="MemberName"
                                                name="MemberName" placeholder="Username / Email" class="form-control">
                                        </div>
                                        <div class="form-group">
                                            <input type="password" minlength="6" [(ngModel)]="member.Password" id="Password"
                                                name="Password" placeholder="Password" class="form-control">
                                        </div>
                                        <div class="form-group">
                                            <button type="button" class="btn btn-raised btn-primary btn-block"
                                                (click)="onSubmit()">
                                                Login
                                                <span style="margin-left:10px;">
                                                  <i class="ft-loader spinner" [hidden]="!isSubmitting"></i>
                                                </span>
                                            </button>
                                        </div>
                                        <div class="d-sm-flex justify-content-between mb-3 font-small-2 text-center"><a
                                                (click)="setType(2)" href="javascript:void(0);">Forgot
                                                Password?</a>
                                        </div>
                                        <p class="text-center text-muted font-small-2 pt-3">Don't have an account?</p>
                                        <p class="text-center" (click)="setType(3)">Register</p>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Forgot Password section-->
            <div class="card overflow-hidden" *ngIf="currentType == 2">
                <div class="card-content">
                    <div class="card-body auth-img">
                        <div class="row m-0">
                            <div
                                class="col-lg-6 d-none d-lg-flex justify-content-center align-items-center auth-img-bg p-3">
                                <img src="assets/img/gallery/login.png" alt="" width="300" height="230"
                                    class="img-fluid"></div>
                            <div class="col-lg-6 col-12 px-4 py-3">
                                <h4 class="mb-2 card-title">Reset Password</h4>
                                <p>Please enter your email address and we'll send you Reset Code to reset your password.</p>
                                <form class="form">
                                    <div class="form-body">
                                        <div class="form-group">
                                            <input type="text" [(ngModel)]="member.Email" id="ResetPasswordEmail"
                                                name="ResetPasswordEmail" placeholder="Email" class="form-control">
                                        </div>

                                        <p class="text-muted" *ngIf="isWaiting">Please request after <span class="text-info">{{countdown.seconds}}</span> seconds.</p>

                                        <div class="form-group">
                                            <button type="button" class="btn btn-raised btn-warning btn-block"
                                                (click)="onSubmit()">
                                                Request Code
                                                <span style="margin-left:10px;">
                                                  <i class="ft-loader spinner" [hidden]="!isSubmitting"></i>
                                                </span>
                                            </button>
                                        </div>
                                        <div class="form-group mt-3">
                                            <button type="button" class="btn btn-outline-primary btn-block"
                                                (click)="setType(1)">
                                                Back To Login
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Forgot Password Verify Code section-->
            <div class="card overflow-hidden" *ngIf="currentType == 4">
                <div class="card-content">
                    <div class="card-body auth-img">
                        <div class="row m-0">
                            <div
                                class="col-lg-6 d-none d-lg-flex justify-content-center align-items-center auth-img-bg p-3">
                                <img src="assets/img/gallery/login.png" alt="" width="300" height="230"
                                    class="img-fluid"></div>
                            <div class="col-lg-6 col-12 px-4 py-3">
                                <h4 class="mb-2 card-title">Reset Password</h4>
                                <p>Please enter Reset Code from your email {{member.Email}}.</p>
                                <form class="form">
                                    <div class="form-body">
                                        <div class="form-group">
                                            <input type="text" [(ngModel)]="member.ResetToken" id="ResetPasswordToken"
                                                name="ResetPasswordToken" placeholder="Reset Code" class="form-control">
                                        </div>
                                        <div class="form-group">
                                            <input type="password" minlength="6" [(ngModel)]="member.Password"
                                                id="ResetPasswordPassword" name="ResetPasswordPassword"
                                                placeholder="Password" class="form-control">
                                        </div>
                                        <div class="form-group">
                                            <input type="password" minlength="6" [(ngModel)]="confirmPassword"
                                                id="ResetPasswordConfirmPassword" name="ResetPasswordConfirmPassword"
                                                placeholder="Confirm Password" class="form-control">
                                        </div>
                                        <div class="form-group">
                                            <button type="button" class="btn btn-raised btn-warning btn-block"
                                                (click)="onSubmit()">
                                                {{'Reset' | translate}}
                                                <span style="margin-left:10px;">
                                                  <i class="ft-loader spinner" [hidden]="!isSubmitting"></i>
                                                </span>
                                            </button>
                                        </div>
                                        <div class="form-group mt-3">
                                            <button type="button" class="btn btn-outline-primary btn-block"
                                                (click)="setType(1)">
                                                Back To Login
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Register Password section-->
            <div class="card overflow-hidden" *ngIf="currentType == 3">
                <div class="card-content">
                    <div class="card-body auth-img">
                        <div class="row m-0">
                            <div
                                class="col-lg-6 d-none d-lg-flex justify-content-center align-items-center auth-img-bg p-3">
                                <img src="assets/img/gallery/login.png" alt="" width="300" height="230"
                                    class="img-fluid"></div>
                            <div class="col-lg-6 col-12 px-4 py-3">
                                <h4 class="mb-2 card-title">Create Account</h4>
                                <p>Fill the below form to create a new account.</p>
                                <form class="form">
                                    <div class="form-body">
                                        <div class="form-group">
                                            <input type="text" [(ngModel)]="member.MemberName" id="RegisterMemberName"
                                                name="RegisterMemberName" placeholder="Username" class="form-control">
                                        </div>
                                        <div class="form-group">
                                            <input type="text" [(ngModel)]="member.Email" id="RegisterEmail"
                                                name="RegisterEmail" placeholder="Email" class="form-control">
                                        </div>
                                        <div class="form-group">
                                            <input type="password" minlength="6" [(ngModel)]="member.Password" id="RegisterPassword"
                                                name="RegisterPassword" placeholder="Password" class="form-control">
                                        </div>
                                        <div class="form-group">
                                            <input type="password" minlength="6" [(ngModel)]="confirmPassword"
                                                id="RegisterConfirmPassword" name="RegisterConfirmPassword"
                                                placeholder="Confirm Password" class="form-control">
                                        </div>

                                        <div class="custom-control custom-checkbox custom-control-inline">
                                            <input type="checkbox" id="customCheckboxInline1"
                                                formControlName="cbTermCond" [(ngModel)]="tncChecked"
                                                name="customCheckboxInline1" class="custom-control-input" />
                                            <label class="custom-control-label" for="customCheckboxInline1"
                                                translate>register.tnc</label>
                                        </div>

                                        <div class="form-group">
                                            <button type="button" class="btn btn-raised btn-primary btn-block"
                                                (click)="onSubmit()">
                                                Register
                                                <span style="margin-left:10px;">
                                                  <i class="ft-loader spinner" [hidden]="!isSubmitting"></i>
                                                </span>
                                            </button>
                                        </div>
                                        <div class="form-group mt-3">
                                            <button type="button" class="btn btn-outline-primary btn-block"
                                                (click)="setType(1)">
                                                Back To Login
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>