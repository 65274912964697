<section id="login" [style.background-image]="company?.CoverPhoto ? 'url(' + company.CoverPhoto + ')' : 'url(assets/img/sidebar-bg/06.jpg)'">
    <div class="row auth-height full-height-vh m-0">
        <div class="col-12 d-flex align-items-center justify-content-center">
            <div class="card overflow-hidden">
                <div class="card-content">
                    <div class="card-body auth-img">
                        <div class="row m-0">
                            <div
                                class="col-lg-6 d-none d-lg-flex justify-content-center align-items-center auth-img-bg p-3">
                                <img src="assets/img/gallery/login.png" alt="" width="300" height="230"
                                    class="img-fluid"></div>
                            <div class="col-lg-6 col-12 px-4 py-3">
                                <div class="col-12 text-center mb-3">
                                    <img *ngIf="company?.Photo" [src]="company.Photo" alt="" width="150" height="150"
                                        class="img-fluid rounded user-timeline-image">
                                </div>
                                <h4 class="mb-2 card-title">Welcome <strong class="primary">{{authService.getMember()?.MemberName}}</strong></h4>
                                <p>Join member to access</p>
                                <form class="form">
                                    <div class="form-body">
                                        <div class="form-group">
                                            <button type="button"
                                                class="btn btn-raised btn-primary btn-block"
                                                (click)="onSubmit()">
                                                Join
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>