import { CompanyInfo } from './company';
import { AttachmentInfo } from './chat';
import { TaxInfo } from './pos';
import { BaseModel, BaseModelHistory } from './base';
import { CommonFunction } from '../common/common_function';

export class ProductImage extends BaseModel {
   ProductImageID: string;
   ProductID: string;
   AttachmentID: string;
   // CreatedBy: string;
   // CreatedDateTime: Date;
   // UpdatedDateTime: Date;   // Server updated time
   // LastUpdatedDateTime: Date;  // Local updated time
   AttachmentPath: string;
   Title: string;
   Description: string;
   ImageData: any;
   Sequence: number;

   public constructor(init?: Partial<ProductImage>) {
      super();
      Object.assign(this, init);
   }
}

export class ProductInfo extends BaseModelHistory {
   ProductID: string;
   Reference: string;
   Code: string;
   CodeType: string;
   Name: string;
   PriceBuy: number;
   PriceSell: number;
   CategoryID: string;
   TaxCategoryID: string;
   TaxIncludedInPrice: boolean;
   IsService: boolean;
   Warranty: number;
   RefProductID: string;
   AttachmentID: string;
   DepartmentID: string;
   // CompanyID: string;
   // CreatedBy: string;
   // CreatedDateTime: Date;
   // UpdatedBy: string;
   // UpdatedDateTime: Date;
   // LastUpdatedDateTime: Date;
   // IsDeleted: boolean;
   IsSelling: boolean;
   IseCommerce: boolean;
   Description: string;
   DescriptionDetail: string;
   AttachmentPath: string;
   MinOrderQty: number;
   Selected: boolean; // Only for local usage on Display Product added or not added to cart
   TaxInfo: TaxInfo;

   public constructor(init?: Partial<ProductInfo>) {
      super();
      Object.assign(this, init);
   }
}

export class CategoryInfo extends BaseModelHistory {
   CategoryID: string;
   Name: string;
   // CompanyID: string;
   // CreatedBy: string;
   // CreatedDateTime: Date;
   // UpdatedBy: string;
   // UpdatedDateTime: Date;
   // LastUpdatedDateTime: Date;
   // IsDeleted: boolean;
   MinOrderQty: number;

   public constructor(init?: Partial<CategoryInfo>) {
      super();
      Object.assign(this, init);
   }
}

export class eCommerceSellerProduct {
   Supplier: CompanyInfo;
   Products: eCommerceAddProduct[];

   public constructor(init?: Partial<eCommerceSellerProduct>) {
      Object.assign(this, init);
   }
}

export class ProductAttribute extends BaseModelHistory {
   ProductAttributeID: string;
   ProductID: string;
   Name: string;
   PriceBuy: number;
   PriceSell: number;
   // CompanyID: string;
   // CreatedBy: string;
   // CreatedDateTime: Date;
   // UpdatedBy: string;
   // UpdatedDateTime: Date;
   // IsDeleted: boolean;
   IsSelling: boolean;
   AttachmentPath: string;

   public constructor(init?: Partial<ProductAttribute>) {
      super();
      Object.assign(this, init);
   }
}

export class eCommerceAddProduct {
   Product: ProductInfo = new ProductInfo({
      ProductID: CommonFunction.GenGUID()
   });
   Category: CategoryInfo;
   Attributes: ProductAttribute[];
   Images: ProductImage[] = [];
   AttachmentMetadata: AttachmentInfo[]; // Metadata same count with Images

   public constructor(init?: Partial<eCommerceAddProduct>) {
      Object.assign(this, init);
   }
}