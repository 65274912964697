export class BaseModel {
   TransactionBy: string;
   TransactionDateTime: Date;

   CreatedBy: string;
   CreatedDateTime: Date;

   UpdatedBy: string;
   UpdatedDateTime: Date;

   DeletedBy: string;
   DeletedDateTime: Date;

   CompanyID: string;

   LastUpdatedDateTime: Date; // To record local updated date time
}

export class BaseModelHistory extends BaseModel {
   IsDeleted: boolean;
}

export class BaseUser extends BaseModelHistory {
   MemberRefID: string;
   CompanyRefID: string;
   Active: boolean;
   ReferrerMemberID: string;
   Balance: number;
   Qr: string;
}

export enum PageAlertType {
   primary,
   secondary,
   success,
   danger,
   info,
   warning,
   light,
   dark
}