import { Component, Output, EventEmitter, Input, ViewChild, NgZone } from '@angular/core';
import { GlobalService } from 'app/shared/services/global.service';
import { CompanyService } from 'app/shared/services/company.service';
import { AuthService } from 'app/shared/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyInfo, CompanyMember, Customer } from 'app/shared/model/company';
import { Member } from '../model/member';
import { MemberService } from '../services/member.service';
import { CommonFunction } from '../common/common_function';
import { LocalStorageType } from '../model/system';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { interval, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import * as bcrypt from 'bcryptjs';

@Component({
  selector: 'app-mobile-login',
  templateUrl: './mobile-login.component.html',
  styleUrls: ['./mobile-login.component.scss']
})

export class MobileLoginComponent {
  member: Member = new Member();

  @Input() company: CompanyInfo;

  isSubmitting = false;

  currentType = 1;
  confirmPassword: string;
  tncChecked = false;

  isWaiting = false;
  countdown: any;
  private _unsubscribeAll: Subject<any>;

  ngOnInit() {
  }

  constructor(private router: Router,
    private globalSrv: GlobalService,
    private companyService: CompanyService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private memberService: MemberService,
    private translate: TranslateService,
    private ngZone: NgZone) {
    // Set the defaults
    this.countdown = {
      weeks: '',
      days: '',
      hours: '',
      minutes: '',
      seconds: ''
    };

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // On submit button click
  async onSubmit() {
    // Block until action completed
    if (this.isSubmitting) {
      return;
    }

    try {
      switch (this.currentType) {
        // Login
        case 1:
          if (!this.member.MemberName) {
            throw 'Please fill in username / email.'
          }

          if (!this.member.Password) {
            throw 'Please fill in password.'
          }

          if (this.member.Password.length < 6) {
            throw 'Password minimum 6 characeters.'
          }

          this.isSubmitting = true;

          let loginResult = await this.memberService.Login(this.member).toPromise().catch(error => {
            throw error;
          })

          // Check for Development environment
          if (CommonFunction.IsDevPlatform()) {
            if (loginResult.MemberName != 'evardlim') {
              //
              throw 'This is testing environment. Please login at https://shop.cloudbiz.asia/'
            }
          }

          // To keep Shopping Cart
          var ShoppingCart = localStorage.getItem(LocalStorageType.ShoppingCart);
          var eCommerceHomeProduct = localStorage.getItem(LocalStorageType.eCommerceHomeProduct);
          var eCommerceProductList = localStorage.getItem(LocalStorageType.eCommerceProductList);
          var currentLanguage = localStorage.getItem(LocalStorageType.Language);
          var LandingSelectedSystem = localStorage.getItem(LocalStorageType.Landing_SelectedSystem);
          localStorage.clear();
          localStorage.setItem(LocalStorageType.ShoppingCart, ShoppingCart);
          localStorage.setItem(LocalStorageType.eCommerceHomeProduct, eCommerceHomeProduct);
          localStorage.setItem(LocalStorageType.eCommerceProductList, eCommerceProductList);
          localStorage.setItem(LocalStorageType.Language, currentLanguage);
          localStorage.setItem(LocalStorageType.Landing_SelectedSystem, LandingSelectedSystem);

          let sessionResult = await this.authService.SetSession(loginResult.Token)

          this.authService.setToken(loginResult.AccessToken);
          this.authService.setMember(loginResult);

          // Update location
          let ipDetailRes = await this.memberService.GetExternal().toPromise().catch(error => {
            //throw error;
            // Bypass throwing error because this is not important;

            return;
          })

          if (ipDetailRes) {
            loginResult.LastLoginIPAddress = ipDetailRes.ip;
            loginResult.LastLoginLocation = ipDetailRes.postal + ' ' + ipDetailRes.city + ' ' + ipDetailRes.country;
            loginResult.IPDetail = JSON.stringify(ipDetailRes);

            await this.memberService.UpdateMember(loginResult).toPromise().catch(error => {

              return;
            });
          }

          // Get Company list from server
          let companyInfo = await this.companyService.GetCompany().toPromise().catch(error => {
            throw error;
          })

          if (companyInfo) {
            let temp = companyInfo.filter(x => x.IsDeleted == false)
            if (temp && temp.length > 0) {
              // To populate the companyID first, therefore Dashbaord can show the data according to Selected Company
              this.authService.setCompany(temp[0].CompanyID);
            }
          }

          localStorage.setItem(LocalStorageType.CompanyList, JSON.stringify(companyInfo));

          await Swal.fire({
            icon: 'success',
            text: this.translate.instant("Welcome back") + `, ${loginResult.MemberName}!`,
            timer: 1300,
            timerProgressBar: true,
            confirmButtonText: this.translate.instant('Button.Confirm')
          });

          this.isSubmitting = false;

          this.ngZone.run(() => window.location.reload())
          break;

        // Forgot
        case 2:
          if (this.isWaiting) {
            return;
          }

          if (!this.member.Email) {
            throw 'Please fill in email.'
          }

          this.isSubmitting = true;

          let resetResult = await this.memberService.RequestReset(this.member).toPromise().catch(error => {
            if ((error + "").indexOf("Please request after ") >= 0) {
              let remainingSecStr = (error + "").replace("Please request after ", "").replace(" seconds.", "");

              let remainingSec = Number(remainingSecStr);

              if (!isNaN(remainingSec)) {
                this.isWaiting = true;

                // Create a subscribable interval
                const countDown = interval(1000)
                  .pipe(
                    map(value => {
                      return remainingSec = remainingSec - 1;
                    }),
                    map(value => {
                      return this.calculateRemainingTime(value);
                    })
                  );

                // Subscribe to the countdown interval
                countDown
                  .pipe(takeUntil(this._unsubscribeAll))
                  .subscribe(value => {
                    this.countdown = value;
                  });
              }
            }
            throw error;
          })

          if (!resetResult) {
            throw 'Failed to send out reset email.';
          }

          Swal.fire({
            icon: 'success',
            title: this.translate.instant('Information'),
            html: 'Reset code has send to your email.'
          });

          this.currentType = 4;
          break;

        // Create
        case 3:
          if (!this.member.MemberName) {
            throw 'Please fill in Username.'
          }

          if (!this.member.Email) {
            throw 'Please fill in Email.'
          }

          // Email validator
          if (!this.member.IsValidEmail()) {
            throw 'Invalid Email.'
          }

          if (!this.member.Password) {
            throw 'Please fill in Password.'
          }

          if (!this.member.IsValidPassword()) {
            throw 'Password minimum 6 characeters.'
          }

          if (!this.confirmPassword) {
            throw 'Please fill in Confirm Password.'
          }

          if (this.confirmPassword.length < 6) {
            throw 'Confirm Password minimum 6 characeters.'
          }

          if (this.member.Password != this.confirmPassword) {
            throw 'Password and Confirm Password not match.'
          }

          this.isSubmitting = true;

          let registerResult = await this.memberService.RegisterFromShop(new Member({
            Email: this.member.Email,
            MemberName: this.member.MemberName,
            Password: bcrypt.hashSync(this.member.Password, bcrypt.genSaltSync(10))
          }), this.company.CompanyCode).toPromise().catch(error => {
            throw error;
          })

          if (!registerResult) {
            throw 'Failed to register.';
          }

          Swal.fire({
            icon: 'success',
            title: this.translate.instant('Information'),
            html: 'Registered successfully.'
          });

          // Clear all cached info
          this.setType(1);

          break;

        // Reset Password Verify Code
        case 4:
          if (!this.member.ResetToken) {
            throw 'Please fill in Reset Code.'
          }

          if (!this.member.Password) {
            throw 'Please fill in Password.'
          }

          if (this.member.Password.length < 6) {
            throw 'Password minimum 6 characeters.'
          }

          if (!this.confirmPassword) {
            throw 'Please fill in Confirm Password.'
          }

          if (this.confirmPassword.length < 6) {
            throw 'Confirm Password minimum 6 characeters.'
          }

          if (this.member.Password != this.confirmPassword) {
            throw 'Password and Confirm Password not match.'
          }

          this.isSubmitting = true;

          let resetPasswordResult = await this.memberService.ResetPassword(new Member({
            Email: this.member.Email,
            ResetToken: this.member.ResetToken,
            Password: bcrypt.hashSync(this.member.Password, bcrypt.genSaltSync(10))
          })).toPromise().catch(error => {
            throw error;
          })

          if (!resetPasswordResult) {
            throw 'Failed to reset password.';
          }

          Swal.fire({
            icon: 'success',
            title: this.translate.instant('Information'),
            html: 'Your password has reset successfully.'
          });

          // Clear all cached info
          this.setType(1);
          break;
      }

      this.isSubmitting = false;
    } catch (e) {
      Swal.fire({
        icon: 'error',
        title: this.translate.instant('Error'),
        html: this.translate.instant(e)
      });

      this.isSubmitting = false;
    }
  }


  private calculateRemainingTime(seconds): any {
    if (seconds == 0) {
      // Unsubscribe from all subscriptions
      this._unsubscribeAll.next();
      this._unsubscribeAll.complete();

      this.isWaiting = false;

      return {
        weeks: '',
        days: '',
        hours: '',
        minutes: '',
        seconds: ''
      };
    }

    const timeLeft = moment.duration(seconds, 'seconds');

    return {
      weeks: timeLeft.asWeeks().toFixed(0),
      days: timeLeft.asDays().toFixed(0),
      hours: timeLeft.hours(),
      minutes: timeLeft.minutes(),
      seconds: timeLeft.seconds()
    };
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  setType(typeNumber: number) {
    // Reset all parameters
    this.member = new Member();
    this.confirmPassword = null;

    this.currentType = typeNumber;
  }
}