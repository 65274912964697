<div class="card">
  <div class="card-content">
    <div class="row">
      <div class="col">
        <h1 class="card-title font-large-1">Total:<span class="float-right">{{total | number : '1.2'}}</span></h1>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <h1 class="card-title font-large-1">Tender:<span class="float-right">{{tenderAmount | number : '1.2'}}</span></h1>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <h1 class="card-title font-large-1">Balance:<span class="float-right">{{balance | number : '1.2'}}</span></h1>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <app-number-pad (checkout)="onCheckout($event)" (tenderAmountChange)="updateTenderAmount($event)" [enableCheckout]="isEnableCheckout"></app-number-pad>
      </div>
    </div>
  </div>
</div>