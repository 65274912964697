import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, Subject } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';
import { AuthService } from 'app/shared/auth/auth.service';
import { CompanyInfo, DepartmentInfo, LevelInfo, PositionInfo, Customer, RefMember, CompanyEmployee } from '../model/company';
import { CommonFunction } from '../common/common_function';
import { LocalStorageType } from '../model/system';
import { Member, MemberLocation } from '../model/member';
// import { openDB, deleteDB, wrap, unwrap } from 'idb';

@Injectable({
  providedIn: 'root'
})

export class CovidService {

  // Base url
  baseurl = CommonFunction.ServerURL + '/api/covid';

  // headers = new HttpHeaders();
  constructor(private http: HttpClient,
    private authService: AuthService) {
  }

  // GET
  LoginCompany(data): Observable<CompanyInfo> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }

    // ////console.log('headers: ' + JSON.stringify(httpOptions));

    return this.http.post<CompanyInfo>(this.baseurl + '/LoginCompany?data=' + CommonFunction.encryptCovid(JSON.stringify(data)), null, httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }
  
  // GET
  RegisterCompany(data): Observable<CompanyInfo> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }

    return this.http.post<CompanyInfo>(this.baseurl + '/RegisterCompany?data=' + CommonFunction.encryptCovid(JSON.stringify(data)), null, httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // GET
  AddRecord(data): Observable<Member> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }

    // ////console.log('headers: ' + JSON.stringify(httpOptions));

    return this.http.post<Member>(this.baseurl + '/AddRecord?data=' + CommonFunction.encryptCovid(JSON.stringify(data)), null, httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // GET
  GetTinyURL(data, needLogin : boolean) {
    // Http Headers
    // let httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'text/plain; charset=UTF-8',
    //     'accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9',
    //     'user-agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/81.0.4044.138 Safari/537.36',
    //     'upgrade-insecure-requests' : '1',
    //     'Server': 'cloudflare'
    //   })
    // }
    if(needLogin) {
      return this.http.get('https://cors-anywhere.herokuapp.com/https://tinyurl.com/api-create.php?url=https://shop.cloudbiz.asia/pages/covid?premise=' + data)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
    } else {
      return this.http.get('https://cors-anywhere.herokuapp.com/https://tinyurl.com/api-create.php?url=https://shop.cloudbiz.asia/pages/covid?premise=' + data +'&type=Visitor')
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
    }
  }

  // GET
  GenerateDailyReport(data): Observable<Member[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }

    // ////console.log('headers: ' + JSON.stringify(httpOptions));

    return this.http.post<Member[]>(this.baseurl + '/GenerateDailyReport?data=' + CommonFunction.encryptCovid(JSON.stringify(data)), null, httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // GET
  GenerateMonthlyReport(data): Observable<Member[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }

    // ////console.log('headers: ' + JSON.stringify(httpOptions));

    return this.http.post<Member[]>(this.baseurl + '/GenerateMonthlyReport?data=' + CommonFunction.encryptCovid(JSON.stringify(data)), null, httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // GET
  LoginMember(data): Observable<Member> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }

    // ////console.log('headers: ' + JSON.stringify(httpOptions));

    return this.http.post<Member>(this.baseurl + '/LoginMember?data=' + CommonFunction.encryptCovid(JSON.stringify(data)), null, httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // GET
  RetrieveMember(data): Observable<Member> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }

    // ////console.log('headers: ' + JSON.stringify(httpOptions));

    return this.http.post<Member>(this.baseurl + '/RetrieveMember?data=' + CommonFunction.encryptCovid(JSON.stringify(data)), null, httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // GET
  RetrieveCompany(data): Observable<CompanyInfo> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }

    // ////console.log('headers: ' + JSON.stringify(httpOptions));

    return this.http.post<CompanyInfo>(this.baseurl + '/RetrieveCompany?data=' + CommonFunction.encryptCovid(JSON.stringify(data)), null, httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // GET
  ResetPasswordCompany(data): Observable<CompanyInfo> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }

    // ////console.log('headers: ' + JSON.stringify(httpOptions));

    return this.http.post<CompanyInfo>(this.baseurl + '/ResetPasswordCompany?data=' + CommonFunction.encryptCovid(JSON.stringify(data)), null, httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // GET
  ResetPasswordUser(data): Observable<Member> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }

    // ////console.log('headers: ' + JSON.stringify(httpOptions));

    return this.http.post<Member>(this.baseurl + '/ResetPasswordUser?data=' + CommonFunction.encryptCovid(JSON.stringify(data)), null, httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }
  
  // GET
  RegisterMember(data): Observable<Member> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }

    return this.http.post<Member>(this.baseurl + '/RegisterMember?data=' + CommonFunction.encryptCovid(JSON.stringify(data)), null, httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // GET
  GetAllCustomer(data): Observable<Member[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }

    // ////console.log('headers: ' + JSON.stringify(httpOptions));

    return this.http.post<Member[]>(this.baseurl + '/GetAllCustomer?data=' + CommonFunction.encryptCovid(JSON.stringify(data)), null, httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // GET
  VisitCount(data): Observable<MemberLocation> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }

    return this.http.post<MemberLocation>(this.baseurl + '/VisitCount?data=' + CommonFunction.encryptCovid(JSON.stringify(data)), null, httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // Error handling
  errorHandl(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      ////console.log('Covid service error: ' + errorMessage);

      // Show connection error
      if(error.status == 0) {
        errorMessage = 'Failed to connect to server';
      } 
      // Do not show full detail of error, either show Internal Error from API or Error Code only
      else if(error.error) {
        errorMessage = error.error;
      } else {
        errorMessage = `Error Code: ${error.status}`;
      }
    }
    return throwError(errorMessage);
  }
}