import { ProductInfo, CategoryInfo } from './product';
import { CompanyInfo } from './company';
import { Member } from './member';
import { CommonFunction } from '../common/common_function';
import { BaseModel } from './base';

export class POSOrderItem {
    OrderItemID: string;
    Item: ProductInfo;
    ProductID: string;
    Quantity: number;
    Total: number;
    TaxPercentage: number;
    CustomerID: string;
    CompanyID: string;
    OrderID: string;
    DeviceID: string;
    CreatedBy: string;
    CreatedDateTime: Date;
    UpdatedDateTime: Date;   // Server updated time
    LastUpdatedDateTime: Date;  // Local updated time

    public constructor(init?: Partial<POSOrderItem>) {
        Object.assign(this, init);
    }
}


export class POSTransactionPayment extends BaseModel {
    POSTransactionPaymentID: string;
    POSTransactionOrderID: string;
    PaymentType: string;
    VoucherID: string;
    PaymentRefID: string;
    Amount: number;
    // CompanyID: string;
    // CreatedBy: string;
    // TransactionDateTime: Date;
    // CreatedDateTime: Date;
    // UpdatedDateTime: Date;   // Server updated time
    // LastUpdatedDateTime: Date;  // Local updated time

    public constructor(init?: Partial<POSTransactionPayment>) {
        super();
        Object.assign(this, init);
    }
}

export class POSTransactionAddress extends BaseModel {
    POSTransactionAddressID: string;
    POSTransactionOrderID: string;
    Name: string;
    UnitNo: string;
    Address: string;
    Email: string;
    PhoneNumber: string;
    GPS: string;
    // CreatedBy: string;
    // TransactionDateTime: Date;
    // CreatedDateTime: Date;
    // UpdatedDateTime: Date;   // Server updated time
    // LastUpdatedDateTime: Date;  // Local updated time

    public constructor(init?: Partial<POSTransactionAddress>) {
        super();
        Object.assign(this, init);
    }
}

export enum POSTransactionStatus {
    PendingPayment = 0,
    Paid = 1,
    Preparing = 2,
    Delivering = 3,
    Received = 4,
    Returned = 5,
    Refunded = 6,
    Cancelled = 7,
    VerifyingPayment = 8,
}

export class POSTransaction extends BaseModel {
    POSTransactionID: string;
    RefID: string;
    Line: string;
    ProductID: string;
    ProductMinOrderQty: number;
    CategoryID: string;
    CategoryMinOrderQty: number;
    CategoryName: string;
    ProductAttributeID: string;
    ProductAttributeName: string;
    OriginalName: string;
    Name: string;
    Unit: number;
    Price: number;
    Tax: number;
    Remark: string;
    DeviceID: string;
    CustomerID: string;
    // CompanyID: string;
    // CreatedBy: string;
    // TransactionDateTime: Date;
    // CreatedDateTime: Date;
    // UpdatedDateTime: Date;   // Server updated time
    // LastUpdatedDateTime: Date;  // Local updated time
    AttachmentPath: string; // Local record only
    Status: number;
    TaxName: string;
    TaxRate: number;

    public constructor(init?: Partial<POSTransaction>) {
        super();
        Object.assign(this, init);
    }
}

export class QuotationTransaction extends BaseModel {
    QuotationTransactionID: string;
    RefID: string;
    Line: string;
    ProductID: string;
    ProductMinOrderQty: number;
    CategoryID: string;
    CategoryMinOrderQty: number;
    CategoryName: string;
    ProductAttributeID: string;
    ProductAttributeName: string;
    OriginalName: string;
    Name: string;
    Unit: number;
    Price: number;
    Tax: number;
    Remark: string;
    DeviceID: string;
    CustomerID: string;
    // CompanyID: string;
    // CreatedBy: string;
    // TransactionDateTime: Date;
    // CreatedDateTime: Date;
    // UpdatedDateTime: Date;   // Server updated time
    // LastUpdatedDateTime: Date;  // Local updated time
    AttachmentPath: string; // Local record only
    Status: number;
    TaxName: string;
    TaxRate: number;

    public constructor(init?: Partial<QuotationTransaction>) {
        super();
        Object.assign(this, init);
    }
}

export class PurchaseOrderTransaction extends BaseModel {
    PurchaseOrderTransactionID: string;
    RefID: string;
    Line: string;
    ProductID: string;
    ProductMinOrderQty: number;
    CategoryID: string;
    CategoryMinOrderQty: number;
    CategoryName: string;
    ProductAttributeID: string;
    ProductAttributeName: string;
    OriginalName: string;
    Name: string;
    Unit: number;
    Price: number;
    Tax: number;
    Remark: string;
    DeviceID: string;
    CustomerID: string;
    // CompanyID: string;
    // CreatedBy: string;
    // TransactionDateTime: Date;
    // CreatedDateTime: Date;
    // UpdatedDateTime: Date;   // Server updated time
    // LastUpdatedDateTime: Date;  // Local updated time
    AttachmentPath: string; // Local record only
    Status: number;
    TaxName: string;
    TaxRate: number;

    public constructor(init?: Partial<PurchaseOrderTransaction>) {
        super();
        Object.assign(this, init);
    }
}

export class eCommerceOrder {
    Supplier: CompanyInfo;
    Customer: Member;
    OrderID: string;
    Orders: POSTransaction[];
    Products: ProductInfo[];
    ProductPrice: number;
    ShippingFees: number;
    Fees: number;
    Address: POSTransactionAddress;
    Voucher: POSVoucher;
    ErrorMessage: string;

    public constructor(init?: Partial<eCommerceOrder>) {
        Object.assign(this, init);
    }
}

export class eCommerceQuotation {
    Supplier: CompanyInfo;
    Customer: Member;
    OrderID: string;
    Orders: QuotationTransaction[];
    Products: ProductInfo[];
    ProductPrice: number;
    ShippingFees: number;
    Fees: number;
    Address: POSTransactionAddress;
    Voucher: POSVoucher;
    ErrorMessage: string;

    public constructor(init?: Partial<eCommerceQuotation>) {
        Object.assign(this, init);
    }
}

export class eCommercePurchaseOrder {
    Supplier: CompanyInfo;
    Customer: Member;
    OrderID: string;
    Orders: PurchaseOrderTransaction[];
    Products: ProductInfo[];
    ProductPrice: number;
    ShippingFees: number;
    Fees: number;
    Address: POSTransactionAddress;
    Voucher: POSVoucher;
    ErrorMessage: string;

    public constructor(init?: Partial<eCommercePurchaseOrder>) {
        Object.assign(this, init);
    }
}


export class POSVoucher {
    POSVoucherID: string;
    POSVoucherCode: string;
    Amount: number;
    ApplicableCategoryIDs: string;
    ApplicableCategories: CategoryInfo[];
    ApplicableProductIDs: string;
    ApplicableProducts: ProductInfo[];
    CompanyID: string;
    CompanyInfo: CompanyInfo;
    CreatedBy: string;
    CreatedDateTime: Date;
    RedeemedBy: string;
    RedeemedDateTime?: Date;
    ExpiryDateTime: Date;
    UpdatedDateTime: Date;   // Server updated time
    LastUpdatedDateTime: Date;  // Local updated time
    CustomerID: string;
    CustomerInfo: Member;
    POSTransactionOrderID: string;
    CreditFrom: POSVoucherCreditFrom;
    Enabled: boolean;
    MinTotal: number;

    public constructor(init?: Partial<POSVoucher>) {
        Object.assign(this, init);
    }
}

export enum POSVoucherCreditFrom {
    Seller = 0,
    SystemOnSellerRule = 1,
}

export class PendingPOSTransaction {
    id: string = CommonFunction.GenGUID();
    customerId: string;
    transactionDateTime: Date;
    transactionRows: POSTransaction[] = [];

    public constructor(init?: Partial<PendingPOSTransaction>) {
        Object.assign(this, init);
    }
}

export class PendingQuotationTransaction {
    id: string = CommonFunction.GenGUID();
    customerId: string;
    transactionDateTime: Date;
    transactionRows: QuotationTransaction[] = [];

    public constructor(init?: Partial<PendingQuotationTransaction>) {
        Object.assign(this, init);
    }
}

export class PendingPurchaseOrderTransaction {
    id: string = CommonFunction.GenGUID();
    customerId: string;
    transactionDateTime: Date;
    transactionRows: PurchaseOrderTransaction[] = [];

    public constructor(init?: Partial<PendingPurchaseOrderTransaction>) {
        Object.assign(this, init);
    }
}

export class TaxSummaryInfo {
    Code: string;
    ProductAmount: number;
    TaxAmount: number;

    public constructor(init?: Partial<TaxSummaryInfo>) {
        Object.assign(this, init);
    }
}

export class TaxInfo {
    TaxID: string;
    Name: string;
    Rate: number;
    Remark: string;
    DeviceID: string;
    CompanyID: string;
    CreatedBy: string;
    CreatedDateTime: Date;
    TransactionDateTime: Date;
    UpdatedDateTime: Date;

    public constructor(init?: Partial<TaxInfo>) {
        Object.assign(this, init);
    }
}

export class ReceiptInfo {
    LogoBase64: string;
    BoldHeader: string[];
    Header: string[];
    Title: string[];
    InvertTitle: string[];
    TransactionNoBarcode: string;  // Barcode Base64 data
    TransactionNo: string;  // Invoice No, Receipt No
    TransactionType: string;    // Dine In / Take Away / Grab / Quotation
    RefNo: string;  // Table No
    TransactionDate: Date;
    PersonInCharge: string;
    Items: POSTransaction[];
    TotalItem: number;  // Total Type of Item
    TotalQty: number;   // Total Quantity of Item (Mix of all type)
    TotalDiscount: number;  // equal to TotalSaving
    Subtotal: number;
    TotalServiceCharge: number;
    TotalTax: number;
    Rounding: number;
    Total: number;  // Final amount after discount
    TenderAmount: number;  // Customer paid amount
    BalanceAmount: number;  // Customer paid amount - Total
    PaymentType: string;    // Credit Card (Visa/Master/AMEX), Cash, eWallet
    PaymentRefNo: string;   // Last 4 digit
    PaymentTransactionNo: string;
    TaxSummary: TaxSummaryInfo[];
    Footer: string[];
    BoldFooter: string[];

    CreatedBy: string;
    CreatedDateTime: Date;

    Remark: string;

    // Purchase Order
    SupplierInfo: string[];

    SenderAddress: string[];
    SenderContactNo: string;
    SenderContactName: string;
    
    ShipToAddress: string[];
    ShipToContactNo: string;
    ShipToContactName: string;

    TermNCondition: string;

    BankName: string;
    BankAccName: string;
    BankAccNo: string;
    BankSwiftCode: string;

    public constructor(init?: Partial<ReceiptInfo>) {
        Object.assign(this, init);
    }
}

export class QuotationInfo {
    LogoBase64: string;
    BoldHeader: string[];
    Header: string[];
    Title: string[];
    InvertTitle: string[];
    TransactionNoBarcode: string;  // Barcode Base64 data
    TransactionNo: string;  // Invoice No, Receipt No
    TransactionType: string;    // Dine In / Take Away / Grab / Quotation
    RefNo: string;  // Table No
    TransactionDate: Date;
    PersonInCharge: string;
    Items: QuotationTransaction[];
    TotalItem: number;  // Total Type of Item
    TotalQty: number;   // Total Quantity of Item (Mix of all type)
    TotalDiscount: number;  // equal to TotalSaving
    Subtotal: number;
    TotalServiceCharge: number;
    TotalTax: number;
    Rounding: number;
    Total: number;  // Final amount after discount
    TenderAmount: number;  // Customer paid amount
    BalanceAmount: number;  // Customer paid amount - Total
    PaymentType: string;    // Credit Card (Visa/Master/AMEX), Cash, eWallet
    PaymentRefNo: string;   // Last 4 digit
    PaymentTransactionNo: string;
    TaxSummary: TaxSummaryInfo[];
    Footer: string[];
    BoldFooter: string[];

    CreatedBy: string;
    CreatedDateTime: Date;

    Remark: string;

    // Purchase Order
    SupplierInfo: string[];

    BillToAddress: string[];
    BillToContactNo: string;
    BillToContactName: string;
    
    ShipToAddress: string[];
    ShipToContactNo: string;
    ShipToContactName: string;

    TermNCondition: string;

    BankName: string;
    BankAccName: string;
    BankAccNo: string;
    BankSwiftCode: string;

    public constructor(init?: Partial<QuotationInfo>) {
        Object.assign(this, init);
    }
}

export class PurchaseOrderInfo {
    LogoBase64: string;
    BoldHeader: string[];
    Header: string[];
    Title: string[];
    InvertTitle: string[];
    TransactionNoBarcode: string;  // Barcode Base64 data
    TransactionNo: string;  // Invoice No, Receipt No
    TransactionType: string;    // Dine In / Take Away / Grab / Quotation
    RefNo: string;  // Table No
    TransactionDate: Date;
    PersonInCharge: string;
    Items: PurchaseOrderTransaction[];
    TotalItem: number;  // Total Type of Item
    TotalQty: number;   // Total Quantity of Item (Mix of all type)
    TotalDiscount: number;  // equal to TotalSaving
    Subtotal: number;
    TotalServiceCharge: number;
    TotalTax: number;
    Rounding: number;
    Total: number;  // Final amount after discount
    TenderAmount: number;  // Customer paid amount
    BalanceAmount: number;  // Customer paid amount - Total
    PaymentType: string;    // Credit Card (Visa/Master/AMEX), Cash, eWallet
    PaymentRefNo: string;   // Last 4 digit
    PaymentTransactionNo: string;
    TaxSummary: TaxSummaryInfo[];
    Footer: string[];
    BoldFooter: string[];

    CreatedBy: string;
    CreatedDateTime: Date;

    Remark: string;

    // Purchase Order
    SupplierInfo: string[];

    BillToAddress: string[];
    BillToContactNo: string;
    BillToContactName: string;
    
    ShipToAddress: string[];
    ShipToContactNo: string;
    ShipToContactName: string;

    TermNCondition: string;

    BankName: string;
    BankAccName: string;
    BankAccNo: string;
    BankSwiftCode: string;

    public constructor(init?: Partial<PurchaseOrderInfo>) {
        Object.assign(this, init);
    }
}

export class CashierNumberPadInfo {
    Total: number;
    Tender: number;
    Balance: number;

    public constructor(init?: Partial<CashierNumberPadInfo>) {
        Object.assign(this, init);
    }
}

export class POSOrder {
    Supplier: CompanyInfo;
    Customer: Member;
    OrderID: string;
    Orders: POSTransaction[];   // Append Tax / Payment as 1 item. // Tax recorded at Original Name, GST 6% or Service Tax name will be record in Name
    ProductPrice: number;
    ShippingFees: number;
    Fees: number;
    Address: POSTransactionAddress;
    Voucher: POSVoucher;
    ErrorMessage: string;

    public constructor(init?: Partial<POSOrder>) {
        Object.assign(this, init);
    }
}

// export class Delivery extends BaseModel {
//     SenderCompany: CompanyInfo = new CompanyInfo();
//     SenderCompany: CompanyInfo = new CompanyInfo();
//     ReceiverCompany: CompanyInfo = new CompanyInfo();
//     DeliveryID: string;
//     SenderCompanyID: string;
//     SenderCompanyID: string;
//     ReceiverCompanyID: string;
//     // CompanyID: string;
//     RefID: string;
//     OrderRefID: string;
//     TrackingNo: string;
//     Remark: string;
//     // CreatedBy: string;
//     Items: ItemInfo[];
//     // TransactionDateTime: Date;
//     // CreatedDateTime: Date;
//     // UpdatedDateTime: Date;   // Server updated time
//     // LastUpdatedDateTime: Date;  // Local updated time

//     public constructor(init?: Partial<Delivery>) {
//         super();
//         Object.assign(this, init);
//     }
// }

export class Order extends BaseModel {
    BillToCompanyName: string;
    BillToCompanyAddress: string;
    BillToCompanyContactNo: string;
    BillToCompanyReceiverName: string;
    ReceiverCompanyName: string;
    ReceiverCompanyAddress: string;
    ReceiverCompanyContactNo: string;
    ReceiverCompanyReceiverName: string;
    OrderID: string;    // Unique ID
    RefID: string;  // Order No (readable)
    QuotationRefID: string; // Quotation No
    Remark: string; // Remark
    Status: number; // Record Order Status
    StatusDesc: string; // Record Order Status
    OrderTransactions: OrderTransaction[];
    CustomerID: string; // Remark

    public constructor(init?: Partial<Order>) {
        super();
        Object.assign(this, init);
    }
}

export class OrderTransaction extends BaseModel {
    OrderTransactionID: string;
    OrderID: string;
    RefID: string;  // Record Order No
    Line: number;   // Record Sequence of Line
    ProductID: string;
    ProductName: string;    // Record New Name
    OriginalName: string;   // Record Original Name
    ProductAttributeID: string;
    ProductAttributeName: string; // Record Attribute Name
    CategoryID: string;
    CategoryName: string;  // Record Category Name
    TaxName: string;  // Record Tax Name
    TaxRate: number;  // Record Tax Rate
    Unit: number;
    UnitPrice: number;
    ProductTotal: number;  // Record Unit * Unit Price
    Tax: number;  // Record Unit * Unit Price
    Total: number;  // Record Product Price with Tax
    IsTaxIncludedInPrice: boolean;  // Record Product Price included Tax or calculate
    Remark: string;  // Record Remark (SN, Reference ID and etc)
    DeviceID: string;  // Record Device that trigger this record for Offline or Security purpose
    CustomerID: string;  // Record Customer ID
    AttachmentPath: string; // Record Product Image
    Status: number; // Record Order Status

    public constructor(init?: Partial<OrderTransaction>) {
        super();
        Object.assign(this, init);
    }
}

export class CustomerDelivery extends BaseModel {
    // SenderCompany: CompanyInfo = new CompanyInfo();
    // SenderCompany: CompanyInfo = new CompanyInfo();
    // ReceiverCompany: CompanyInfo = new CompanyInfo();
    BillToCompanyName: string;
    BillToCompanyAddress: string;
    BillToCompanyContactNo: string;
    BillToCompanyReceiverName: string;
    ReceiverCompanyName: string;
    ReceiverCompanyAddress: string;
    ReceiverCompanyContactNo: string;
    ReceiverCompanyReceiverName: string;
    CustomerDeliveryID: string;    // Unique ID
    RefID: string;  // Order No (readable)
    OrderRefID: string; // Quotation No
    TrackingNo: string; // Remark
    Remark: string; // Remark
    Status: number; // Record Order Status
    StatusDesc: string; // Record Order Status
    CustomerDeliveryTransactions: CustomerDeliveryTransaction[];
    CustomerID: string; // Remark

    public constructor(init?: Partial<CustomerDelivery>) {
        super();
        Object.assign(this, init);
    }
}

export class CustomerDeliveryTransaction extends BaseModel {
    CustomerDeliveryTransactionID: string;
    RefID: string;  // Record Order No
    Line: number;   // Record Sequence of Line
    ProductID: string;
    ProductName: string;    // Record New Name
    OriginalName: string;   // Record Original Name
    ProductAttributeID: string;
    ProductAttributeName: string; // Record Attribute Name
    CategoryID: string;
    CategoryName: string;  // Record Category Name
    TaxName: string;  // Record Tax Name
    TaxRate: number;  // Record Tax Rate
    Unit: number;
    UnitPrice: number;
    ProductTotal: number;  // Record Unit * Unit Price
    Tax: number;  // Record Unit * Unit Price
    Total: number;  // Record Product Price with Tax
    IsTaxIncludedInPrice: boolean;  // Record Product Price included Tax or calculate
    Remark: string;  // Record Remark (SN, Reference ID and etc)
    DeviceID: string;  // Record Device that trigger this record for Offline or Security purpose
    AttachmentPath: string; // Record Product Image
    Status: number; // Record Order Status
    CustomerDeliveryTransactionQRs: CustomerDeliveryTransactionQR[];
    IsCollapse: boolean; // Local Record
    DeliverQty: number; // Local Record
    BalanceQty: number; // Local Record

    public constructor(init?: Partial<CustomerDeliveryTransaction>) {
        super();
        Object.assign(this, init);
    }
}

export class CustomerDeliveryTransactionQR {
    CustomerDeliveryTransactionQRID: string;
    CustomerDeliveryTransactionID: string;
    RefID: string;
    QRCode: string;
    Remark: string;
    Qty: number;
    CreatedDateTime: Date = new Date();

    public constructor(init?: Partial<CustomerDeliveryTransactionQR>) {
        Object.assign(this, init);
    }
}


export class SupplierOrder extends BaseModel {
    SenderCompanyName: string;
    SenderCompanyAddress: string;
    SenderCompanyContactNo: string;
    SenderCompanyReceiverName: string;
    SupplierOrderID: string;    // Unique ID
    RefID: string;  // Order No (readable)
    QuotationRefID: string; // Quotation No
    Remark: string; // Remark
    Status: number; // Record Order Status
    StatusDesc: string; // Record Order Status
    SupplierOrderTransactions: SupplierOrderTransaction[];
    SupplierID: string; // Remark

    public constructor(init?: Partial<SupplierOrder>) {
        super();
        Object.assign(this, init);
    }
}

export class SupplierOrderTransaction extends BaseModel {
    SupplierOrderTransactionID: string;
    SupplierOrderID: string;
    RefID: string;  // Record Order No
    Line: number;   // Record Sequence of Line
    ProductID: string;
    ProductName: string;    // Record New Name
    OriginalName: string;   // Record Original Name
    ProductAttributeID: string;
    ProductAttributeName: string; // Record Attribute Name
    CategoryID: string;
    CategoryName: string;  // Record Category Name
    TaxName: string;  // Record Tax Name
    TaxRate: number;  // Record Tax Rate
    Unit: number;
    UnitPrice: number;
    ProductTotal: number;  // Record Unit * Unit Price
    Tax: number;  // Record Unit * Unit Price
    Total: number;  // Record Product Price with Tax
    IsTaxIncludedInPrice: boolean;  // Record Product Price included Tax or calculate
    Remark: string;  // Record Remark (SN, Reference ID and etc)
    DeviceID: string;  // Record Device that trigger this record for Offline or Security purpose
    SupplierID: string;  // Record Customer ID
    AttachmentPath: string; // Record Product Image
    Status: number; // Record Order Status
    IsCollapse: boolean; // Local Record
    DeliverQty: number; // Local Record
    BalanceQty: number; // Local Record

    public constructor(init?: Partial<SupplierOrderTransaction>) {
        super();
        Object.assign(this, init);
    }
}

export class SupplierDelivery extends BaseModel {
    SenderCompanyName: string;
    SenderCompanyAddress: string;
    SenderCompanyContactNo: string;
    SenderCompanyReceiverName: string;
    SupplierDeliveryID: string;    // Unique ID
    RefID: string;  // Order No (readable)
    OrderRefID: string; // Quotation No
    TrackingNo: string; // Remark
    Remark: string; // Remark
    Status: number; // Record Order Status
    StatusDesc: string; // Record Order Status
    SupplierDeliveryTransactions: SupplierDeliveryTransaction[];
    SupplierID: string; // Remark

    public constructor(init?: Partial<SupplierDelivery>) {
        super();
        Object.assign(this, init);
    }
}

export class SupplierDeliveryTransaction extends BaseModel {
    SupplierDeliveryTransactionID: string;
    RefID: string;  // Record Order No
    Line: number;   // Record Sequence of Line
    ProductID: string;
    ProductName: string;    // Record New Name
    OriginalName: string;   // Record Original Name
    ProductAttributeID: string;
    ProductAttributeName: string; // Record Attribute Name
    CategoryID: string;
    CategoryName: string;  // Record Category Name
    TaxName: string;  // Record Tax Name
    TaxRate: number;  // Record Tax Rate
    Unit: number;
    UnitPrice: number;
    ProductTotal: number;  // Record Unit * Unit Price
    Tax: number;  // Record Unit * Unit Price
    Total: number;  // Record Product Price with Tax
    IsTaxIncludedInPrice: boolean;  // Record Product Price included Tax or calculate
    Remark: string;  // Record Remark (SN, Reference ID and etc)
    DeviceID: string;  // Record Device that trigger this record for Offline or Security purpose
    AttachmentPath: string; // Record Product Image
    Status: number; // Record Order Status
    SupplierDeliveryTransactionQRs: SupplierDeliveryTransactionQR[];
    IsCollapse: boolean; // Local Record
    DeliverQty: number; // Local Record
    BalanceQty: number; // Local Record

    public constructor(init?: Partial<SupplierDeliveryTransaction>) {
        super();
        Object.assign(this, init);
    }
}

export class SupplierDeliveryTransactionQR {
    SupplierDeliveryTransactionQRID: string;
    SupplierDeliveryTransactionID: string;
    RefID: string;
    QRCode: string;
    Remark: string;
    Qty: number;
    CreatedDateTime: Date = new Date();

    public constructor(init?: Partial<SupplierDeliveryTransactionQR>) {
        Object.assign(this, init);
    }
}

export class StockSummary {
    ProductID: string;
    CompanyID: string;
    In: number;
    InReturn: number;
    Out: number;
    OutReturn: number;
    Process: number;
    Balance: number;
    ProductInfo: ProductInfo;

    public constructor(init?: Partial<StockSummary>) {
        Object.assign(this, init);
    }
}

export class QRStockSummary {
    ProductID: string;
    CompanyID: string;
    QRCode: string;
    In: number;
    InReturn: number;
    Out: number;
    OutReturn: number;
    Process: number;
    Balance: number;
    TransactionDateTime: Date;
    ProductInfo: ProductInfo;

    public constructor(init?: Partial<QRStockSummary>) {
        Object.assign(this, init);
    }
}

export class SupplierReturn extends BaseModel {
    SenderCompanyName: string;
    SenderCompanyAddress: string;
    SenderCompanyContactNo: string;
    SenderCompanyReceiverName: string;
    SupplierReturnID: string;    // Unique ID
    RefID: string;  // Order No (readable)
    SupplierDeliveryRefID: string; // Delivery No
    TrackingNo: string; // Remark
    Remark: string; // Remark
    Status: number; // Record Order Status
    SupplierReturnTransactions: SupplierReturnTransaction[];
    SupplierID: string; // Remark

    public constructor(init?: Partial<SupplierReturn>) {
        super();
        Object.assign(this, init);
    }
}

export class SupplierReturnTransaction extends BaseModel {
    SupplierReturnTransactionID: string;
    RefID: string;  // Record Order No
    Line: number;   // Record Sequence of Line
    ProductID: string;
    ProductName: string;    // Record New Name
    OriginalName: string;   // Record Original Name
    ProductAttributeID: string;
    ProductAttributeName: string; // Record Attribute Name
    CategoryID: string;
    CategoryName: string;  // Record Category Name
    TaxName: string;  // Record Tax Name
    TaxRate: number;  // Record Tax Rate
    Unit: number;
    UnitPrice: number;
    ProductTotal: number;  // Record Unit * Unit Price
    Tax: number;  // Record Unit * Unit Price
    Total: number;  // Record Product Price with Tax
    IsTaxIncludedInPrice: boolean;  // Record Product Price included Tax or calculate
    Remark: string;  // Record Remark (SN, Reference ID and etc)
    DeviceID: string;  // Record Device that trigger this record for Offline or Security purpose
    AttachmentPath: string; // Record Product Image
    Status: number; // Record Order Status
    SupplierReturnTransactionQRs: SupplierReturnTransactionQR[];
    IsCollapse: boolean; // Local Record
    DeliverQty: number; // Local Record
    BalanceQty: number; // Local Record

    public constructor(init?: Partial<SupplierReturnTransaction>) {
        super();
        Object.assign(this, init);
    }
}

export class SupplierReturnTransactionQR {
    SupplierReturnTransactionQRID: string;
    SupplierReturnTransactionID: string;
    RefID: string;
    QRCode: string;
    Remark: string;
    Qty: number;
    CreatedDateTime: Date = new Date();

    public constructor(init?: Partial<SupplierReturnTransactionQR>) {
        Object.assign(this, init);
    }
}

export class CustomerReturn extends BaseModel {
    BillToCompanyName: string;
    BillToCompanyAddress: string;
    BillToCompanyContactNo: string;
    BillToCompanyReceiverName: string;
    ReceiverCompanyName: string;
    ReceiverCompanyAddress: string;
    ReceiverCompanyContactNo: string;
    ReceiverCompanyReceiverName: string;
    CustomerReturnID: string;    // Unique ID
    RefID: string;  // Order No (readable)
    CustomerDeliveryRefID: string; // Delivery No
    TrackingNo: string; // Remark
    Remark: string; // Remark
    Status: number; // Record Order Status
    CustomerReturnTransactions: CustomerReturnTransaction[];
    CustomerID: string; // Remark

    public constructor(init?: Partial<CustomerReturn>) {
        super();
        Object.assign(this, init);
    }
}

export class CustomerReturnTransaction extends BaseModel {
    CustomerReturnTransactionID: string;
    RefID: string;  // Record Order No
    Line: number;   // Record Sequence of Line
    ProductID: string;
    ProductName: string;    // Record New Name
    OriginalName: string;   // Record Original Name
    ProductAttributeID: string;
    ProductAttributeName: string; // Record Attribute Name
    CategoryID: string;
    CategoryName: string;  // Record Category Name
    TaxName: string;  // Record Tax Name
    TaxRate: number;  // Record Tax Rate
    Unit: number;
    UnitPrice: number;
    ProductTotal: number;  // Record Unit * Unit Price
    Tax: number;  // Record Unit * Unit Price
    Total: number;  // Record Product Price with Tax
    IsTaxIncludedInPrice: boolean;  // Record Product Price included Tax or calculate
    Remark: string;  // Record Remark (SN, Reference ID and etc)
    DeviceID: string;  // Record Device that trigger this record for Offline or Security purpose
    AttachmentPath: string; // Record Product Image
    Status: number; // Record Order Status
    CustomerReturnTransactionQRs: CustomerReturnTransactionQR[];
    IsCollapse: boolean; // Local Record
    DeliverQty: number; // Local Record
    BalanceQty: number; // Local Record

    public constructor(init?: Partial<CustomerReturnTransaction>) {
        super();
        Object.assign(this, init);
    }
}

export class CustomerReturnTransactionQR {
    CustomerReturnTransactionQRID: string;
    CustomerReturnTransactionID: string;
    RefID: string;
    QRCode: string;
    Remark: string;
    Qty: number;
    CreatedDateTime: Date = new Date();

    public constructor(init?: Partial<CustomerReturnTransactionQR>) {
        Object.assign(this, init);
    }
}

export class ProductFormula extends BaseModel {
    ProductFormulaID: string;    // Unique ID
    Product: ProductInfo = new ProductInfo({
       ProductID: CommonFunction.GenGUID()
    });
    ProductID: string;    // Product ID
    ProductFormulaTransactions: ProductFormulaTransaction[];
    RefID: string;  // Product Formula No (readable)
    Remark: string; // Remark

    public constructor(init?: Partial<ProductFormula>) {
        super();
        Object.assign(this, init);
    }
}

export class ProductFormulaTransaction extends BaseModel {
    ProductFormulaTransactionID: string;    // Unique ID
    ProductFormulaID: string;    // Parent ID
    RefID: string;  // Record Product Process No
    Line: number;   // Record Sequence of Line
    ProductID: string;
    ProductName: string;    // Record New Name
    Unit: number;
    Remark: string;  // Record Remark (SN, Reference ID and etc)
    DeviceID: string;  // Record Device that trigger this record for Offline or Security purpose

    public constructor(init?: Partial<ProductFormulaTransaction>) {
        super();
        Object.assign(this, init);
    }
}

export class ProductProcess extends BaseModel {
    ProductProcessID: string;    // Unique ID
    Product: ProductInfo = new ProductInfo({
       ProductID: CommonFunction.GenGUID()
    });
    ProductFormulaID: string;    // Product Formula No
    ProductProcessTransactions: ProductProcessTransaction[];
    RefID: string;  // Product Process No (readable)
    Remark: string; // Remark
    Unit: number;
    Status: number; // Record Order Status  0: New, 1: In Process, 2: Completed, 3: Cancelled
    StatusDesc: string;

    public constructor(init?: Partial<ProductProcess>) {
        super();
        Object.assign(this, init);
    }
}

export class ProductProcessTransaction extends BaseModel {
    ProductProcessTransactionID: string;
    ProductProcessID: string;
    RefID: string;  // Record Order No
    Line: number;   // Record Sequence of Line
    ProductID: string;
    ProductName: string;    // Record New Name
    OriginalName: string;   // Record Original Name
    ProductAttributeID: string;
    ProductAttributeName: string; // Record Attribute Name
    CategoryID: string;
    CategoryName: string;  // Record Category Name
    TaxName: string;  // Record Tax Name
    TaxRate: number;  // Record Tax Rate
    Unit: number;
    UnitPrice: number;
    ProductTotal: number;  // Record Unit * Unit Price
    Tax: number;  // Record Unit * Unit Price
    Total: number;  // Record Product Price with Tax
    IsTaxIncludedInPrice: boolean;  // Record Product Price included Tax or calculate
    Remark: string;  // Record Remark (SN, Reference ID and etc)
    DeviceID: string;  // Record Device that trigger this record for Offline or Security purpose
    AttachmentPath: string; // Record Product Image
    Status: number; // Record Order Status
    ProductProcessTransactionQRs: ProductProcessTransactionQR[];
    IsCollapse: boolean; // Local Record
    DeliverQty: number; // Local Record
    BalanceQty: number; // Local Record

    public constructor(init?: Partial<ProductProcessTransaction>) {
        super();
        Object.assign(this, init);
    }
}

export class ProductProcessTransactionQR {
    ProductProcessTransactionQRID: string;
    ProductProcessTransactionID: string;
    RefID: string;
    QRCode: string;
    Remark: string;
    Qty: number;
    CreatedDateTime: Date = new Date();

    public constructor(init?: Partial<ProductProcessTransactionQR>) {
        Object.assign(this, init);
    }
}