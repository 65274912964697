import { RouteInfo } from './sidebar-visitor.metadata';

//Sidebar menu Routes and data
export const VisitorROUTES: RouteInfo[] = [

    {
        path: '/pages/home', title: 'Home', icon: 'ft-home', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
        ]
    },
    {
        path: '/pages/shoppingcart', title: 'Shopping Cart', icon: 'ft-shopping-cart', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
        ]
    },
    {
        path: '/pages/covid', title: 'COVID-19 Feature', icon: 'icon-note', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
        ]
    },
    {
        path: '/pages/share-trading', title: 'Share Statistic', icon: 'icon-note', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
        ]
    },
];

export const UserROUTES: RouteInfo[] = [

    {
        path: '/pages/home', title: 'Home', icon: 'ft-home', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
        ]
    },
    {
        path: '/pages/shoppingcart', title: 'Shopping Cart', icon: 'ft-shopping-cart', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
        ]
    },
    {
        path: '/pages/order', title: 'My Order', icon: 'icon-bag', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
        ]
    },
    {
        path: '/pages/voucher', title: 'My Voucher', icon: 'icon-notebook', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
        ]
    },
    {
        path: '/pages/covid', title: 'COVID-19 Feature', icon: 'icon-note', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
        ]
    },
];