import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe } from "@angular/common";
import { RouterModule } from "@angular/router";

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";

//COMPONENTS
import { FooterComponent } from "./footer/footer.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { CustomizerComponent } from './customizer/customizer.component';
import { NotificationSidebarComponent } from './notification-sidebar/notification-sidebar.component';

//DIRECTIVES
import { ToggleFullscreenDirective } from "./directives/toggle-fullscreen.directive";
import { SidebarDirective } from './directives/sidebar.directive';
import { SidebarLinkDirective } from './directives/sidebarlink.directive';
import { SidebarListDirective } from './directives/sidebarlist.directive';
import { SidebarAnchorToggleDirective } from './directives/sidebaranchortoggle.directive';
import { SidebarToggleDirective } from './directives/sidebartoggle.directive';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NavbarVisitorComponent } from './navbar-visitor/navbar-visitor.component';
import { SidebarVisitorComponent } from './sidebar-visitor/sidebar-visitor.component';
// import { LocalStorageType } from './model/system';
import { LocalizedDatePipe } from './pipes/localizedDatePipe';
// import { LoginPageComponent } from 'app/pages/content-pages/login/login-page.component';
// import { ContentPagesModule } from 'app/pages/content-pages/content-pages.module';
import { ControlMessagesComponent } from './common/control_messages';
import { LoginPageComponent } from 'app/pages/content-pages/login/login-page.component';
import { RegisterPageComponent } from 'app/pages/content-pages/register/register-page.component';
import { ForgotPasswordPageComponent } from 'app/pages/content-pages/forgot-password/forgot-password-page.component';
import { TopScrollerComponent } from './top-scroller/top-scroller.component';
import { MonthDatePickerComponent } from './month-date-picker/month-date-picker.component';
import { NumberPadComponent } from './number-pad/number-pad.component';
import { CashierNumberPadComponent } from './cashier-number-pad/cashier-number-pad.component';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { PageAlertComponent } from './page-alert/page-alert.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MobileBarComponent } from './mobile-bar/mobile-bar.component';
import { MobileTopBarComponent } from './mobile-top-bar/mobile-top-bar.component';
import { MobileLoginComponent } from './mobile-login/mobile-login.component';
import { MobileJoinMemberComponent } from './mobile-join-member/mobile-join-member.component';
import { NavbarShopComponent } from './navbar-shop/navbar-shop.component';
import { TextAreaMultiLanguageComponent } from './textarea-multi-language/textarea-multi-language.component';
import { SpanMultiLanguageComponent } from './span-multi-language/span-multi-language.component';
import { SidebarShopComponent } from './sidebar-shop/sidebar-shop.component';
import { GalleryUploaderComponent } from './gallery-uploader/gallery-uploader.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MobileFloatingButtonComponent } from './mobile-floating-button/mobile-floating-button.component';

@NgModule({
    exports: [
        CommonModule,
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        CustomizerComponent,
        NotificationSidebarComponent,
        ToggleFullscreenDirective,
        SidebarDirective,
        NgbModule,
        TranslateModule,
        NavbarVisitorComponent,
        SidebarVisitorComponent,
        // LocalizedDatePipe,
        ControlMessagesComponent,
        ForgotPasswordPageComponent,
        LoginPageComponent,
        RegisterPageComponent,
        TopScrollerComponent,
        NumberPadComponent,
        CashierNumberPadComponent,
        AccessDeniedComponent,
        PageAlertComponent,
        MobileBarComponent,
        MobileTopBarComponent,
        MobileLoginComponent,
        MobileJoinMemberComponent,
        NavbarShopComponent,
        TextAreaMultiLanguageComponent,
        SpanMultiLanguageComponent,
        SidebarShopComponent,
        GalleryUploaderComponent,
        MobileFloatingButtonComponent
    ],
    imports: [
        RouterModule,
        CommonModule,
        NgbModule,
        TranslateModule,
        PerfectScrollbarModule,
        FormsModule,
        ReactiveFormsModule,
        ImageCropperModule
        // ContentPagesModule,
    ],
    declarations: [
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        CustomizerComponent,
        NotificationSidebarComponent,
        ToggleFullscreenDirective,
        SidebarDirective,
        SidebarLinkDirective,
        SidebarListDirective,
        SidebarAnchorToggleDirective,
        SidebarToggleDirective,
        NavbarVisitorComponent,
        SidebarVisitorComponent,
        // LocalizedDatePipe,
        ControlMessagesComponent,
        ForgotPasswordPageComponent,
        LoginPageComponent,
        RegisterPageComponent,
        TopScrollerComponent,
        MonthDatePickerComponent,
        NumberPadComponent,
        CashierNumberPadComponent,
        AccessDeniedComponent,
        PageAlertComponent,
        MobileBarComponent,
        MobileTopBarComponent,
        MobileLoginComponent,
        MobileJoinMemberComponent,
        NavbarShopComponent,
        TextAreaMultiLanguageComponent,
        SpanMultiLanguageComponent,
        SidebarShopComponent,
        GalleryUploaderComponent,
        MobileFloatingButtonComponent
    ],
    entryComponents: [
        ForgotPasswordPageComponent,
        LoginPageComponent,
        RegisterPageComponent
    ],
    providers: [ DatePipe, CurrencyPipe, LocalizedDatePipe] //LocalizedDatePipe
})
export class SharedModule { }
