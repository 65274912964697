import { Component, Output, EventEmitter, OnDestroy, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from '../services/layout.service';
import { Subscription, Observable } from 'rxjs';
import { ConfigService } from '../services/config.service';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { CompanyInfo, RefMember, Customer, CompanyEmployeeStatus } from '../model/company';
import { CompanyService } from '../services/company.service';
import { Member } from '../model/member';
import { LocalStorageType } from '../model/system';
import { MemberService } from '../services/member.service';
import { CommonFunction } from '../common/common_function';
import { LoginPageComponent } from 'app/pages/content-pages/login/login-page.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RegisterPageComponent } from 'app/pages/content-pages/register/register-page.component';
import { ForgotPasswordPageComponent } from 'app/pages/content-pages/forgot-password/forgot-password-page.component';
import { ApplicationStateService } from '../services/application-state.service';
import { ResizeService } from '../services/resize.service';
import { delay } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: "app-navbar-visitor",
  templateUrl: "./navbar-visitor.component.html",
  styleUrls: ["./navbar-visitor.component.scss"]
})
export class NavbarVisitorComponent implements OnInit, AfterViewInit, OnDestroy {
  isMobile = false;
  currentLang = "en";
  toggleClass = "ft-maximize";
  placement = "bottom-right";
  public isCollapsed = true;
  layoutSub: Subscription;
  @Output()
  toggleHideSidebar = new EventEmitter<Object>();

  username: string;
  browserLang: string;

  isCovid: boolean;

  public config: any = {};

  constructor(public translate: TranslateService, private layoutService: LayoutService, private configService: ConfigService, private router: Router, private authService: AuthService,
    private cmpServ: CompanyService,
    private mmbrServ: MemberService,
    private modalService: NgbModal,
    public resizeService: ResizeService,
    private cdr: ChangeDetectorRef) {

    // Use this function to detect 1st view
    this.isMobile = this.resizeService.getIsMobileResolution();

    // Use this to detect on screen change
    this.resizeService.onResize$
      .pipe(delay(0))
      .subscribe((x) => {
        if (x) {
          ////console.log('ShoppingCartComponent: ' + x)
          this.isMobile = x;
        }
      });

    // const browserLang: string = translate.getBrowserLang();

    this.browserLang = localStorage.getItem(LocalStorageType.Language)

    if (!this.browserLang) {
      this.browserLang = "en";
    }

    translate.use(this.browserLang.match(/en|es|pt|de|cn|my/) ? this.browserLang : "en");

    this.layoutSub = layoutService.changeEmitted$.subscribe(
      direction => {
        const dir = direction.direction;
        if (dir === "rtl") {
          this.placement = "bottom-left";
        } else if (dir === "ltr") {
          this.placement = "bottom-right";
        }
      });

    this.layoutService.modalChangeEmitted$.subscribe(
      modelName => {
        ////console.log('Modal Change: ' + modelName)

        switch (modelName) {
          case 'Landing-Login':
            this.openLandingLoginModal();
            break;

          case 'Login':
            this.openLoginModal();
            break;

          case 'Register':
            this.openRegisterModal();
            break;

          case 'ResetPassword':
            this.openForgetPasswordModal();
            break;
        }
      }
    );

    this.username = this.authService.getMember() ? this.authService.getMember().MemberName : null;
  }

  ngOnInit() {
    this.config = this.configService.templateConf;
  }

  ngAfterViewInit() {
    if (this.config.layout.dir) {
      setTimeout(() => {
        const dir = this.config.layout.dir;
        if (dir === "rtl") {
          this.placement = "bottom-left";
        } else if (dir === "ltr") {
          this.placement = "bottom-right";
        }
      }, 0);

    }

    ////console.log('NAv bar ngAfterViewInit')

    this.cdr.detectChanges();
  }

  ngAfterViewChecked() {
    if (this.router.url.indexOf('/pages/covid') == 0) {
      this.isCovid = true;
    } else {
      this.isCovid = false;
    }

    this.cdr.detectChanges()
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
  }

  ChangeLanguage(language: string) {
    localStorage.setItem(LocalStorageType.Language, language)
    this.browserLang = language;

    //console.log(this.browserLang)

    this.translate.use(language);
  }

  ToggleClass() {
    if (this.toggleClass === "ft-maximize") {
      this.toggleClass = "ft-minimize";
    } else {
      this.toggleClass = "ft-maximize";
    }
  }

  toggleNotificationSidebar() {
    this.layoutService.emitNotiSidebarChange(true);
  }

  toggleSidebar() {
    const appSidebar = document.getElementsByClassName("app-sidebar")[0];
    if (appSidebar.classList.contains("hide-sidebar")) {
      this.toggleHideSidebar.emit(false);
    } else {
      this.toggleHideSidebar.emit(true);
    }
  }

  Logout() {
    this.authService.logout();
    ////console.log('User logout')
    // this.router.navigateByUrl("/pages/home");

    // window.location.reload();
  }

  isUserLogIn() {
    var userInfo = this.authService.getToken();

    if (userInfo) {
      return true;
    } else {
      return false;
    }
  }

  goToShoppingCart() {
    this.router.navigateByUrl("/pages/shoppingcart");
  }

  openLandingLoginModal() {
    const modalRef = this.modalService.open(LoginPageComponent, { centered: true, size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.userType = 'buyer';
    modalRef.componentInstance.isLandingLogin = true;
  }

  openLoginModal() {
    const modalRef = this.modalService.open(LoginPageComponent, { centered: true, size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.userType = 'buyer';
  }

  openRegisterModal() {
    this.modalService.open(RegisterPageComponent, { centered: true, size: 'lg', backdrop: 'static' });
  }

  openForgetPasswordModal() {
    this.modalService.open(ForgotPasswordPageComponent, { centered: true, size: 'lg', backdrop: 'static' });
  }


  goToLogin() {
    this.layoutService.emitHomeModalChange('Login')
    // this.openLoginModal();
    // this.router.navigateByUrl("/pages/login/buyer");
  }

  async goToSeller() {
    // Only Email & Phone Number verified Member can go to Seller page
    if (CommonFunction.CheckValidDate(new Date(this.authService.getMember().EmailVerifiedDateTime)) && CommonFunction.CheckValidDate(new Date(this.authService.getMember().PhoneVerifiedDateTime))) {
      this.router.navigateByUrl("/dashboard/dashboard");
    } else {
      var confirmation = await Swal.fire({
        title: this.translate.instant('Account requried verification'),
        text: this.translate.instant("In order to become seller, we need to verify your email & phone number."),
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: this.translate.instant('No'),
        confirmButtonText: this.translate.instant('Okay, bring me to verification!')
      })

      if (confirmation) {
        this.router.navigateByUrl("/pages/customer-profile");
      }
    }
  }
}
