export const environment = {
  production: true,
  environmentName: 'prod',
  firebase: {
    apiKey: "AIzaSyA4VfEyBv_e8XltqW3G7tJV9gsNC1sbSB8",
    authDomain: "nextdatasolutionsfree.firebaseapp.com",
    databaseURL: "https://nextdatasolutionsfree.firebaseio.com",
    projectId: "nextdatasolutionsfree",
    storageBucket: "nextdatasolutionsfree.appspot.com",
    messagingSenderId: "508925957990",
    appId: "1:508925957990:web:f525c0d769d9034a489aa7"
  },
  serverURL: "https://shop.cloudbiz.asia",
  eCommLocalCheck: ":4700",
  isoLocalCheck: ":4701",
  devLocalCheck: ":4702",
  appLocalCheck: ":4703",
  eCommWebCheck: "https://shop.cloudbiz.asia",
  isoWebCheck: "https://iso.cloudbiz.asia",
  devWebCheck: "https://dorsett68.ddns.net",
  appWebCheck: "https://app.cloudbiz.asia",
  paypalID: "AQMFcvvFjPopQK-9n63HDFeGml0LCl8X9Ive2kbsjeuroM19WefOs2H8GtXLdN79CsiDmCga1uDrlr9l"
};
