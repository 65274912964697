<div #wrapper class="wrapper" [ngClass]="{
    'nav-collapsed': iscollapsed,
    'menu-collapsed': iscollapsed,
    'sidebar-sm': isSidebar_sm,
    'sidebar-lg': isSidebar_lg
  }" [dir]="options.direction">
  <app-navbar-visitor (toggleHideSidebar)="toggleHideSidebar($event)"></app-navbar-visitor>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="container-fluid">
        <router-outlet></router-outlet>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
  <app-notification-sidebar></app-notification-sidebar>
  <!-- <app-customizer (directionEvent)="getOptions($event)"></app-customizer> -->
  <app-top-scroller></app-top-scroller>
</div>