import {
    Component,
    OnInit,
    Output,
    EventEmitter,
    AfterViewInit,
    OnDestroy,
    ViewChild,
    ElementRef,
    Renderer2,
    HostListener
} from "@angular/core";
import { LayoutService } from "../services/layout.service";
import { ConfigService } from "../services/config.service";
import { Subscription } from "rxjs";

@Component({
    selector: "app-top-scroller",
    templateUrl: "./top-scroller.component.html",
    styleUrls: ["./top-scroller.component.scss"]
})
export class TopScrollerComponent {
    isShow: boolean;
    topPosToStartShowing = 500;

    constructor(
        private elRef: ElementRef,
        private renderer: Renderer2,
        private layoutService: LayoutService,
        private configService: ConfigService
    ) {
        // this.layoutSub = layoutService.customizerChangeEmitted$.subscribe(
        //   options => {
        //     if (options) {
        //       if (options.bgColor) {
        //         this.selectedBgColor = options.bgColor;
        //         this.selectedBgImage = options.bgImage;
        //       }
        //     }
        //   }
        // );
    }

    @HostListener('window:scroll')
    checkScroll() {

        // window의 scroll top
        // Both window.pageYOffset and document.documentElement.scrollTop returns the same result in all the cases. window.pageYOffset is not supported below IE 9.

        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

        if (scrollPosition >= this.topPosToStartShowing) {
            this.isShow = true;
        } else {
            this.isShow = false;
        }
    }

    scrollTop() {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }
}
