import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'localizedDate',
  pure: false
})


//https://stackoverflow.com/questions/34904683/how-to-set-locale-in-datepipe-in-angular-2
export class LocalizedDatePipe implements PipeTransform {

  constructor(private translateService: TranslateService) {
  }

  transform(value: Date, pattern: string = 'mediumDate'): any {
    if (!value) {
      return ''
    } else {
      let newData = new Date(value);
      let timeDiff = new Date().getTimezoneOffset();
      // ////console.log('LocalizedDatePipe value timeDiff: ' + timeDiff)

      const datePipe: DatePipe = new DatePipe("en"); //this.translateService.currentLang // TODO
      return datePipe.transform(newData.setMinutes(newData.getMinutes() - timeDiff), pattern);
    }
  }
}