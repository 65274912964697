<!--Registration Page Starts-->
<section id="regestration">
    <div class="container-fluid">
        <div class="row m-0">
            <div id="registerRow" class="col-12 d-flex align-items-center justify-content-center">
                <div class="card">
                    <div class="card-content">
                        <div class="card-body register-img">
                            <div class="row m-0">
                                <div class="col-lg-6 d-none d-lg-block py-2 px-3 text-center">
                                    <img src="../../assets/img/gallery/register.png" alt="" class="img-fluid mt-3 pl-3"
                                        width="400" height="230" />
                                </div>
                                <div class="col-lg-6 col-md-12 pt-3 px-4 bg-white">
                                    <span class="float-right"><a (click)="onCloseModal()" href="javascript:void(0)"><i
                                                class="ft-x font-large-1 text-muted"></i></a></span>

                                    <h4 class="card-title mb-2" translate>register.title</h4>
                                    <p class="card-text mb-3" translate>register.desc</p>
                                    <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                                        <input formControlName="memberName" type="text" class="form-control"
                                            [placeholder]="'register.username' | translate" />
                                        <control-messages class="errorMsgStyle" [control]="registerForm.get('memberName')">
                                        </control-messages>

                                        <input formControlName="email" type="text" class="form-control"
                                        [placeholder]="'register.email' | translate" />
                                        <control-messages class="errorMsgStyle" [control]="registerForm.get('email')">
                                        </control-messages>

                                        <input formControlName="password" type="password" class="form-control"
                                        [placeholder]="'register.password' | translate" />
                                        <control-messages class="errorMsgStyle" [control]="registerForm.get('password')">
                                        </control-messages>

                                        <input formControlName="confirmPassword" type="password" class="form-control"
                                        [placeholder]="'register.confirmPassword' | translate" />
                                        <control-messages class="errorMsgStyle" [control]="registerForm.get('confirmPassword')">
                                        </control-messages>

                                        <div class="custom-control custom-checkbox custom-control-inline">
                                            <input type="checkbox" id="customCheckboxInline1" formControlName="cbTermCond"
                                                name="customCheckboxInline1" class="custom-control-input" />
                                            <label class="custom-control-label" for="customCheckboxInline1" translate>register.tnc</label>
                                        </div>
                                        <control-messages class="errorMsgStyle" [control]="registerForm.get('cbTermCond')">
                                        </control-messages>

                                        <div class="fg-actions d-flex justify-content-between">
                                            <div class="login-btn">
                                                <button class="btn btn-outline-primary" type="button" (click)="goToLogin()"
                                                    [disabled]="isSubmitting">
                                                    <span class="text-decoration-none" translate>register.backLogin</span>
                                                </button>
                                            </div>
                                            <div class="recover-pass">
                                                <button class="btn btn-primary" type="submit"
                                                    [disabled]="isSubmitting">
                                                    <span class="text-decoration-none text-white" translate>register.register</span>
                                                    <i class="ft-loader spinner" [hidden]="!isSubmitting"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Registration Page Ends-->