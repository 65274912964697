import { Component, ViewChild } from '@angular/core';
import { MemberService } from 'app/shared/services/member.service';
import { Member } from 'app/shared/model/member';
import * as bcrypt from 'bcryptjs';
import { CommonFunction } from 'app/shared/common/common_function';
import { Router } from '@angular/router';

// Pop Up Alert
import Swal from 'sweetalert2';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LayoutService } from 'app/shared/services/layout.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidationService } from 'app/shared/common/validation_service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-register-page',
    templateUrl: './register-page.component.html',
    styleUrls: ['./register-page.component.scss']
})

export class RegisterPageComponent {
    registerForm: FormGroup;
    mmbr = new Member();
    confirmPassword: string;

    isSubmitting = false;
    // myform: FormGroup;
    // username: FormControl;
    // email: FormControl;
    // password: FormControl;
    // confirmPassword: FormControl;

    ngOnInit() {
        // this.createFormControls();
        // this.createForm();
    }

    // createFormControls() {
    //     this.username = new FormControl('', Validators.required);
    //     this.email = new FormControl('', [
    //         Validators.required,
    //         Validators.pattern("[^ @]*@[^ @]*")
    //     ]);
    //     this.password = new FormControl('', [
    //         Validators.required,
    //         Validators.minLength(8)
    //     ]);
    //     this.confirmPassword = new FormControl('', [
    //         Validators.required,
    //         Validators.minLength(8)
    //     ]);
    // }

    // createForm() {
    //     this.myform = new FormGroup({
    //         // name: new FormGroup({
    //         //     firstName: this.firstName,
    //         //     lastName: this.lastName,
    //         // }),
    //         username: this.username,
    //         password: this.password,
    //         confirmPassword: this.confirmPassword,
    //     });
    // }


    constructor(private mmbrServ: MemberService,
        public activeModal: NgbActiveModal,
        private layoutService: LayoutService,
        private formBuilder: FormBuilder,
        private translate: TranslateService,
        private validationServ: ValidationService,
        private router: Router) {
        this.registerForm = this.formBuilder.group({
            memberName: ['', [Validators.required, Validators.maxLength(40)]],
            email: ['', [Validators.required, Validators.maxLength(100), this.validationServ.emailValidator]],
            password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(50)]],
            confirmPassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(50)]],
            cbTermCond: ['', [Validators.required]]
        },
            {
                validator: [
                    this.validationServ.match('password', 'confirmPassword'),
                ]
            });
    }

    /**
     * https://w3path.com/angular-8-advance-form-validation/
     * ngOnInit() {
    this.registerForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', [Validators.required, ValidationService.password ]]
    }, {
      validator: [
        ValidationService.match('password', 'confirmPassword'),
      ]
    });
   
  }
     */
    //  On submit click, reset field value
    async onSubmit() {
        if (this.registerForm.invalid) {
            Object.keys(this.registerForm.controls).forEach(field => { // {1}
                const control = this.registerForm.get(field);            // {2}
                control.markAsTouched({ onlySelf: true });       // {3}
            });

            return;
        }

        this.mmbr.MemberName = this.registerForm.controls['memberName'].value;
        this.mmbr.Email = this.registerForm.controls['email'].value;
        this.mmbr.Password = this.registerForm.controls['password'].value;

        if (this.mmbr.MemberName.indexOf(' ') >= 0) {
            Swal.fire({
                icon: 'error',
                title: this.translate.instant('Error'),
                html: 'No empty space allowed in username.'
            });
            return;
        }

        this.isSubmitting = true;

        try {
            let usernameResult = await this.mmbrServ.IsMemberNameValid(this.mmbr).toPromise().catch(error => {
                throw error;
            })

            if (usernameResult) {
                throw 'Username already in used. Please use another username.';
            }

            let emailResult = await this.mmbrServ.IsEmailValid(this.mmbr).toPromise().catch(error => {
                throw error;
            })

            if (emailResult) {
                throw 'Email already in used. Please use another email.';
            }

            // hash the password before sending to server
            this.mmbr.Password = CommonFunction.hashPassword(this.mmbr.Password);
            this.mmbr.MemberID = CommonFunction.GenGUID();

            let registerResult = await this.mmbrServ.Register(this.mmbr).toPromise().catch(error => {
                throw error;
            })

            if (registerResult) {
                await Swal.fire({
                    icon: 'success',
                    title: this.translate.instant('Information'),
                    html: 'Registered successfully.',
                    confirmButtonText: this.translate.instant('Button.Confirm')
                });

                this.goToLogin();
            }
            else {
                throw 'Failed to register.';
            }
        } catch (e) {
            Swal.fire({
                icon: 'error',
                title: this.translate.instant('Error'),
                html: this.translate.instant(e)
            });

            this.isSubmitting = false;
        }
    }

    goToLogin() {
        this.openLoginModal();
    }

    openLoginModal() {
        this.activeModal.close();
        this.layoutService.emitHomeModalChange('Login')
    }

    onCloseModal() {
        this.activeModal.close();
    }
}
