import { Router } from '@angular/router';
import { Injectable, NgZone } from '@angular/core';
import { Member } from '../model/member';
import { CompanyEmployee, CompanyInfo, CompanyEmployeeModuleInfo } from '../model/company';
import { LocalStorageType } from '../model/system';
import { Subject } from 'rxjs';
import { environment } from 'environments/environment';
import { CommonFunction } from '../common/common_function';
import { KJUR, b64utoutf8 } from "jsrsasign";

@Injectable()
export class AuthService {
  // token: string;
  // company: string;

  // //Member
  // private emitMemberSource = new Subject<any>();
  // memberChangeEmitted$ = this.emitMemberSource.asObservable();
  // emitMemberChange(change: any) {
  //   this.emitMemberSource.next(change);
  // }

  constructor(private router: Router,
    private ngZone: NgZone) { }

  signupUser(email: string, password: string) {
    //your code for signing up the new user
  }

  signinUser(email: string, password: string) {
    //your code for checking credentials and getting tokens for for signing in user
  }

  logout() {
    // this.token = null;
    // this.company = null;
    ////console.log('Logout remove access token')
    localStorage.removeItem(LocalStorageType.AccessToken);
    localStorage.removeItem(LocalStorageType.CompanyID);
    localStorage.removeItem(LocalStorageType.Session);

    if (CommonFunction.IsISOPlatform()) {
      this.router.navigateByUrl('/pages/landing')
    } else if (CommonFunction.IsAppPlatform()) {
      window.location.reload();
    } else {
      this.router.navigateByUrl('/pages/home')
    }
  }

  shopLogout() {
    // this.token = null;
    // this.company = null;
    ////console.log('Logout remove access token')
    localStorage.removeItem(LocalStorageType.AccessToken);
    localStorage.removeItem(LocalStorageType.CompanyID);
    localStorage.removeItem(LocalStorageType.Session);

    // this.router.navigateByUrl('/' + localStorage.getItem(LocalStorageType.ShopName))
    // this.ngZone.run(() => window.location.reload())

    // If shop name is null, return to Main Page
    if (localStorage.getItem(LocalStorageType.ShopName)) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigateByUrl('/' + localStorage.getItem(LocalStorageType.ShopName)));
    } else {
      this.router.navigateByUrl('/')
    }
  }

  getToken() {
    return localStorage.getItem(LocalStorageType.AccessToken);//this.token;
  }

  getCompany() {
    return localStorage.getItem(LocalStorageType.CompanyID);//this.company;
  }

  getMember(): Member {
    return JSON.parse(localStorage.getItem(LocalStorageType.Member));//this.token;
  }

  getEmployee(): CompanyEmployee {
    return JSON.parse(localStorage.getItem(LocalStorageType.Employee));//this.token;
  }

  getCompanyInfo() {
    return JSON.parse(localStorage.getItem(LocalStorageType.CompanyInfo)) as CompanyInfo;//this.token;
  }

  getEmployeeModule() {
    return JSON.parse(localStorage.getItem(LocalStorageType.EmployeeModule)) as CompanyEmployeeModuleInfo[];//this.token;
  }

  setToken(token: string) {
    // this.emitMemberChange(token)
    return localStorage.setItem(LocalStorageType.AccessToken, token);//this.token;
  }

  setCompany(company: string) {
    return localStorage.setItem(LocalStorageType.CompanyID, company);//this.company;
  }

  setCompanyInfo(company: CompanyInfo) {
    return localStorage.setItem(LocalStorageType.CompanyInfo, JSON.stringify(company));//this.company;
  }

  setMember(member: Member) {
    return localStorage.setItem(LocalStorageType.Member, JSON.stringify(member));//this.company;
  }

  setEmployee(emply: CompanyEmployee) {
    return localStorage.setItem(LocalStorageType.Employee, JSON.stringify(emply));//this.company;
  }

  setEmployeeModule(modules: CompanyEmployeeModuleInfo[]) {
    return localStorage.setItem(LocalStorageType.EmployeeModule, JSON.stringify(modules));//this.company;
  }

  isAuthenticated() {
    // // here you can check if user is authenticated or not through his token 
    // if(localStorage.getItem(LocalStorageType.AccessToken)) {
    return true;
    // } else {
    //   return false;
    // }
  }

  private cert = '-----BEGIN PUBLIC KEY-----\r\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA0kBIpmxM4ddrMnOeh7Ij\r\nI7PFmAx/NZcdgTOyhG5ahl20vESarwI/gcB7++3AR6nYnTF5Qzmub7z9ACGnu7UO\r\nuWoDX6rtacBfM/0+uPG3egtFz8fSSVb6NDJksvHO8ciaK4JW5Aa3bDtcyZtQz3rV\r\nZul6rloKChFMtiyqm1WrcrQE1w/ZjEv4PEiQZMB2t2h4DDkRlK+tDS06q6QTHCju\r\njTaLaXAF/gYBDgnYBjXX0Ji4lxvz3zLWW0HzK8zM7OIkD28VHAQmCWDWzq0yNKR7\r\nAb8jw2HcZ3rGhx5u5D1jAR9qXSLKBjB37fYFpSasfgqW4vLDmbOkkQafX7jh87kk\r\ndQIDAQAB\r\n-----END PUBLIC KEY-----\r\n'

  SetSession(token) {
    console.log('set session tokne: ' + token)
    localStorage.setItem(LocalStorageType.Session, token)

    return this.GetSession()
  }

  async GetSession() {
    let token = localStorage.getItem(LocalStorageType.Session)

    let verifyResult = KJUR.jws.JWS.verify(token, this.cert, ["RS256"]);

    if (verifyResult) {
      let result = await KJUR.jws.JWS.readSafeJSONString(b64utoutf8(token.split('.')[1]));

      return result;
    } else {
      throw 'Invalid Session'
    }
  }

  setAnnouncement(announcement: string) {
    return localStorage.setItem(LocalStorageType.Announcement, announcement);
  }

  getAnnouncement() {
    return localStorage.getItem(LocalStorageType.Announcement);
  }

  clearAnnouncement() {
    return localStorage.removeItem(LocalStorageType.Announcement);
  }

}
