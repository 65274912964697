import { Component, NgZone, AfterViewInit, EventEmitter, Output, Input } from '@angular/core';
// Record token
import { AuthService } from 'app/shared/auth/auth.service';

// Pop Up Alert
import Swal from 'sweetalert2';
import { ResizeService } from 'app/shared/services/resize.service';

@Component({
    selector: 'app-number-pad',
    templateUrl: './number-pad.component.html',
    styleUrls: ['./number-pad.component.scss']
})

export class NumberPadComponent implements AfterViewInit {
    @Input() receiveAmount: number;
    @Input() enableCheckout: boolean;
    @Output() tenderAmountChange: EventEmitter<number>;
    @Output() checkout: EventEmitter<number>;
    currentAmount: number = 0;

    constructor(public resizeService: ResizeService) {

        this.tenderAmountChange = new EventEmitter<number>();
        this.checkout = new EventEmitter<number>();
    }

    ngAfterViewInit() {
    }

    keypressed(data) {
        // Nothing to do
        if (data == 0 && this.currentAmount == 0) {
            return;
        }

        if (this.currentAmount == 0) {
            this.currentAmount = data;
        } else {
            this.currentAmount = Number(this.currentAmount + '' + data);
        }

        this.tenderAmountChange.next(this.currentAmount / 100);
    }

    backspace() {
        if (this.currentAmount == 0) {
            return;
        }

        let currentAmountStr = this.currentAmount.toString();

        this.currentAmount = Number(currentAmountStr.substring(0, currentAmountStr.length - 1));

        this.tenderAmountChange.next(this.currentAmount / 100);
    }

    // On submit button click
    async onSubmit() {
        if (this.enableCheckout) {
            // this.tenderAmount.next(this.currentAmount);
            this.checkout.next(this.currentAmount / 100);
        }
    }

    clear() {
        this.currentAmount = 0;

        this.tenderAmountChange.next(this.currentAmount / 100);
    }

    quickAmount(data) {
        this.currentAmount = data;

        this.tenderAmountChange.next(this.currentAmount / 100);
    }
}
