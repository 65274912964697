import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LayoutService } from 'app/shared/services/layout.service';
import Swal from 'sweetalert2';
import { Member } from 'app/shared/model/member';
import { MemberService } from 'app/shared/services/member.service';
import { ValidationService } from 'app/shared/common/validation_service';
import { CommonFunction } from 'app/shared/common/common_function';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-forgot-password-page',
    templateUrl: './forgot-password-page.component.html',
    styleUrls: ['./forgot-password-page.component.scss']
})

export class ForgotPasswordPageComponent {
    forgotForm: FormGroup;
    resetPasswordForm: FormGroup;
    isSubmitting = false;
    isResetPassword = false;

    constructor(private router: Router,
        private formBuilder: FormBuilder,
        public activeModal: NgbActiveModal,
        private layoutService: LayoutService,
        private memberService: MemberService,
        private translate: TranslateService,
        private validationServ: ValidationService,
        private route: ActivatedRoute) {

        // Preset login
        this.forgotForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.maxLength(100), this.validationServ.emailValidator]]
        });

        // Preset login
        this.resetPasswordForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.maxLength(100), this.validationServ.emailValidator]],
            token: ['', [Validators.required, Validators.maxLength(10)]],
            password: ['', [Validators.required, Validators.maxLength(50)]],
            confirmPassword: ['', [Validators.required, Validators.maxLength(50)]],
        },
        {
            validator: [
                this.validationServ.match('password', 'confirmPassword'),
            ]
        });
    }

    // On submit click, reset form fields
    onSubmit() {
        if (this.forgotForm.invalid) {
            ////console.log('formGrp invalid');

            Object.keys(this.forgotForm.controls).forEach(field => { // {1}
                const control = this.forgotForm.get(field);            // {2}
                control.markAsTouched({ onlySelf: true });       // {3}
            });

            return;
        }

        this.isSubmitting = true;

        const memberInfo = new Member();
        memberInfo.Email = this.forgotForm.controls['email'].value;
        this.memberService.RequestReset(memberInfo).subscribe(async res => {
            ////console.log('RequestReset: ' + JSON.stringify(res))
            if (res) {
                Swal.fire({
                    icon: 'success',
                    title: this.translate.instant('Information'),
                    html: 'Reset code has send to your email.'
                });

                this.isSubmitting = false;
                this.isResetPassword = true;
                this.resetPasswordForm.controls['email'].setValue(this.forgotForm.controls['email'].value);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: this.translate.instant('Error'),
                    html: 'Failed to send out reset email.'
                });
            }
        },
            (err) => {
                ////console.log('Forgot Password Error:' + JSON.stringify(err));

                if (err && err.indexOf('Error Code: 404') >= 0) {
                    Swal.fire({
                        icon: 'error',
                        title: this.translate.instant('Error'),
                        html: 'Email not found.'
                    });
                } else
                    if (err && err.indexOf('Http failure response') >= 0) {
                        Swal.fire({
                            icon: 'error',
                            title: this.translate.instant('Error'),
                            html: 'Failed to connect server'
                        });
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            title: this.translate.instant('Error'),
                            html: 'Error: ' + err
                        });
                    }
                this.isSubmitting = false;
            });
    }

    // On submit click, reset form fields
    onResetPassword() {
        if (this.resetPasswordForm.invalid) {
            ////console.log('resetPasswordForm invalid');

            Object.keys(this.resetPasswordForm.controls).forEach(field => { // {1}
                const control = this.resetPasswordForm.get(field);            // {2}
                control.markAsTouched({ onlySelf: true });       // {3}
            });

            return;
        }

        // Checking on password confirmation
        if(this.resetPasswordForm.controls['password'].value != this.resetPasswordForm.controls['confirmPassword'].value) {
            return;
        }


        this.isSubmitting = true;

        const memberInfo = new Member();
        memberInfo.Email = this.resetPasswordForm.controls['email'].value;
        memberInfo.ResetToken = this.resetPasswordForm.controls['token'].value;
        memberInfo.Password = CommonFunction.hashPassword(this.resetPasswordForm.controls['password'].value);
        this.memberService.ResetPassword(memberInfo).subscribe(async res => {
            ////console.log('ResetPassword: ' + JSON.stringify(res))
            if (res) {
                Swal.fire({
                    icon: 'success',
                    title: this.translate.instant('Information'),
                    html: 'Your password has reset successfully.'
                });

                this.activeModal.close();
            } else {
                Swal.fire({
                    icon: 'error',
                    title: this.translate.instant('Error'),
                    html: 'Failed to reset password.'
                });
            }
        },
            (err) => {
                ////console.log('Forgot Password Error:' + JSON.stringify(err));

                if (err && err.indexOf('Error Code: 404') >= 0) {
                    Swal.fire({
                        icon: 'error',
                        title: this.translate.instant('Error'),
                        html: 'Invalid reset code.'
                    });
                } else if (err && err.indexOf('Error Code: 406') >= 0) {
                    Swal.fire({
                        icon: 'error',
                        title: this.translate.instant('Error'),
                        html: 'Failed to reset password.'
                    });
                } else if (err && err.indexOf('Http failure response') >= 0) {
                        Swal.fire({
                            icon: 'error',
                            title: this.translate.instant('Error'),
                            html: 'Failed to connect server'
                        });
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            title: this.translate.instant('Error'),
                            html: 'Error: ' + err
                        });
                    }
                this.isSubmitting = false;
            });
    }

    goToLogin() {
        this.openLoginModal();
    }

    openLoginModal() {
        this.activeModal.close();
        this.layoutService.emitHomeModalChange('Login')
    }

    goToForgotPassword() {
        this.openForgotPasswordModal();
    }

    openForgotPasswordModal() {
        this.activeModal.close();
        this.layoutService.emitHomeModalChange('Login')
    }

    onCloseModal() {
        this.activeModal.close();
    }
}
