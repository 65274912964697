<div class="container">
  <div class="row">
      <div class="col">
        <a (click)="keypressed(1)" class="btn btn-raised mr-1 btn-success btn-lg btn-block">1</a>
      </div>
      <div class="col">
        <a (click)="keypressed(2)" class="btn btn-raised mr-1 btn-success btn-lg btn-block">2</a>
      </div>
      <div class="col">
        <a (click)="keypressed(3)" class="btn btn-raised mr-1 btn-success btn-lg btn-block">3</a>
      </div>
      <div class="col">
        <a (click)="quickAmount(1000)" class="btn btn-raised mr-1 btn-success btn-lg btn-block">10.00</a>
      </div>
  </div>
  <div class="row">
      <div class="col">
        <a (click)="keypressed(4)" class="btn btn-raised mr-1 btn-success btn-lg btn-block">4</a>
      </div>
      <div class="col">
        <a (click)="keypressed(5)" class="btn btn-raised mr-1 btn-success btn-lg btn-block">5</a>
      </div>
      <div class="col">
        <a (click)="keypressed(6)" class="btn btn-raised mr-1 btn-success btn-lg btn-block">6</a>
      </div>
      <div class="col">
        <a (click)="quickAmount(5000)" class="btn btn-raised mr-1 btn-success btn-lg btn-block">50.00</a>
      </div>
  </div>
  <div class="row">
      <div class="col">
        <a (click)="keypressed(7)" class="btn btn-raised mr-1 btn-success btn-lg btn-block">7</a>
      </div>
      <div class="col">
        <a (click)="keypressed(8)" class="btn btn-raised mr-1 btn-success btn-lg btn-block">8</a>
      </div>
      <div class="col">
        <a (click)="keypressed(9)" class="btn btn-raised mr-1 btn-success btn-lg btn-block">9</a>
      </div>
      <div class="col">
        <a (click)="quickAmount(10000)" class="btn btn-raised mr-1 btn-success btn-lg btn-block">100.00</a>
      </div>
  </div>
  <div class="row">
      <div class="col">
        <a (click)="backspace()" class="btn btn-raised mr-1 btn-info btn-lg btn-block">⌫</a>
      </div>
      <div class="col">
        <a (click)="keypressed(0)" class="btn btn-raised mr-1 btn-success btn-lg btn-block">0</a>
      </div>
      <div class="col">
        <a (click)="onSubmit()" [class]="enableCheckout ? 'btn btn-raised mr-1 btn-info btn-lg btn-block' : 'btn btn-raised mr-1 btn-light btn-lg btn-block'">=</a>
      </div>
      <div class="col">
        <a (click)="clear()" class="btn btn-raised mr-1 btn-danger btn-lg btn-block">CLEAR</a>
      </div>
  </div>
</div>