import { CommonFunction } from '../common/common_function';
import { BaseModel, BaseModelHistory, BaseUser } from './base';
import { Member } from './member';

export class CompanyInfo extends BaseModelHistory {
   // CompanyID: string;
   CompanyName: string;
   NatureOfBusiness: string;
   EstablishDate: Date;
   ContactNo: string;
   PhoneNumber: string;
   UnitNo: string;
   Address: string;
   Address1: string;
   Address2: string;
   PostCode: string;
   City: string;
   State: string;
   Country: string;
   Website: string;
   GSTEnabled: number;
   Email: string;
   SMTPServer: string;
   SMTPEmail: string;
   SMTPEmailPass: string;
   License: string;
   ShowAds: number;
   AllowedMember: number;
   CompanyCode: string;
   SocsoNo: string;
   EPFNo: string;
   Password: string;
   Photo: string;
   PhotoBackgroundColor: string;
   CoverPhoto: string;
   FacebookID: string;
   WeChatID: string;
   // IsDelete: boolean;
   // DeletedBy: string;
   // CreatedBy: string;
   // CreatedDateTime: Date;
   // UpdatedBy: string;
   // UpdatedDateTime: Date;   // Server updated time
   // LastUpdatedDateTime: Date;  // Local updated time
   GPS: string;
   PaymentFeesChargeThreshold: number;
   Description: string;
   BankName: string;
   BankAccNo: string;
   VacationStartDateTime: Date;
   VacationEndDateTime: Date;
   OnVacation: boolean;
   IsCreatedByOwner: boolean;
   About: string;
   Departments:  DepartmentInfo[];
   Levels:  LevelInfo[];
   Positions:  PositionInfo[];

   public constructor(init?: Partial<CompanyInfo>) {
      super();
      Object.assign(this, init);
   }
}

export class DepartmentInfo extends BaseModelHistory {
   CompanyDepartmentID: string;
   // CompanyID: string;
   DepartmentCode: string;
   DepartmentName: string;
   // CreatedBy: string;
   // CreatedDateTime: Date;
   // UpdatedBy: string;
   // UpdatedDateTime: Date;
   // LastUpdatedDateTime: Date;
   Selected: boolean;
}

export class LevelInfo extends BaseModelHistory {
   CompanyEmployeeLevelID: string;
   // CompanyID: string;
   LevelCode: string;
   LevelName: string;
   LevelDescription: string;
   // CreatedBy: string;
   // CreatedDateTime: Date;
   // UpdatedBy: string;
   // UpdatedDateTime: Date;
   // LastUpdatedDateTime: Date;
   Selected: boolean;
}

export class PositionInfo extends BaseModelHistory {
   CompanyEmployeePositionID: string;
   // CompanyID: string;
   PositionName: string;
   PositionDescription: string;
   // CreatedBy: string;
   // CreatedDateTime: Date;
   // UpdatedBy: string;
   // UpdatedDateTime: Date;
   // LastUpdatedDateTime: Date;
   Selected: boolean;
}

export class Customer extends BaseUser {
   CustomerMemberInfo: Member = new Member();
   CustomerInfo: CompanyInfo = new CompanyInfo();
   CustomerID: string;
   // MemberRefID: string;
   // CompanyRefID: string;
   // CompanyID: string;
   CustomerLevelID: string;
   Email: string;
   // Active: boolean;
   // ReferrerMemberID: string;
   // CreatedBy: string;
   // CreatedDateTime: Date;
   // UpdatedDateTime: Date;
   // LastUpdatedDateTime: Date;

   public constructor(init?: Partial<Customer>) {
      super();
      
      Object.assign(this, init);
   }
}

export class Supplier extends BaseUser {
   SupplierInfo: CompanyInfo = new CompanyInfo();
   SupplierID: string;
   // MemberRefID: string;
   // CompanyRefID: string;
   // CompanyID: string;
   SupplierLevelID: string;
   // Active: boolean;
   // ReferrerMemberID: string;
   // CreatedBy: string;
   // CreatedDateTime: Date;
   // UpdatedDateTime: Date;
   // LastUpdatedDateTime: Date;

   public constructor(init?: Partial<Supplier>) {
      super();
      Object.assign(this, init);
   }
}

export class RefMember {
   ID: string;
   MemberKey: string;
   Photo: any;
   CreatedDateTime: Date;
   UpdatedDateTime: Date;
   LastUpdatedDateTime: Date;
}

export class CompanyEmployee extends BaseUser {
   MemberInfo: Member = new Member();
   CompanyEmployeeID: string;
   CompanyName: string;
   // CompanyID: string;
   // MemberRefID: string;
   MemberKey: string;
   CompanyEmployeeLevelID: string;
   CompanyDepartmentID: string;
   CompanyEmployeePositionID: string;
   LevelName: string;
   DepartmentName: string;
   PositionName: string;
   Activate: number;
   // ReferrerMemberID: string;
   GSTEnabled: number;
   EmployeeName: string;
   IdentityID: string;
   Email: string;
   PhoneNumber: string;
   IsAdmin: boolean;
   // CreatedBy: string;
   // CreatedDateTime: Date;
   // LastUpdatedDateTime: Date;

   // Internal record, for app display purpose
   Status: CompanyEmployeeStatus;

   public constructor(init?: Partial<CompanyEmployee>) {
      super();
      Object.assign(this, init);
   }
}

export enum CompanyEmployeeStatus {
   ConfirmedEmployee = 'Confirmed',
   PendingUserConfirmation = 'Pending User Confirmation',
   PendingCompanyConfirmation = 'Pending Company Confirmation'
}

// export class SupplierInfo {
//    SupplierInfo: CompanyInfo = new CompanyInfo();
//    SupplierID: string;
//    Name: string;
//    ContactNo: string;
//    Address: string;
//    Website: string;
//    CompanyID: string;
//    CreatedBy: string;
//    CreatedDateTime: Date;
//    UpdatedDateTime: Date;
//    LastUpdatedDateTime: Date;

//    public constructor(init?: Partial<SupplierInfo>) {
//        Object.assign(this, init);
//    }
// }

// export class CustomerInfo {
//    CustomerID: string;
//    Name: string;
//    ContactNo: string;
//    Address: string;
//    Website: string;
//    CreatedBy: string = 'Admin';
//    CreatedDateTime: Date = new Date();
//    UpdatedDateTime: Date;
//    LastUpdatedDateTime: Date;

//    public constructor(init?: Partial<CustomerInfo>) {
//        Object.assign(this, init);
//    }
// }

export class AccessControlInfo {
   Module: string;
   CreateAccess: boolean;
   ViewAccess: boolean;
   EditAccess: boolean;
   DeleteAccess: boolean;
   ViewAccessDisabled: boolean = false;
   EditAccessDisabled: boolean = false;
   HasCreateAccess: boolean = true;
   HasViewAccess: boolean = true;
   HasEditAccess: boolean = true;
   HasDeleteAccess: boolean = true;

   public constructor(init?: Partial<AccessControlInfo>) {
       Object.assign(this, init);
   }
}

export class CompanyModuleInfo extends BaseModelHistory {
   CompanyModuleID: string;
   // CompanyID: string;
   ModuleID: string;
   LicenseCode: string;
   ActivatedDate: Date;
   ExpiredDate: Date;
   // UpdatedBy: string;
   // CreatedBy: string;
   // CreatedDateTime: Date;
   // UpdatedDateTime: Date;

   public constructor(init?: Partial<CompanyModuleInfo>) {
      super();
       Object.assign(this, init);
   }
}

export class CompanyEmployeeModuleInfo extends BaseModel {
   CompanyEmployeeModuleID: string;
   // CompanyID: string;
   EmployeeID: string;
   ModuleID: string;
   Access: string;
   // UpdatedBy: string;
   // CreatedBy: string;
   // CreatedDateTime: Date;
   // UpdatedDateTime: Date;

   public constructor(init?: Partial<CompanyEmployeeModuleInfo>) {
      super();
       Object.assign(this, init);
   }
}

export class AuditLog {
   AuditLogID: string;
   MemberID: string;
   CompanyID: string;
   MemberName: string;
   EventDateTime: Date;
   EventType: number;
   EventTypeDesc: string;
   TableName: string;
   RecordID: string;
   OriginalValue: string;
   NewValue: string;

   public constructor(init?: Partial<AuditLog>) {
       Object.assign(this, init);
   }
}

export class CompanyEmployeeLevel extends BaseModelHistory {
   CompanyEmployeeLevelID: string;
   LevelCode: string;
   LevelName: string;
   LevelDescription: string;

   public constructor(init?: Partial<CompanyEmployeeLevel>) {
      super();
      Object.assign(this, init);
   }
}

export class CompanyDepartment extends BaseModelHistory {
   CompanyDepartmentID: string;
   DepartmentCode: string;
   DepartmentName: string;

   public constructor(init?: Partial<CompanyDepartment>) {
      super();
      Object.assign(this, init);
   }
}

export class CompanyEmployeePosition extends BaseModelHistory {
   CompanyEmployeePositionID: string;
   PositionName: string;
   PositionDescription: string;

   public constructor(init?: Partial<CompanyEmployeePosition>) {
      super();
      Object.assign(this, init);
   }
}

export class CompanyEvent extends BaseModelHistory {
   CustomerMemberInfo: Member = new Member();
   CustomerInfo: CompanyInfo = new CompanyInfo();
   CompanyEventID: string;
   EventName: string;
   EventStartDate: Date;
   EventEndDate: Date;
   UnitNo: string;
   Address: string;
   GPSLatLong: string;
   ApplicableMemberLevelID: string;
   EmailNotify: boolean;
   SMSNotify: boolean;
   WhatsappNotify: boolean;

   public constructor(init?: Partial<CompanyEvent>) {
      super();
      
      Object.assign(this, init);
   }
}

export class CompanyMember extends BaseUser {
   MemberInfo: Member = new Member();
   CompanyMemberID: string;
   CompanyMemberLevelID: string;
   CreditPoints: number;
   DebitPoints: number;
   BalancePoints: number;

   public constructor(init?: Partial<CompanyMember>) {
      super();
      
      Object.assign(this, init);
   }
}

export class CompanyMemberPoint extends BaseUser {
   MemberInfo: Member = new Member();
   CompanyMemberPointID: string;
   TransactionID: string;
   Purpose: string;
   IsCredit: boolean;
   Remark: string;
   Point: number;

   public constructor(init?: Partial<CompanyMemberPoint>) {
      super();
      
      Object.assign(this, init);
   }
}

export enum RedemptionFormat {
   FixedPoint = 0,
   PointToUnitPrice = 1,
}

export class CompanyMemberPointRedemption extends BaseUser {
   MemberInfo: Member = new Member();
   CompanyMemberPointRedemptionID: string;
   ProductID: string;
   RedemptionFormat: RedemptionFormat;
   IsCredit: boolean;
   Remark: string;
   Points: number;

   public constructor(init?: Partial<CompanyMemberPointRedemption>) {
      super();
      
      Object.assign(this, init);
   }
}

export class GetCompanyProject extends BaseModelHistory {
   ProjectInfo: CompanyProject = new CompanyProject({
      ProjectID: CommonFunction.GenGUID()
   });
   Images: CompanyProjectImage[] = [];

   // Local usage
   MemberRegistered: boolean = false;

   public constructor(init?: Partial<GetCompanyProject>) {
      super();
      Object.assign(this, init);
   }
}

export class CompanyProject extends BaseModelHistory {
   ProjectID: string;
   Name: string;
   LaunchDate: Date;
   CompletionDate: Date;
   GPS: string;
   // Local Usage
   latitude: number;
   longitude: number;
   zoom: number;

   public constructor(init?: Partial<CompanyProject>) {
      super();
      Object.assign(this, init);
   }
}

export class CompanyProjectImage extends BaseModel {
   ProjectImageID: string;
   ProjectID: string;
   AttachmentID: string;
   AttachmentPath: string;
   Title: string;
   Description: string;
   ImageData: any;
   Sequence: number;

   public constructor(init?: Partial<CompanyProjectImage>) {
      super();
      Object.assign(this, init);
   }
}

export class CompanyNews extends BaseModelHistory {
   CustomerMemberInfo: Member = new Member();
   CustomerInfo: CompanyInfo = new CompanyInfo();
   CompanyNewsID: string;
   NewsTitle: string;
   NewsDate: Date;
   NewsUrl: string;

   public constructor(init?: Partial<CompanyNews>) {
      super();
      
      Object.assign(this, init);
   }
}

export class ProjectRegistration extends BaseModelHistory {
   ProjectRegistrationID: string;
   ProjectID: string;
   MemberRefID: string;
   MemberInfo: Member = new Member();
   ProjectInfo: CompanyProject = new CompanyProject({
      ProjectID: CommonFunction.GenGUID()
   });

   public constructor(init?: Partial<ProjectRegistration>) {
      super();
      Object.assign(this, init);
   }
}