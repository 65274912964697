import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, Subject } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';
import { AuthService } from 'app/shared/auth/auth.service';
import { CompanyInfo, DepartmentInfo, LevelInfo, PositionInfo, Customer, RefMember, CompanyEmployee, Supplier, CompanyEmployeeModuleInfo, CompanyModuleInfo, AuditLog, CompanyMember, CompanyEvent, GetCompanyProject, CompanyMemberPoint, CompanyMemberPointRedemption, CompanyNews, ProjectRegistration } from '../model/company';
import { CommonFunction } from '../common/common_function';
import { LocalStorageType } from '../model/system';
// import { openDB, deleteDB, wrap, unwrap } from 'idb';

@Injectable({
  providedIn: 'root'
})

export class CompanyService {

  private emitChangeSource = new Subject<any>();
  changeEmitted$ = this.emitChangeSource.asObservable();
  emitChange(change: any) {
    this.emitChangeSource.next(change);
  }

  //Company
  private emitCompanySource = new Subject<any>();
  companyChangeEmitted$ = this.emitCompanySource.asObservable();
  emitCompanyChange(change: any) {
    this.emitCompanySource.next(change);
  }

  // //customizer - compact menu
  // private emitCompanyCMSource = new Subject<any>();
  // companyCMChangeEmitted$ = this.emitCompanyCMSource.asObservable();
  // emitCompanyCMChange(change: any) {
  //   this.emitCompanyCMSource.next(change);
  // }

  // //customizer - compact menu
  // private emitNotiSidebarSource = new Subject<any>();
  // notiSidebarChangeEmitted$ = this.emitNotiSidebarSource.asObservable();
  // emitNotiSidebarChange(change: any) {
  //   this.emitNotiSidebarSource.next(change);
  // }

  // Base url
  baseurl = CommonFunction.ServerURL + '/api/company';

  // headers = new HttpHeaders();
  constructor(private http: HttpClient,
    private authService: AuthService) {
    // this.headers = this.headers.append('Content-Type', 'application/json');
    // this.headers = this.headers.append('Authorization', 'Token ' + this.authService.getToken());
    // this.headers = this.headers.append('Company', this.authService.getCompany());
  }


  // var headerDict = {
  //   'Content-Type': 'application/json',
  //   'Authorization': 'Token ' + this.authService.getToken(),
  //   'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
  // };

  // var requestOptions = {                                                                                                                                                                                 
  //   headers: new Headers(headerDict), 
  // };


  // // POST
  // Login(data): Observable<CompanyInfo> {
  //   return this.http.post<CompanyInfo>(this.baseurl + '/GetCompany', JSON.stringify(data), this.httpOptions)
  //   .pipe(
  //     //retry(1)
  //     catchError(this.errorHandl)
  //   )
  // }  

  // GET
  GetCompany(): Observable<CompanyInfo[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
      })
    }

    // ////console.log('headers: ' + JSON.stringify(httpOptions));

    return this.http.get<CompanyInfo[]>(this.baseurl + '/GetCompany', httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // GET
  GetEmployee(): Observable<CompanyEmployee[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
      })
    }

    // ////console.log('headers: ' + JSON.stringify(httpOptions));

    return this.http.get<CompanyEmployee[]>(this.baseurl + '/GetAllEmployee?companyID=' + this.authService.getCompany(), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // GET
  GetInviteEmployee(): Observable<CompanyEmployee[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
      })
    }

    // ////console.log('headers: ' + JSON.stringify(httpOptions));

    return this.http.get<CompanyEmployee[]>(this.baseurl + '/GetAllInviteEmployee?companyID=' + this.authService.getCompany(), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // GET
  GetAllDepartment(): Observable<DepartmentInfo[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '' ? this.authService.getCompany() : '',
      })
    }

    // ////console.log('headers: ' + JSON.stringify(httpOptions));

    return this.http.get<DepartmentInfo[]>(this.baseurl + '/GetAllDepartment?companyID=' + this.authService.getCompany(), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // GET
  GetAllDepartmentTemplate(): Observable<DepartmentInfo[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': '',
      })
    }

    // ////console.log('headers: ' + JSON.stringify(httpOptions));

    return this.http.get<DepartmentInfo[]>(this.baseurl + '/GetAllDepartmentTemplate', httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // GET
  GetAllPosition(): Observable<PositionInfo[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
      })
    }

    return this.http.get<PositionInfo[]>(this.baseurl + '/GetAllPosition?companyID=' + this.authService.getCompany(), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // GET
  GetAllPositionTemplate(): Observable<PositionInfo[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': '',
      })
    }

    // ////console.log('headers: ' + JSON.stringify(httpOptions));

    return this.http.get<PositionInfo[]>(this.baseurl + '/GetAllPositionTemplate', httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // GET
  GetAllLevel(): Observable<LevelInfo[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
      })
    }

    // ////console.log('headers: ' + JSON.stringify(httpOptions));

    return this.http.get<LevelInfo[]>(this.baseurl + '/GetAllLevel?companyID=' + this.authService.getCompany(), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // GET
  GetAllLevelTemplate(): Observable<LevelInfo[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': '',
      })
    }

    // ////console.log('headers: ' + JSON.stringify(httpOptions));

    return this.http.get<LevelInfo[]>(this.baseurl + '/GetAllLevelTemplate', httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // GET
  GetAllCustomer(): Observable<Customer[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
      })
    }

    // ////console.log('headers: ' + JSON.stringify(httpOptions));

    return this.http.get<Customer[]>(this.baseurl + '/GetAllCustomer?companyID=' + this.authService.getCompany(), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // POST
  AddCustomer(data): Observable<Customer[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
      })
    }

    return this.http.post<Customer[]>(this.baseurl + '/AddCustomer', JSON.stringify(data), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // PUT
  UpdateCustomer(data): Observable<Customer[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
      })
    }

    return this.http.put<Customer[]>(this.baseurl + '/UpdateCustomer', JSON.stringify(data), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // GET
  GetRefMember(): Observable<RefMember[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
      })
    }

    // ////console.log('headers: ' + JSON.stringify(httpOptions));

    return this.http.get<RefMember[]>(this.baseurl + '/GetRefMembers', httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  //   // GET
  //   GetIssues(): Observable<Bug> {
  //     return this.http.get<Bug>(this.baseurl + '/bugtracking/')
  //     .pipe(
  //       //retry(1)
  //       catchError(this.errorHandl)
  //     )
  //   }

  //   // PUT
  //   UpdateBug(id, data): Observable<Bug> {
  //     return this.http.put<Bug>(this.baseurl + '/bugtracking/' + id, JSON.stringify(data), this.httpOptions)
  //     .pipe(
  //       //retry(1)
  //       catchError(this.errorHandl)
  //     )
  //   }

  //   // DELETE
  //   DeleteBug(id){
  //     return this.http.delete<Bug>(this.baseurl + '/bugtracking/' + id, this.httpOptions)
  //     .pipe(
  //       //retry(1)
  //       catchError(this.errorHandl)
  //     )
  //   }

  // POST
  AddDepartment(data): Observable<DepartmentInfo[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
      })
    }

    return this.http.post<DepartmentInfo[]>(this.baseurl + '/AddDepartment', JSON.stringify(data), httpOptions)
      .pipe(
        //retry(1)
        catchError(error => {
          // this.saveDepartmentInOffline(data, 'Add')

          return this.errorHandl(error);
        })
      )
  }

  // POST
  UpdateDepartment(data): Observable<DepartmentInfo[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
      })
    }

    return this.http.put<DepartmentInfo[]>(this.baseurl + '/UpdateDepartment', JSON.stringify(data), httpOptions)
      .pipe(
        //retry(1)
        catchError(error => {
          // this.saveDepartmentInOffline(data, 'Update')

          return this.errorHandl(error);
        })
      )
  }

  // async {{'Save' | translate}}Department(department, method) {
  //   const db = await this.openDepartmentDB();
  //   const tx = db.transaction('departmentsToSync', 'readwrite');
  //   tx.store.put({ department, method });
  //   await tx.done;
  // }

  // async {{'Save' | translate}}DepartmentInOffline(data, method) {
  //   ////console.log('department 2: ' + data)

  //   const db = await this.openDepartmentDB();
  //   const tx = db.transaction('departmentsToSync', 'readwrite');
  //   tx.store.put({ data, method });
  //   await tx.done;
  // }

  // //https://www.monterail.com/blog/pwa-offline-dynamic-data
  // openDepartmentDB() {
  //   return openDB('company', 1, {
  //     upgrade(db) {
  //       db.createObjectStore('departmentsToSync', { keyPath: 'id', autoIncrement: true });
  //     }
  //   });
  // }

  // async syncDepartments() {
  //   ////console.log('syncDepartments');

  //   const db = await openDB('company', 1);
  //   const departments = await db.getAll('departmentsToSync');
  //   const departmentIdsToRemove = [];

  //   // Http Headers
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Authorization': 'Token ' + this.authService.getToken(),
  //       'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
  //     })
  //   }

  //   await Promise.all(departments.map(async ({ id, method, data }) => {
  //     try {
  //       if (method == 'Update') {

  //         var response = await this.http.post<DepartmentInfo[]>(this.baseurl + '/UpdateDepartment', JSON.stringify(data), httpOptions)
  //           .pipe(
  //             //retry(1)
  //             catchError(this.errorHandl)
  //           ).toPromise()

  //         if (response && response.length > 0) {
  //           departmentIdsToRemove.push(id)
  //         }

  //         ////console.log('sync call success Update Completed');

  //       }
  //       else if (method == 'Add') {

  //         var response = await this.http.post<DepartmentInfo[]>(this.baseurl + '/AddDepartment', JSON.stringify(data), httpOptions)
  //           .pipe(
  //             //retry(1)
  //             catchError(this.errorHandl)
  //           ).toPromise()

  //         if (response && response.length > 0) {
  //           departmentIdsToRemove.push(id)
  //         }

  //       }
  //     } catch (error) {
  //       ////console.log('Sync Error: ' + error);
  //     }
  //   }));

  //   const tx = db.transaction('departmentsToSync', 'readwrite');

  //   for (const id of departmentIdsToRemove) {
  //     tx.store.delete(id);
  //   }

  //   await tx.done;
  // }

  // GET
  GetAllEmployee(): Observable<CompanyEmployee[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
      })
    }

    // ////console.log('headers: ' + JSON.stringify(httpOptions));

    return this.http.get<CompanyEmployee[]>(this.baseurl + '/GetAllEmployee?companyID=' + this.authService.getCompany(), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // POST
  AddEmployee(data): Observable<CompanyEmployee> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
      })
    }

    return this.http.post<CompanyEmployee>(this.baseurl + '/AddEmployee', JSON.stringify(data), httpOptions)
      .pipe(
        //retry(1)
        catchError(error => {
          // this.saveEmployeeInOffline(data, 'Add')

          return this.errorHandl(error);
        })
      )
  }

  // POST
  UpdateEmployee(data): Observable<CompanyEmployee> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
      })
    }

    return this.http.put<CompanyEmployee>(this.baseurl + '/UpdateEmployee', JSON.stringify(data), httpOptions)
      .pipe(
        //retry(1)
        catchError(error => {
          // this.saveEmployeeInOffline(data, 'Update')

          return this.errorHandl(error);
        })
      )
  }

  // POST
  DeleteEmployee(data): Observable<CompanyEmployee> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
      })
    }

    return this.http.post<CompanyEmployee>(this.baseurl + '/DeleteEmployee', JSON.stringify(data), httpOptions)
      .pipe(
        //retry(1)
        catchError(error => {
          // this.saveEmployeeInOffline(data, 'Delete')

          return this.errorHandl(error);
        })
      )
  }

  // async {{'Save' | translate}}Employee(employee, method) {
  //   const db = await this.openEmployeeDB();
  //   const tx = db.transaction('employeesToSync', 'readwrite');
  //   tx.store.put({ employee, method });
  //   await tx.done;
  // }

  // async {{'Save' | translate}}EmployeeInOffline(data, method) {
  //   const db = await this.openEmployeeDB();
  //   const tx = db.transaction('employeesToSync', 'readwrite');
  //   tx.store.put({ data, method });
  //   await tx.done;
  // }

  // //https://www.monterail.com/blog/pwa-offline-dynamic-data
  // openEmployeeDB() {
  //   return openDB('company', 1, {
  //     upgrade(db) {
  //       db.createObjectStore('employeesToSync', { keyPath: 'id', autoIncrement: true });
  //     }
  //   });
  // }

  // async syncEmployees() {
  //   const db = await openDB('company', 1);
  //   const employees = await db.getAll('employeesToSync');
  //   const employeeIdsToRemove = [];

  //   // Http Headers
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Authorization': 'Token ' + this.authService.getToken(),
  //       'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
  //     })
  //   }

  //   await Promise.all(employees.map(async ({ id, method, data }) => {
  //     try {
  //       if (method == 'Update') {

  //         var response = await this.http.post<CompanyEmployee[]>(this.baseurl + '/UpdateEmployee', JSON.stringify(data), httpOptions)
  //           .pipe(
  //             //retry(1)
  //             catchError(this.errorHandl)
  //           ).toPromise()

  //         if (response && response.length > 0) {
  //           employeeIdsToRemove.push(id)
  //         }

  //       }
  //       else if (method == 'Add') {

  //         var response = await this.http.post<CompanyEmployee[]>(this.baseurl + '/AddEmployee', JSON.stringify(data), httpOptions)
  //           .pipe(
  //             //retry(1)
  //             catchError(this.errorHandl)
  //           ).toPromise()

  //         if (response && response.length > 0) {
  //           employeeIdsToRemove.push(id)
  //         }
  //       }
  //       else if (method == 'Invite') {

  //         var response = await this.http.post<CompanyEmployee[]>(this.baseurl + '/InviteEmployee', JSON.stringify(data), httpOptions)
  //           .pipe(
  //             //retry(1)
  //             catchError(this.errorHandl)
  //           ).toPromise()

  //         if (response && response.length > 0) {
  //           employeeIdsToRemove.push(id)
  //         }
  //       }
  //     } catch (error) {
  //       ////console.log('Sync Error: ' + error);
  //     }
  //   }));

  //   const tx = db.transaction('employeesToSync', 'readwrite');

  //   for (const id of employeeIdsToRemove) {
  //     tx.store.delete(id);
  //   }

  //   await tx.done;
  // }

  // POST
  AddCompany(data): Observable<CompanyInfo> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
      })
    }

    return this.http.post<CompanyInfo>(this.baseurl + '/AddCompany', JSON.stringify(data), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // POST
  UpdateCompany(data): Observable<CompanyInfo> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
      })
    }

    return this.http.put<CompanyInfo>(this.baseurl + '/UpdateCompany', JSON.stringify(data), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // POST
  DeleteCompany(data): Observable<CompanyInfo> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
      })
    }

    return this.http.post<CompanyInfo>(this.baseurl + '/DeleteCompany', JSON.stringify(data), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // POST
  AddLevel(data): Observable<LevelInfo[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
      })
    }

    return this.http.post<LevelInfo[]>(this.baseurl + '/AddLevel', JSON.stringify(data), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // POST
  AddPosition(data): Observable<PositionInfo[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
      })
    }

    return this.http.post<PositionInfo[]>(this.baseurl + '/AddPosition', JSON.stringify(data), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // POST
  UpdateLevel(data): Observable<LevelInfo[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
      })
    }

    return this.http.put<LevelInfo[]>(this.baseurl + '/UpdateLevel', JSON.stringify(data), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // POST
  UpdatePosition(data): Observable<PositionInfo[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
      })
    }

    return this.http.put<PositionInfo[]>(this.baseurl + '/UpdatePosition', JSON.stringify(data), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // POST
  DeleteLevel(data): Observable<LevelInfo[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
      })
    }

    return this.http.post<LevelInfo[]>(this.baseurl + '/DeleteLevel', JSON.stringify(data), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // POST
  DeletePosition(data): Observable<PositionInfo[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
      })
    }

    return this.http.post<PositionInfo[]>(this.baseurl + '/DeletePosition', JSON.stringify(data), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // POST
  DeleteDepartment(data): Observable<DepartmentInfo[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
      })
    }

    return this.http.post<DepartmentInfo[]>(this.baseurl + '/DeleteDepartment', JSON.stringify(data), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // POST
  IsCompanyCodeValid(data): Observable<CompanyInfo> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
      })
    }

    return this.http.post<CompanyInfo>(this.baseurl + '/IsCompanyCodeValid', JSON.stringify(data), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // POST
  InviteEmployee(data): Observable<CompanyEmployee> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
      })
    }

    return this.http.post<CompanyEmployee>(this.baseurl + '/InviteEmployee', JSON.stringify(data), httpOptions)
      .pipe(
        //retry(1)
        catchError(error => {
          // this.saveEmployeeInOffline(data, 'Invite')

          return this.errorHandl(error);
        })
      )
  }

  // POST
  GetCompanySeller(data): Observable<CompanyInfo[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }

    // this.decrypt(this.encrypt(JSON.stringify(data)))

    return this.http.post<CompanyInfo[]>(this.baseurl + '/GetCompanySeller?data=' + CommonFunction.encryptCompany(JSON.stringify(data)), null, httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // GET
  GeteCommCompany(companyCode): Observable<CompanyInfo> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }

    return this.http.get<CompanyInfo>(this.baseurl + '/GeteCommCompany?data=' + CommonFunction.encryptCompany(JSON.stringify(companyCode)), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // GET
  GetAllSupplier(): Observable<Supplier[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
      })
    }

    // ////console.log('headers: ' + JSON.stringify(httpOptions));

    return this.http.get<Supplier[]>(this.baseurl + '/GetAllSupplier?companyID=' + this.authService.getCompany(), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // POST
  AddSupplier(data): Observable<Supplier[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
      })
    }

    return this.http.post<Supplier[]>(this.baseurl + '/AddSupplier', JSON.stringify(data), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // PUT
  UpdateSupplier(data): Observable<Supplier[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
      })
    }

    return this.http.put<Supplier[]>(this.baseurl + '/UpdateSupplier', JSON.stringify(data), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // Error handling
  errorHandl(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      ////console.log('Company service error: ' + errorMessage);

      // Show connection error
      if (error.status == 0) {
        errorMessage = 'Failed to connect to server';
      }
      // Do not show full detail of error, either show Internal Error from API or Error Code only
      else if (error.error) {
        errorMessage = error.error;
      } else {
        errorMessage = `Error Code: ${error.status}`;
      }
    }
    return throwError(errorMessage);
  }

  // GET
  GetAllCompanyEmployeeModule(): Observable<CompanyEmployeeModuleInfo[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
      })
    }

    // ////console.log('headers: ' + JSON.stringify(httpOptions));

    return this.http.get<CompanyEmployeeModuleInfo[]>(this.baseurl + '/GetAllCompanyEmployeeModule?companyID=' + this.authService.getCompany(), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // GET
  GetAllCompanyModule(): Observable<CompanyModuleInfo[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
      })
    }

    // ////console.log('headers: ' + JSON.stringify(httpOptions));

    return this.http.get<CompanyModuleInfo[]>(this.baseurl + '/GetAllCompanyModule?companyID=' + this.authService.getCompany(), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // POST
  AddCompanyEmployeeModule(data): Observable<CompanyEmployeeModuleInfo[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
      })
    }

    return this.http.post<CompanyEmployeeModuleInfo[]>(this.baseurl + '/AddCompanyEmployeeModule', JSON.stringify(data), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // POST
  DeleteSupplier(data): Observable<Supplier[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
      })
    }

    return this.http.post<Supplier[]>(this.baseurl + '/DeleteSupplier', JSON.stringify(data), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // POST
  DeleteCustomer(data): Observable<Customer[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
      })
    }

    return this.http.post<Customer[]>(this.baseurl + '/DeleteCustomer', JSON.stringify(data), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // GET
  GetAuditLog(): Observable<AuditLog[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
      })
    }

    return this.http.get<AuditLog[]>(this.baseurl + '/GetAuditLog?companyID=' + this.authService.getCompany(), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // GET
  GetAllMember(): Observable<CompanyMember[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
      })
    }

    // ////console.log('headers: ' + JSON.stringify(httpOptions));

    return this.http.get<CompanyMember[]>(this.baseurl + '/GetAllMember?companyID=' + this.authService.getCompany(), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // POST
  AddMember(data): Observable<CompanyMember> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
      })
    }

    return this.http.post<CompanyMember>(this.baseurl + '/AddMember', JSON.stringify(data), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // PUT
  UpdateMember(data): Observable<Customer[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
      })
    }

    return this.http.put<Customer[]>(this.baseurl + '/UpdateMember', JSON.stringify(data), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // GET
  GetAllCompanyEvent(): Observable<CompanyEvent[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
      })
    }

    // ////console.log('headers: ' + JSON.stringify(httpOptions));

    return this.http.get<CompanyEvent[]>(this.baseurl + '/GetAllCompanyEvent?companyID=' + this.authService.getCompany(), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // POST
  AddCompanyEvent(data): Observable<CompanyEvent[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
      })
    }

    return this.http.post<CompanyEvent[]>(this.baseurl + '/AddCompanyEvent', JSON.stringify(data), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // PUT
  UpdateCompanyEvent(data): Observable<CompanyEvent[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
      })
    }

    return this.http.put<CompanyEvent[]>(this.baseurl + '/UpdateCompanyEvent', JSON.stringify(data), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // GET
  GetAllProject(): Observable<GetCompanyProject[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
      })
    }

    // ////console.log('headers: ' + JSON.stringify(httpOptions));

    return this.http.get<GetCompanyProject[]>(this.baseurl + '/GetAllProject?companyID=' + this.authService.getCompany(), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // POST
  AddProject(data): Observable<GetCompanyProject[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
      })
    }

    return this.http.post<GetCompanyProject[]>(this.baseurl + '/AddProject', JSON.stringify(data), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // PUT
  UpdateProject(data): Observable<GetCompanyProject[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
      })
    }

    return this.http.put<GetCompanyProject[]>(this.baseurl + '/UpdateProject', JSON.stringify(data), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // POST
  DeleteProject(data): Observable<GetCompanyProject[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
      })
    }

    return this.http.post<GetCompanyProject[]>(this.baseurl + '/DeleteProject', JSON.stringify(data), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // GET
  GetAllProjectByCode(companyCode): Observable<GetCompanyProject[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
      })
    }

    return this.http.get<GetCompanyProject[]>(this.baseurl + '/GetAllProjectByCode?companyCode=' + companyCode, httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // GET
  GetCompanyByCode(companyCode): Observable<CompanyInfo> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
      })
    }

    // ////console.log('headers: ' + JSON.stringify(httpOptions));

    return this.http.get<CompanyInfo>(this.baseurl + '/GetCompanyByCode?companyCode=' + companyCode, httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // GET
  GetAllMemberPoint(): Observable<CompanyMemberPoint[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
      })
    }

    // ////console.log('headers: ' + JSON.stringify(httpOptions));

    return this.http.get<CompanyMemberPoint[]>(this.baseurl + '/GetAllMemberPoint?companyID=' + this.authService.getCompany(), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // GET
  GetAllMemberPointByMemberID(memberID): Observable<CompanyMemberPoint[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
      })
    }

    // ////console.log('headers: ' + JSON.stringify(httpOptions));

    return this.http.get<CompanyMemberPoint[]>(this.baseurl + '/GetAllMemberPointByMemberID?companyID=' + this.authService.getCompany() + '&memberID=' + memberID, httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // POST
  AddMemberPoint(data): Observable<CompanyMemberPoint[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
      })
    }

    return this.http.post<CompanyMemberPoint[]>(this.baseurl + '/AddMemberPoint', JSON.stringify(data), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // PUT
  UpdateMemberPoint(data): Observable<CompanyMemberPoint[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
      })
    }

    return this.http.put<CompanyMemberPoint[]>(this.baseurl + '/UpdateMemberPoint', JSON.stringify(data), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // POST
  DeleteMemberPoint(data): Observable<CompanyMemberPoint[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
      })
    }

    return this.http.post<CompanyMemberPoint[]>(this.baseurl + '/DeleteMemberPoint', JSON.stringify(data), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // POST
  AddMemberPointRedemption(data): Observable<CompanyMemberPointRedemption> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
      })
    }

    return this.http.post<CompanyMemberPointRedemption>(this.baseurl + '/AddMemberPointRedemption', JSON.stringify(data), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // PUT
  UpdateMemberPointRedemption(data): Observable<CompanyMemberPointRedemption> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
      })
    }

    return this.http.put<CompanyMemberPointRedemption>(this.baseurl + '/UpdateMemberPointRedemption', JSON.stringify(data), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // POST
  DeleteMemberPointRedemption(data): Observable<CompanyMemberPointRedemption> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
      })
    }

    return this.http.post<CompanyMemberPointRedemption>(this.baseurl + '/DeleteMemberPointRedemption', JSON.stringify(data), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // GET
  GetAllCompanyNews(): Observable<CompanyNews[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
      })
    }

    // ////console.log('headers: ' + JSON.stringify(httpOptions));

    return this.http.get<CompanyNews[]>(this.baseurl + '/GetAllCompanyNews?companyID=' + this.authService.getCompany(), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // POST
  AddCompanyNews(data): Observable<CompanyNews[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
      })
    }

    return this.http.post<CompanyNews[]>(this.baseurl + '/AddCompanyNews', JSON.stringify(data), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // PUT
  UpdateCompanyNews(data): Observable<CompanyNews[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
      })
    }

    return this.http.put<CompanyNews[]>(this.baseurl + '/UpdateCompanyNews', JSON.stringify(data), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // GET
  GetAllNewsByCode(companyCode): Observable<CompanyNews[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
      })
    }

    return this.http.get<CompanyNews[]>(this.baseurl + '/GetAllNewsByCode?companyCode=' + companyCode, httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // GET
  GetMemberByMemberID(companyId, memberId): Observable<CompanyMember> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
      })
    }

    return this.http.get<CompanyMember>(this.baseurl + '/GetMemberByMemberID?companyId=' + companyId + '&memberId=' + memberId, httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // GET
  GetProjectRegistrationByMemberID(companyID, memberID): Observable<ProjectRegistration[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
      })
    }

    // ////console.log('headers: ' + JSON.stringify(httpOptions));

    return this.http.get<ProjectRegistration[]>(this.baseurl + '/GetProjectRegistrationByMemberID?companyID=' + companyID + '&memberID=' + memberID, httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // GET
  GetAllProjectRegistration(): Observable<ProjectRegistration[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
      })
    }

    // ////console.log('headers: ' + JSON.stringify(httpOptions));

    return this.http.get<ProjectRegistration[]>(this.baseurl + '/GetAllProjectRegistration?companyID=' + this.authService.getCompany(), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // POST
  AddProjectRegistration(data): Observable<ProjectRegistration[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
      })
    }

    return this.http.post<ProjectRegistration[]>(this.baseurl + '/AddProjectRegistration', JSON.stringify(data), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // POST
  AddDummyCustomerSupplier(): Observable<string> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
      })
    }

    return this.http.post<string>(this.baseurl + '/AddDummyCustomerSupplier?companyID=' + this.authService.getCompany(), null, httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // GET
  GetAllSupplierCount(): Observable<number> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
      })
    }

    // ////console.log('headers: ' + JSON.stringify(httpOptions));

    return this.http.get<number>(this.baseurl + '/GetAllSupplierCount?companyID=' + this.authService.getCompany(), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // GET
  GetAllCustomerCount(): Observable<number> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany() ? this.authService.getCompany() : '',
      })
    }

    // ////console.log('headers: ' + JSON.stringify(httpOptions));

    return this.http.get<number>(this.baseurl + '/GetAllCustomerCount?companyID=' + this.authService.getCompany(), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

}