<ng-container *ngIf="!resizeService.isMobileResolution">
    <a href="javascript:;" (click)="setDataLanguage('my')"
        [class]="languageType == 'my' ? 'btn-lang btn btn-raised btn-sm btn-primary ml-1 float-right' : 'btn-lang btn btn-sm btn-outline-primary ml-1 float-right'">
        <img src="./assets/img/flags/my.png" alt="Malay" class="langimg">
        <span class="d-none">BM</span>
    </a>
    <a href="javascript:;" (click)="setDataLanguage('cn')"
        [class]="languageType == 'cn' ? 'btn-lang btn btn-raised btn-sm btn-primary ml-1 float-right' : 'btn-lang btn btn-sm btn-outline-primary ml-1 float-right'">
        <img src="./assets/img/flags/cn.png" alt="Chinese" class="langimg">
        <span class="d-none">中文</span>
    </a>
    <a href="javascript:;" (click)="setDataLanguage('en')"
        [class]="languageType == 'en' ? 'btn-lang btn btn-raised btn-sm btn-primary ml-1 float-right' : 'btn-lang btn btn-sm btn-outline-primary ml-1 float-right'">
        <img src="./assets/img/flags/us.png" alt="English" class="langimg">
        <span class="d-none">EN</span>
    </a>
</ng-container>

<div class="row" *ngIf="resizeService.isMobileResolution">
    <div class="col-12">
        <a href="javascript:;" (click)="setDataLanguage('my')"
            [class]="languageType == 'my' ? 'btn-lang btn btn-raised btn-sm btn-primary ml-1 float-right' : 'btn-lang btn btn-sm btn-outline-primary ml-1 float-right'">
            <img src="./assets/img/flags/my.png" alt="Malay" class="langimg">
            <span class="d-none">BM</span>
        </a>
        <a href="javascript:;" (click)="setDataLanguage('cn')"
            [class]="languageType == 'cn' ? 'btn-lang btn btn-raised btn-sm btn-primary ml-1 float-right' : 'btn-lang btn btn-sm btn-outline-primary ml-1 float-right'">
            <img src="./assets/img/flags/cn.png" alt="Chinese" class="langimg">
            <span class="d-none">中文</span>
        </a>
        <a href="javascript:;" (click)="setDataLanguage('en')"
            [class]="languageType == 'en' ? 'btn-lang btn btn-raised btn-sm btn-primary ml-1 float-right' : 'btn-lang btn btn-sm btn-outline-primary ml-1 float-right'">
            <img src="./assets/img/flags/us.png" alt="English" class="langimg">
            <span class="d-none">EN</span>
        </a>
    </div>
</div>

<textarea *ngIf="languageType == 'en'" class="form-control input-md" [disabled]="disabled" [(ngModel)]="languageEn"
    (ngModelChange)="constructLanguage()" [placeholder]="placeholder" id="nameEn" name="nameEn" type="text"
    [rows]="rows"></textarea>
<textarea *ngIf="languageType == 'cn'" class="form-control input-md" [disabled]="disabled" [(ngModel)]="languageCn"
    (ngModelChange)="constructLanguage()" [placeholder]="placeholder" id="nameCn" name="nameCn" type="text"
    [rows]="rows"></textarea>
<textarea *ngIf="languageType == 'my'" class="form-control input-md" [disabled]="disabled" [(ngModel)]="languageMy"
    (ngModelChange)="constructLanguage()" [placeholder]="placeholder" id="nameMy" name="nameMy" type="text"
    [rows]="rows"></textarea>