<section id="login" [style.background-image]="'assets/img/sidebar-bg/06.jpg'">
  <div class="row auth-height full-height-vh m-0">
      <div class="col-12 d-flex align-items-center justify-content-center">
          <div class="card overflow-hidden">
              <div class="card-content">
                  <div class="card-body auth-img">
                      <div class="row m-0">
                          <div
                              class="col-lg-6 d-none d-lg-flex justify-content-center align-items-center auth-img-bg p-3">
                              <img src="assets/img/gallery/login.png" alt="" width="300" height="230"
                                  class="img-fluid"></div>
                          <div class="col-lg-6 col-12 px-4 py-3">
                              <p>Activating your account</p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>