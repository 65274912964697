import { Component, Output, EventEmitter, OnDestroy, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from '../services/layout.service';
import { Subscription, Observable } from 'rxjs';
import { ConfigService } from '../services/config.service';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { CompanyInfo, RefMember, Customer, CompanyEmployeeStatus } from '../model/company';
import { CompanyService } from '../services/company.service';
import { Member } from '../model/member';
import { LocalStorageType } from '../model/system';
import { MemberService } from '../services/member.service';
import { CommonFunction } from '../common/common_function';
import Swal from 'sweetalert2';

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {
  currentLang = "en";
  toggleClass = "ft-maximize";
  placement = "bottom-right";
  public isCollapsed = true;
  layoutSub: Subscription;
  @Output()
  toggleHideSidebar = new EventEmitter<Object>();

  public companies: CompanyInfo[];
  selectedCompanyName: string;
  private selectedCompany: CompanyInfo;
  username: string;

  public config: any = {};

  constructor(public translate: TranslateService, private layoutService: LayoutService, private configService: ConfigService, private router: Router, private authService: AuthService,
    private cmpServ: CompanyService,
    private mmbrServ: MemberService,
    private cdr: ChangeDetectorRef) {
    // const browserLang: string = translate.getBrowserLang();

    let browserLang = localStorage.getItem(LocalStorageType.Language)

    if (!browserLang) {
      browserLang = "en";
    }

    translate.use(browserLang.match(/en|es|pt|de|cn|my/) ? browserLang : "en");

    this.layoutSub = layoutService.changeEmitted$.subscribe(
      direction => {
        const dir = direction.direction;
        if (dir === "rtl") {
          this.placement = "bottom-left";
        } else if (dir === "ltr") {
          this.placement = "bottom-right";
        }
      });

    cmpServ.companyChangeEmitted$.subscribe(
      companyList => {
        ////console.log('NavBar subscribe on Company Change')
        if (companyList) {
          this.updateCompanyDropDown();
        }
      }
    );

    this.username = this.authService.getMember() ? this.authService.getMember().MemberName : null;
  }

  private onCompanySelected() {
    this.selectedCompany = this.companies.find(company => company.CompanyName === this.selectedCompanyName);
    ////console.log('Selected companyid: ' + this.selectedCompany.CompanyID);
    this.authService.setCompany(this.selectedCompany.CompanyID);
    this.authService.setCompanyInfo(this.selectedCompany);
  }

  private changeCompanyRefresh(companyID: string) {
    this.changeCompany(companyID);

    // Refresh page
    window.location.reload();
  }

  private changeCompany(companyID: string) {

    ////console.log('ComapnyID: ' + companyID)
    this.selectedCompany = this.companies.find(company => company.CompanyID === companyID);
    this.selectedCompanyName = this.selectedCompany.CompanyName;
    ////console.log('Selected CompanyName: ' + this.selectedCompany.CompanyName);
    this.authService.setCompany(this.selectedCompany.CompanyID);
    this.authService.setCompanyInfo(this.selectedCompany);
  }

  ngOnInit() {
    this.config = this.configService.templateConf;
  }

  updateCompanyDropDown() {

    // Get template for Department, Level, Position
    this.cmpServ.GetAllDepartmentTemplate().subscribe((res) => {
      // ////console.log('GetAllTemplateDepartment return: ' + JSON.stringify(res))
      if (res) {
        res.forEach((item) => {
          item.CompanyDepartmentID = CommonFunction.GenGUID();
        })
        localStorage.setItem(LocalStorageType.TemplateDepartmentList, JSON.stringify(res))
      }
    })

    this.cmpServ.GetAllPositionTemplate().subscribe((res) => {
      // ////console.log('GetAllTemplatePosition return: ' + JSON.stringify(res))
      if (res) {
        res.forEach((item) => {
          item.CompanyEmployeePositionID = CommonFunction.GenGUID();
        })
        localStorage.setItem(LocalStorageType.TemplatePositionList, JSON.stringify(res))
      }
    })

    this.cmpServ.GetAllLevelTemplate().subscribe((res) => {
      // ////console.log('GetAllTemplateLevel return: ' + JSON.stringify(res))
      if (res) {
        res.forEach((item) => {
          item.CompanyEmployeeLevelID = CommonFunction.GenGUID();
        })
        localStorage.setItem(LocalStorageType.TemplateLevelList, JSON.stringify(res))
      }
    })

    let tempCompany: CompanyInfo[] = JSON.parse(localStorage.getItem(LocalStorageType.CompanyList));

    if (tempCompany) {
      this.companies = tempCompany.filter(x => !x.IsDeleted)
    }

    // Set the companyID
    if (this.companies && this.companies.length > 0) {
      // Set default as 1st company if not selected yet
      if (!this.authService.getCompany()) {
        ////console.log('Selected companyid: ' + this.companies[0].CompanyID);
        this.changeCompany(this.companies[0].CompanyID);
        this.authService.setCompany(this.companies[0].CompanyID);
      }
      else {
        this.changeCompany(this.authService.getCompany());
        this.authService.setCompany(this.authService.getCompany());
      }

      this.getCompanyEmployee();
      this.getCompanyEmployeeModule();
      // });

      // this.cmpServ.GetRefMember().subscribe(refMmbrRes => {
      //   ////console.log('GetRefMember result ' + JSON.stringify(refMmbrRes))

      //   // Populate based on Customer
      //   localStorage.setItem(LocalStorageType.CompanyRefMemberList, JSON.stringify(refMmbrRes))

      //   this.cmpServ.GetAllCustomer().subscribe(res => {
      //     ////console.log('GetAllCustomer result ' + JSON.stringify(res))

      //     localStorage.setItem(LocalStorageType.CustomerList, JSON.stringify(res))
      //   });
      // });
    }
    else {
      console.log('updateCompanyDropDown 7')
      this.companies = []
      this.selectedCompanyName = 'Select Company';

      // Guide user to Create Company page to start up
      this.createCompany();
    }
  }

  async getCompanyEmployee() {
    try {
      var res = await this.cmpServ.GetEmployee().toPromise().catch(error => {
        throw error;
      })

      var memberIDs: string[] = [];

      res.forEach(function (item, index) {
        item.Status = CompanyEmployeeStatus.ConfirmedEmployee
        memberIDs.push(item.MemberRefID)
      })

      let resInvite = await this.cmpServ.GetInviteEmployee().toPromise().catch(error => {
        throw error;
      })

      resInvite.forEach(function (item, index) {
        item.Status = CompanyEmployeeStatus.PendingUserConfirmation
        res.push(item)
      })

      localStorage.setItem(LocalStorageType.EmployeeList, JSON.stringify(res))

      var memberResult = await this.mmbrServ.GetMember(memberIDs.join(',')).toPromise().catch(error => {
        throw error;
      })

      // Merge member into existing list
      if (memberResult) {
        var mmbrList = localStorage.getItem(LocalStorageType.MemberList);

        if (mmbrList && mmbrList != '') {
          var currentList: Member[] = JSON.parse(mmbrList)

          memberResult.forEach(function (item: Member) {
            var memberIndex = currentList.findIndex(x => x.MemberID == item.MemberID)

            if (memberIndex >= 0) {
              // Update to the latest
              currentList[memberIndex] = item;
            }
            else {
              currentList.unshift(item)
            }
          });

          localStorage.setItem(LocalStorageType.MemberList, JSON.stringify(currentList))
        }
        else {
          // Set Server List to local
          localStorage.setItem(LocalStorageType.MemberList, JSON.stringify(memberResult))
        }
      }

      // let refMember = refMemberList.filter(x => x.MemberKey == currentMember.MemberID)
      // ////console.log('refMember count: ' + refMember.length)
      let currentEmployee = res.find(x => x.MemberInfo.MemberID == this.authService.getMember().MemberID)

      this.authService.setEmployee(currentEmployee);
    } catch (e) {
      Swal.fire({
        icon: 'error',
        title: this.translate.instant('Error'),
        html: this.translate.instant(e)
      });
    }
  }

  async getCompanyEmployeeModule() {
    this.cmpServ.GetAllCompanyEmployeeModule().subscribe(async res => {
      try {
        if (res && res.length > 0) {
          this.authService.setEmployeeModule(res);
        } else {
          this.authService.setEmployeeModule([]);
        }
      } catch (e) {
        Swal.fire({
          icon: 'error',
          title: this.translate.instant('Error'),
          html: this.translate.instant(e)
        });
      }
    });
  }

  ngAfterViewInit() {
    if (this.config.layout.dir) {
      setTimeout(() => {
        const dir = this.config.layout.dir;
        if (dir === "rtl") {
          this.placement = "bottom-left";
        } else if (dir === "ltr") {
          this.placement = "bottom-right";
        }
      }, 0);

    }

    ////console.log('NAv bar ngAfterViewInit')

    this.updateCompanyDropDown();
    // ////console.log('nav bar stringify: ' + JSON.stringify(this.companies));

    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
  }

  ChangeLanguage(language: string) {
    localStorage.setItem(LocalStorageType.Language, language)

    this.translate.use(language);
  }

  ToggleClass() {
    if (this.toggleClass === "ft-maximize") {
      this.toggleClass = "ft-minimize";
    } else {
      this.toggleClass = "ft-maximize";
    }
  }

  toggleNotificationSidebar() {
    this.layoutService.emitNotiSidebarChange(true);
  }

  toggleSidebar() {
    const appSidebar = document.getElementsByClassName("app-sidebar")[0];
    if (appSidebar.classList.contains("hide-sidebar")) {
      this.toggleHideSidebar.emit(false);
    } else {
      this.toggleHideSidebar.emit(true);
    }
  }

  Logout() {
    this.authService.logout();
    ////console.log('User logout')
  }

  createCompany() {
    this.router.navigateByUrl("/company/company-wizard");
  }

  goToBuyer() {
    this.router.navigateByUrl("/pages/home");
  }
}
