import { Component, Output, EventEmitter, OnDestroy, OnInit, AfterViewInit, ChangeDetectorRef, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from '../services/layout.service';
import { Subscription, Observable } from 'rxjs';
import { ConfigService } from '../services/config.service';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { CompanyInfo, RefMember, Customer, CompanyEmployeeStatus } from '../model/company';
import { CompanyService } from '../services/company.service';
import { Member } from '../model/member';
import { LocalStorageType } from '../model/system';
import { MemberService } from '../services/member.service';
import { CommonFunction } from '../common/common_function';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';

@Component({
  selector: "app-mobile-top-bar",
  templateUrl: "./mobile-top-bar.component.html",
  styleUrls: ["./mobile-top-bar.component.scss"]
})
export class MobileTopBarComponent {
  @Input() pageTitle: string;
  
  currentRoute: string;
  NotHomePage = true;

  constructor(public translate: TranslateService, private layoutService: LayoutService, private configService: ConfigService, private router: Router, private authService: AuthService,
    private cmpServ: CompanyService,
    private mmbrServ: MemberService,
    private cdr: ChangeDetectorRef,
    private location: Location) {
    // const browserLang: string = translate.getBrowserLang();

    if (router.url.indexOf('/dashboard/') >= 0 || router.url.indexOf('/shop/') >= 0) {
      this.NotHomePage = false;
    } else {
      this.NotHomePage = true;
    }

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
        console.log('NavigationStart');
        console.log(router.url);
      }

      if (event instanceof NavigationEnd) {
        // Hide loading indicator
        console.log('NavigationEnd');
        console.log(router.url);

        if (router.url.indexOf('/dashboard/') >= 0) {
          this.NotHomePage = false;
        } else {
          this.NotHomePage = true;
        }
      }

      if (event instanceof NavigationError) {
        // Hide loading indicator
        console.log('NavigationError');

        // Present error to user
        console.log(event.error);
      }
    });
  }


  backClicked() {
    this.location.back();
  }

  showMenu() {
    
  }

}
