import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable()
export class ResizeService {

  public isMobileResolution: boolean;

  public getIsMobileResolution(): boolean {
    return this.isMobileResolution;
  }

  get onResize$(): Observable<boolean> {
    return this.resizeSubject.asObservable().pipe(distinctUntilChanged());
  }

  private resizeSubject: Subject<boolean>;

  constructor() {
    this.resizeSubject = new Subject();

    if (window.innerWidth < 991) {
      this.isMobileResolution = true;
    } else {
      this.isMobileResolution = false;
    }
  }

  onResize(size: boolean) {
    this.resizeSubject.next(size);
  }

  public getClassColor(i: number) {
    let classString = 'card card-inverse ';

    switch ((6 + i) % 6) {
      case 0:
        classString += 'bg-primary';
        break;
      case 1:
        classString += 'bg-success';
        break;
      case 2:
        classString += 'bg-danger';
        break;
      case 3:
        classString += 'bg-info';
        break;
      case 4:
        classString += 'bg-warning';
        break;
      case 5:
        classString += 'bg-secondary';
        break;

    }

    return classString;
  }

  public getFooterClassColor(i: number) {
    let classString = 'card-footer text-muted ';
    switch ((6 + i) % 6) {
      case 0:
        classString += 'bg-primary';
        break;
      case 1:
        classString += 'bg-success';
        break;
      case 2:
        classString += 'bg-danger';
        break;
      case 3:
        classString += 'bg-info';
        break;
      case 4:
        classString += 'bg-warning';
        break;
      case 5:
        classString += 'bg-secondary';
        break;

    }

    return classString;
  }

  public getFooterBadgeClassColor(i: number) {
    let classString = 'badge white mr-1 ';
    
    switch ((6 + i) % 6) {
      case 5:
        classString += 'bg-primary';
        break;
      case 4:
        classString += 'bg-success';
        break;
      case 3:
        classString += 'bg-danger';
        break;
      case 2:
        classString += 'bg-info';
        break;
      case 1:
        classString += 'bg-warning';
        break;
      case 0:
        classString += 'bg-secondary';
        break;

    }

    return classString;
  }
}