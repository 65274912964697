import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { FullLayoutComponent } from "./layouts/full/full-layout.component";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";

import { Full_ROUTES } from "./shared/routes/full-layout.routes";
import { CONTENT_ROUTES } from "./shared/routes/content-layout.routes";

import { AuthGuard } from './shared/auth/auth-guard.service';
import { FullVisitorLayoutComponent } from './layouts/full-visitor/full-visitor-layout.component';
import { Full_Visitor_ROUTES } from './shared/routes/full-visitor-layout.routes';
import { environment } from 'environments/environment';
import { FullVisitorLandingLayoutComponent } from './layouts/full-visitor-landing/full-visitor-landing-layout.component';
import { Full_Visitor_Landing_ROUTES } from './shared/routes/full-visitor-landing-layout.routes';
import { CommonFunction } from './shared/common/common_function';
import { ShopAuthGuard } from './shared/auth/shop-auth-guard.service';
import { ShopLayoutComponent } from './layouts/shop/shop-layout.component';
import { Shop_ROUTES } from './shared/routes/shop-layout.routes';
import { ActivationComponent } from './pages/activation/activation.component';
import { Shop_Alone_ROUTES } from './shared/routes/shop.routes';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'pages/home',
    pathMatch: 'full',
  },
  { path: '', component: FullLayoutComponent, data: { title: 'full Views' }, children: Full_ROUTES, canActivate: [ShopAuthGuard] },
  { path: '', component: ContentLayoutComponent, data: { title: 'content Views' }, children: CONTENT_ROUTES, canActivate: [AuthGuard] },
  { path: '', component: FullVisitorLayoutComponent, data: { title: 'full visitor Views' }, children: Full_Visitor_ROUTES, canActivate: [AuthGuard] },
  { path: '', component: FullVisitorLandingLayoutComponent, data: { title: 'full visitor landing Views' }, children: Full_Visitor_Landing_ROUTES, canActivate: [AuthGuard] },
  {
    path: '**',
    redirectTo: 'pages/error'
  }
];

const isoAppRoutes: Routes = [
  {
    path: '',
    redirectTo: 'pages/landing',
    pathMatch: 'full',
  },
  { path: ':shopName', data: { title: 'shop view' }, children: Shop_Alone_ROUTES },
  { path: 'activate/:email/:code', component: ActivationComponent, data: { title: 'shop view' } },
  { path: 'activate/:email/:code/:shopName', component: ActivationComponent, data: { title: 'shop view' } },
  { path: '', component: ShopLayoutComponent, data: { title: 'shop Views' }, children: Shop_ROUTES, canActivate: [AuthGuard] },
  { path: '', component: FullLayoutComponent, data: { title: 'full Views' }, children: Full_ROUTES, canActivate: [AuthGuard] },
  { path: '', component: ContentLayoutComponent, data: { title: 'content Views' }, children: CONTENT_ROUTES, canActivate: [AuthGuard] },
  { path: '', component: FullVisitorLayoutComponent, data: { title: 'full visitor Views' }, children: Full_Visitor_ROUTES, canActivate: [AuthGuard] },
  { path: '', component: FullVisitorLandingLayoutComponent, data: { title: 'full visitor landing Views' }, children: Full_Visitor_Landing_ROUTES, canActivate: [AuthGuard] },
  {
    path: '**',
    redirectTo: 'pages/error'
  }
];

// const platformRoute = (switch (CommonFunction.GetPlatform()) {
//   case "ISO":
//     return isoAppRoutes;
//     break;
//   case "eComm":
//     return appRoutes;
//     break;
//   default:
//     return appRoutes;
//     break;
// })

export class AppRoutingSelector {
  static getAppRoute(): Routes {
    switch (CommonFunction.GetPlatform()) {
      case "ISO":
        return isoAppRoutes;
      case "eComm":
        return appRoutes;
      case "App":
        return isoAppRoutes;
      default:
        return appRoutes;
    }
  }
}

@NgModule({
  imports: [RouterModule.forRoot(AppRoutingSelector.getAppRoute())], //window.location.href.indexOf(environment.ismsCheck) >= 0 ? isoAppRoutes : appRoutes
  exports: [RouterModule]
})

export class AppRoutingModule {
  constructor() {
    //console.log('HREF: ' + window.location.href)
  }
}