<div class="appHeader">
    <div class="left">
        <a *ngIf="NotHomePage" href="javascript:void(0);" class="headerButton goBack" (click)="backClicked()">
            <i class="ft-chevron-left"></i>
        </a>
        <a *ngIf="!NotHomePage" href="javascript:void(0);" class="headerButton goBack" (click)="showMenu()">
            <i class="ft-menu"></i>
        </a>
    </div>
    <div class="pageTitle">
        {{pageTitle}}
    </div>
    <div class="right">
    </div>
</div>