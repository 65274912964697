import { Routes, RouterModule } from '@angular/router';

// Route for content layout with sidebar, navbar and footer.

export const Full_Visitor_Landing_ROUTES: Routes = [
  {
    path: 'pages',
    loadChildren: () => import('../../pages/full-visitor-landing-pages/full-visitor-landing-pages.module').then(m => m.FullVisitorLandingPagesModule)
  }
];
