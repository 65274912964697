<!--Forgot Password Starts-->
<section id="forgot-password">
  <div class="container-fluid forgot-password-bg">
    <div id="forgotRow" class="col-sm-12 row  m-0  d-flex align-items-center justify-content-center">
      <div class="card">
        <div class="card-content">
          <div class="card-body fg-image">
            <div class="row m-0">
              <div class="col-lg-6 d-none d-lg-block text-center py-3">
                <img src="../../../../assets/img/gallery/forgot.png" alt="" class="img-fluid" width="300"
                  height="230" />
              </div>
              <div class="col-lg-6 col-md-12 bg-white px-4 pt-3">
                <span class="float-right"><a (click)="onCloseModal()" href="javascript:void(0)"><i
                      class="ft-x font-large-1 text-muted"></i></a></span>

                <h4 class="card-title mb-2" translate>forgot.title</h4>
                <div *ngIf="!isResetPassword">
                  <p class="card-text mb-3" translate>forgot.desc</p>
                  <form [formGroup]="forgotForm" (ngSubmit)="onSubmit()">
                    <input type="text" class="form-control" [placeholder]="'forgot.email' | translate" formControlName="email" />
                    <control-messages class="errorMsgStyle" [control]="forgotForm.get('email')"></control-messages>

                    <div class="fg-actions d-flex justify-content-between">
                      <div class="login-btn">
                        <button class="btn btn-outline-primary" type="button" (click)="goToLogin()">
                          <span class="text-decoration-none" translate>forgot.backLogin</span>
                        </button>
                      </div>
                      <div class="recover-pass">
                        <button class="btn btn-primary" type="submit" [disabled]="isSubmitting">
                          <span class="text-decoration-none text-white" translate>forgot.recover</span>
                          <i class="ft-loader spinner ml-1" [hidden]="!isSubmitting"></i>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div *ngIf="isResetPassword">
                  <p class="card-text mb-3">
                    Please get reset code from your email address.
                  </p>
                  <form [formGroup]="resetPasswordForm" (ngSubmit)="onResetPassword()">
                    <input type="text" class="form-control" placeholder="Email" formControlName="email"
                      [disabled]="true" />
                    <control-messages class="errorMsgStyle" [control]="resetPasswordForm.get('email')">
                    </control-messages>

                    <input type="text" class="form-control" placeholder="Reset Code" formControlName="token" />
                    <control-messages class="errorMsgStyle" [control]="resetPasswordForm.get('token')">
                    </control-messages>

                    <input type="password" class="form-control" placeholder="Password" formControlName="password" />
                    <control-messages class="errorMsgStyle" [control]="resetPasswordForm.get('password')">
                    </control-messages>

                    <input type="password" class="form-control" placeholder="Confirm Password" formControlName="confirmPassword" />
                    <control-messages class="errorMsgStyle-2" [control]="resetPasswordForm.get('confirmPassword')">
                    </control-messages>

                    <div class="fg-actions d-flex justify-content-between">
                      <div class="login-btn">
                        <button class="btn btn-outline-primary" type="button" (click)="goToLogin()">
                          <span class="text-decoration-none">Back To Login</span>
                        </button>
                      </div>
                      <div class="recover-pass">
                        <button class="btn btn-primary" type="submit" [disabled]="isSubmitting">
                          <span class="text-decoration-none text-white">Reset </span>
                          <i class="ft-loader spinner" [hidden]="!isSubmitting"></i>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--Forgot Password Ends-->