import { Component, ElementRef, AfterViewInit, HostListener } from '@angular/core';
import { ResizeService } from './resize.service';

@Component({
    selector: 'app-size-detector',
    template: '<div [hidden]="true" *ngFor="let s of sizes" class="{{s.css + \' \' + (prefix + s.id) }}"></div>'
})

export class SizeDetectorComponent implements AfterViewInit {
    isMobile: boolean = false;

    prefix = 'is-';
    sizes = [
        {
            id: SCREEN_SIZE.XS, name: 'xs',
            css: `d-block d-sm-none`
        },
        {
            id: SCREEN_SIZE.SM, name: 'sm',
            css: `d-none d-sm-block d-md-none`
        },
        {
            id: SCREEN_SIZE.MD, name: 'md',
            css: `d-none d-md-block d-lg-none`
        },
        {
            id: SCREEN_SIZE.LG, name: 'lg',
            css: `d-none d-lg-block d-xl-none`
        },
        {
            id: SCREEN_SIZE.XL, name: 'xl',
            css: `d-none d-xl-block`
        },
    ];

    constructor(private elementRef: ElementRef, private resizeSvc: ResizeService) {
    }

    @HostListener("window:resize", [])
    private onResize() {
        this.detectScreenSize();
    }

    ngAfterViewInit() {
        // this.detectScreenSize();
        if (window.innerWidth < 991) {
            this.resizeSvc.isMobileResolution = true;
        } else {
            this.resizeSvc.isMobileResolution = false;
        }
    }

    private detectScreenSize() {
        const currentSize = this.sizes.find(x => {
            const el = this.elementRef.nativeElement.querySelector(`.${this.prefix}${x.id}`);
            const isVisible = window.getComputedStyle(el).display != 'none';

            return isVisible;
        });

        if (currentSize.id == SCREEN_SIZE.LG || currentSize.id == SCREEN_SIZE.XL) {
            this.isMobile = false;
        } else {
            this.isMobile = true;
        }

        this.resizeSvc.onResize(this.isMobile);
        this.resizeSvc.isMobileResolution = this.isMobile;
    }
}

export enum SCREEN_SIZE {
    XS,
    SM,
    MD,
    LG,
    XL
}