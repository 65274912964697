import { BaseModel } from './base';

export class Member extends BaseModel {
    MemberID: string;
    MemberName: string;
    Email: string;
    EmailCode: string;
    EmailVerifiedDateTime: Date;
    Password: string;
    DeleteFlag: boolean;
    Name: string;
    IdentityID: string;
    Activate: boolean;
    ResetToken: string;
    AccessToken: string;
    Token: string;
    LastLoginDateTime: Date;
    LastLoginIPAddress: string;
    LastLoginLocation: string;
    ContactNo: string;
    PhoneNumber: string;    //depreciated
    PhoneCode: string;
    PhoneVerifiedDateTime: Date;
    Photo: string;
    LastSeenDateTime: Date;
    Profession: string;
    UnitNo: string;
    Address: string;
    Title: string;
    DOB: Date;
    // UpdatedDateTime: Date;
    // LastUpdatedDateTime: Date;
    QR: string;
    ActivationCode: string;
    Gender: string;
    IPDetail: string;
    ReferrerMemberID: string;
    ReferrerCompanyID: string;
    CompanyIDs: string[];
    LoginFailCount: number;
    CodeFailCount: number;
    Locked: boolean;
    IsCreatedByOwner: boolean;

    public constructor(init?: Partial<Member>) {
        super();
        Object.assign(this, init);
    }

    IsValidEmail(): boolean {
        if (this.Email && this.Email.match(
            /[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?/
        )) {
            return true;
        }

        return false;
    }

    IsValidPassword(): boolean {
        if (this.Password && this.Password.length >= 6) {
            return true;
        }

        return false;
    }
}

export class AccessDevice {
    MemberDeviceID: string;
    MemberID: string;
    UDID: string;
    Manufacturer: string;
    Model: string;
    OS: string;
    Carrier: string;
    ScreenWidth: string;
    ScreenHeight: string;
    NotificationAccessID: string;
    PhoneNumber: string;
    CreatedDateTime: Date;

    public constructor(init?: Partial<AccessDevice>) {
        Object.assign(this, init);
    }
}

export class MemberLocation {
    MemberDeviceAccessHistoryID: string;
    MemberDeviceID: string;
    MemberID: string;
    IPAddress: string;
    Longitude: number;
    Latitude: number;
    Address: string;
    CreatedDateTime: Date;

    public constructor(init?: Partial<MemberLocation>) {
        Object.assign(this, init);
    }
}

// export class IPDetail {
//     query: string;
//     status: string;
//     country: string;
//     countryCode: string;
//     regionName: string;
//     city: string;
//     zip: string;
//     lat: string;
//     lon: string;
//     timezone: string;
//     isp: string;
//     org: string;
//     as: string;
// }

export interface IPDetail {
    ip: string;
    city: string;
    region: string;
    region_code: string;
    country: string;
    country_code: string;
    country_code_iso3: string;
    country_capital: string;
    country_tld: string;
    country_name: string;
    continent_code: string;
    in_eu: boolean;
    postal: string;
    latitude: number;
    longitude: number;
    timezone: string;
    utc_offset: string;
    country_calling_code: string;
    currency: string;
    currency_name: string;
    languages: string;
    country_area: number;
    country_population: number;
    asn: string;
    org: string;
}