import { Injectable } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})

//https://w3path.com/angular-8-advance-form-validation/
export class ValidationService {

  constructor(private translate: TranslateService) {
  }

  getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
    let config = {
      required: this.translate.instant('This field is required'),
      invalidCreditCard: this.translate.instant('Is invalid credit card number'),
      invalidEmailAddress: this.translate.instant('Invalid email address'),
      invalidPassword: this.translate.instant('Invalid password. Password must be at least 6 characters long, and contain a number.'),
      minlength: this.translate.instant('Minimum length') + ` ${validatorValue.requiredLength}`,
      invalidMatch: this.translate.instant('Password and Confirm Password do not match.')
    };

    return config[validatorName];
  }

  creditCardValidator(control) {
    // Visa, MasterCard, American Express, Diners Club, Discover, JCB
    if (control.value.toString().match(
        /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/
      )
    ) {
      return null;
    } else {
      return { invalidCreditCard: true };
    }
  }

  emailValidator(control) {
    // RFC 2822 compliant regex
    if (control.value.toString().match(
      /[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?/
      )
    ) {
      return null;
    } else {
      return { invalidEmailAddress: true };
    }
  }

  passwordValidator(control) {
    // {6,100}           - Assert password is between 6 and 100 characters
    // (?=.*[0-9])       - Assert a string has at least one number
    if (control.value.toString().match(/^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{6,100}$/)) {
      return null;
    } else {
      return { invalidPassword: true };
    }
  }

  match(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.invalidMatch) {
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ invalidMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }
}