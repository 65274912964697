<!--Theme customizer Starts-->
<!-- <div #scroller class="scroller border-left-blue-grey border-left-lighten-4 d-none d-sm-none d-md-block">
  <a [hidden]="!isShow" class="customizer-toggle bg-danger" id="customizer-toggle-icon" (click)="scrollTop()">
    <i class="ft-chevrons-up font-medium-4 white align-middle"></i>
  </a>
</div> -->
<!--Theme customizer Ends-->

<button *ngIf="isShow" class="btn btn-raised mr-1 btn-info" (click)="scrollTop()"><i class="ft-chevrons-up font-medium-4 white align-middle"></i></button>

<!-- <a [hidden]="!isShow" class="customizer-toggle bg-danger" id="customizer-toggle-icon" (click)="scrollTop()">
    <i class="ft-chevrons-up font-medium-4 white align-middle"></i>
  </a> -->