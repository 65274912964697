<!--Theme customizer Starts-->
<!-- <div #scroller class="scroller border-left-blue-grey border-left-lighten-4 d-none d-sm-none d-md-block">
  <a [hidden]="!isShow" class="customizer-toggle bg-danger" id="customizer-toggle-icon" (click)="scrollTop()">
    <i class="ft-chevrons-up font-medium-4 white align-middle"></i>
  </a>
</div> -->
<!--Theme customizer Ends-->

<button *ngIf="showButton" class="btn btn-raised mr-1 btn-danger" (click)="create()"><i class="ft-plus font-medium-4 white align-middle"></i></button>
<!-- <a *ngIf="showButton" class="btn btn-floating btn-large bg-danger" (click)="scrollTop()"><i class="ft-plus"></i></a> -->
<!-- <a [hidden]="!isShow" class="customizer-toggle bg-danger" id="customizer-toggle-icon" (click)="scrollTop()">
    <i class="ft-chevrons-up font-medium-4 white align-middle"></i>
  </a> -->