import { Routes, RouterModule } from '@angular/router';

// Route for content layout with sidebar, navbar and footer.

export const Shop_Alone_ROUTES: Routes = [
  {
    path: '',
    loadChildren: () => import('../../pages/shop/shop.module').then(m => m.ShopModule)
  }
];
