<div *ngIf="!resizeService.isMobileResolution || authService.getToken()" #wrapper class="wrapper" [ngClass]="{
    'nav-collapsed': iscollapsed,
    'menu-collapsed': iscollapsed,
    'sidebar-sm': isSidebar_sm,
    'sidebar-lg': isSidebar_lg
  }" [dir]="options.direction">
  <div #appSidebar appSidebar class="app-sidebar" (toggleHideSidebar)="toggleHideSidebar($event)"
    [ngClass]="{ 'hide-sidebar': hideSidebar }" data-active-color="white" [attr.data-background-color]="bgColor"
    [attr.data-image]="bgImage">
    <app-sidebar-visitor></app-sidebar-visitor>
    <div class="sidebar-background" #sidebarBgImage></div>
  </div>
  <app-navbar-visitor (toggleHideSidebar)="toggleHideSidebar($event)"></app-navbar-visitor>
  <div class="main-panel">
    <div class="main-content">
      <div class="content-wrapper">
        <div class="container-fluid">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
  <!-- <app-mobile-bar *ngIf="resizeService.isMobileResolution"></app-mobile-bar> -->
  <app-notification-sidebar></app-notification-sidebar>
  <!-- <app-customizer (directionEvent)="getOptions($event)"></app-customizer> -->
  <app-top-scroller></app-top-scroller>
</div>

<!-- <div #wrapper class="wrapper" [ngClass]="{
  'nav-collapsed': iscollapsed,
  'menu-collapsed': iscollapsed,
  'sidebar-sm': isSidebar_sm,
  'sidebar-lg': isSidebar_lg
}" [dir]="options.direction">
  <div class="main-panel">
    <div class="main-content">
      <app-mobile-login *ngIf="resizeService.isMobileResolution && !authService.getToken()"></app-mobile-login>
    </div>
  </div>
</div> -->