<!-- Navbar (Header) Starts -->
<nav class="header-navbar navbar navbar-expand-lg navbar-light bg-faded">
    <div class="container-fluid">
        <div class="navbar-header">
            <button type="button" class="navbar-toggle d-lg-none float-left" data-toggle="collapse"
                (click)="toggleSidebar()">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>
            <!-- <span class="d-lg-none navbar-right navbar-collapse-toggle">
                <a href="javascript:void(0)" (click)="goToBuyer()" class="btn btn-raised ml-1 btn-round btn-info" translate>Buyer</a>
            </span> -->
            <span class="d-lg-none navbar-right navbar-collapse-toggle">
                <a class="open-navbar-container" (click)="isCollapsed = !isCollapsed"
                    [attr.aria-expanded]="!isCollapsed" aria-controls="navbarSupportedContent">
                    <i class="ft-more-vertical"></i>
                </a>
            </span>
            <!-- <form class="navbar-form navbar-right mt-1" role="search">
                <div class="position-relative has-icon-right">
                    <input type="text" class="form-control round" placeholder="{{ 'Search' | translate }}">
                    <div class="form-control-position">
                        <i class="ft-search"></i>
                    </div>
                </div>
            </form> -->
            <!--
            <div class="dropdown btn-group dropup mr-1 mb-1" ngbDropdown>
                <button type="button" class="btn btn-danger btn-raised">{{selectedCompanyName}}</button>
                <button type="button" class="btn btn-danger btn-raised dropdown-toggle" ngbDropdownToggle
                    aria-haspopup="true" aria-expanded="false">
                    <span class="sr-only">Toggle Dropdown</span>
                </button>
                <div ngbDropdownMenu class="dropdown-menu" role="menu">
                    <a href="javascript:;" (click)="changeCompany(cmp.CompanyID)" class="dropdown-item"
                        *ngFor="let cmp of companies">{{cmp.CompanyName}}</a>
                </div>
            </div> -->

            <!-- <select (change)="onCompanySelected($event.target.value)">
                <option *ngFor="let cmp of companies" [value]="cmp.CompanyID">{{cmp.CompanyName}}</option>
            </select> -->
        </div>
        <div class="navbar-container">
            <div class="collapse navbar-collapse" id="navbarSupportedContent" [ngbCollapse]="isCollapsed">
                <ul class="navbar-nav">
                    <li>
                        <div class="dropdown btn-group btn-group-justified btn-group-raised dropup mt-1" ngbDropdown>
                            <button class="btn btn-outline-primary" id="dropdownMenu2"
                                ngbDropdownToggle><app-span-multi-language [ngModel]="selectedCompanyName"></app-span-multi-language></button>
                            <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownMenu2">
                                <button class="dropdown-item" (click)="changeCompanyRefresh(cmp.CompanyID)"
                                    *ngFor="let cmp of companies"><app-span-multi-language [ngModel]="cmp.CompanyName"></app-span-multi-language></button>
                                <button class="dropdown-item btn-danger"
                                    (click)="createCompany()">{{'Create Company' | translate}}
                                    <i class="ft-plus"></i></button>
                                <!-- <span class="input-group-text">
                                    <a (click)="createCategory()" href="javascript:void(0)"
                                        class="text-decoration-none text-white">+</a></span> -->
                            </div>
                            <!-- <div class="input-group-append">
                            </div> -->
                        </div>
                        <!-- <div *ngIf='companies != null' class="dropdown btn-group dropup mr-1 mb-1" ngbDropdown>
                            <button class="btn btn-outline-primary mr-1" id="dropdownMenu2"
                                ngbDropdownToggle>{{'Create Company'}}</button>
                            <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownMenu2">
                                <button class="dropdown-item" (click)="changeCompany(cmp.CompanyID)"
                                    *ngFor="let cmp of companies">{{cmp.CompanyName}}</button>
                            </div>
                        </div> -->
                    </li>
                    <!-- <li class="nav-item mr-2  d-none d-lg-block">
                        <a href="javascript:;" class="nav-link" id="navbar-fullscreen" appToggleFullscreen
                            (click)="ToggleClass()">
                            <i class="{{toggleClass}} font-medium-3 blue-grey darken-4"></i>
                            <p class="d-none">fullscreen</p>
                        </a>
                    </li> -->
                    <li class="nav-item" ngbDropdown [placement]="placement">
                        <a class="nav-link position-relative" id="dropdownLang" ngbDropdownToggle>
                            <i class="ft-flag font-medium-3 blue-grey darken-4"></i>
                            <p class="d-none">Language</p>
                        </a>
                        <div ngbDropdownMenu aria-labelledby="dropdownLang" class="dropdownLang text-left">
                            <a class="dropdown-item py-1 lang" href="javascript:;" (click)="ChangeLanguage('en')">
                                <img src="./assets/img/flags/us.png" alt="English" class="langimg">
                                <span>English</span>
                            </a>
                            <a class="dropdown-item py-1 lang" href="javascript:;" (click)="ChangeLanguage('cn')">
                                <img src="./assets/img/flags/cn.png" alt="Chinese" class="langimg">
                                <span>中文</span>
                            </a>
                            <a class="dropdown-item py-1 lang" href="javascript:;" (click)="ChangeLanguage('my')">
                                <img src="./assets/img/flags/my.png" alt="Malay" class="langimg">
                                <span>Malay</span>
                            </a>
                        </div>
                    </li>
                    <!-- <li class="nav-item" ngbDropdown [placement]="placement">
                        <a class="nav-link position-relative" id="dropdownBasic2" ngbDropdownToggle>
                            <i class="ft-bell font-medium-3 blue-grey darken-4"></i>
                            <span class="notification badge badge-pill badge-danger">4</span>
                            <p class="d-none">Notifications</p>
                        </a>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic2" class="notification-dropdown">
                            <div class="noti-list" [perfectScrollbar]>
                                <a
                                    class="dropdown-item noti-container py-3 border-bottom border-bottom-blue-grey border-bottom-lighten-4">
                                    <i class="ft-bell info float-left d-block font-large-1 mt-1 mr-2"></i>
                                    <span class="noti-wrapper">
                                        <span class="noti-title line-height-1 d-block text-bold-400 info">New Order
                                            Received</span>
                                        <span class="noti-text">Lorem ipsum dolor sit ametitaque in, et!</span>
                                    </span>
                                </a>
                                <a
                                    class="dropdown-item noti-container py-3 border-bottom border-bottom-blue-grey border-bottom-lighten-4">
                                    <i class="ft-bell warning float-left d-block font-large-1 mt-1 mr-2"></i>
                                    <span class="noti-wrapper">
                                        <span class="noti-title line-height-1 d-block text-bold-400 warning">New User
                                            Registered</span>
                                        <span class="noti-text">Lorem ipsum dolor sit ametitaque in </span>
                                    </span>
                                </a>
                                <a
                                    class="dropdown-item noti-container py-3 border-bottom border-bottom-blue-grey border-bottom-lighten-4">
                                    <i class="ft-bell danger float-left d-block font-large-1 mt-1 mr-2"></i>
                                    <span class="noti-wrapper">
                                        <span class="noti-title line-height-1 d-block text-bold-400 danger">New Order
                                            Received</span>
                                        <span class="noti-text">Lorem ipsum dolor sit ametest?</span>
                                    </span>
                                </a>
                                <a class="dropdown-item noti-container py-3">
                                    <i class="ft-bell success float-left d-block font-large-1 mt-1 mr-2"></i>
                                    <span class="noti-wrapper">
                                        <span class="noti-title line-height-1 d-block text-bold-400 success">New User
                                            Registered</span>
                                        <span class="noti-text">Lorem ipsum dolor sit ametnatus aut.</span>
                                    </span>
                                </a>
                            </div>
                            <a
                                class="noti-footer primary text-center d-block border-top border-top-blue-grey border-top-lighten-4 text-bold-400 py-1">Read
                                All Notifications</a>
                        </div>
                    </li> -->
                    <li class="nav-item" ngbDropdown [placement]="placement">
                        <a class="nav-link position-relative" id="dropdownBasic3" ngbDropdownToggle>
                            <span class="d-none font-medium-3 mr-1 text-bold-400" *ngIf="username">{{username}}</span>
                            <i class="ft-user font-medium-3 blue-grey darken-4"></i>
                            <p class="d-none">User Settings</p>
                        </a>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic3" class="text-left">
                            <a class="dropdown-item py-1" routerLink="/pages/profile">
                                <i class="ft-user mr-2"></i>
                                <span>{{'My Profile' | translate}}</span>
                            </a>
                            <!-- <a class="dropdown-item py-1" routerLink="/inbox">
                                <i class="ft-mail mr-2"></i>
                                <span>{{'My Inbox' | translate}}</span>
                            </a>
                            <a class="dropdown-item py-1" href="javascript:;">
                                <i class="ft-settings mr-2"></i>
                                <span>{{'Settings' | translate}}</span>
                            </a> -->
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="javascript:;" (click)="Logout()">
                                <i class="ft-power mr-2"></i>
                                <span>{{'Logout' | translate}}</span>
                            </a>
                        </div>
                    </li>
                    <li class="nav-item mr-2  d-none d-lg-block">
                        <a href="javascript:void(0)" (click)="goToBuyer()" 
                        class="btn btn-raised ml-1 btn-round btn-info mt-1" translate>Buyer</a>
                    </li>
                    <!-- <li class="nav-item d-none d-lg-block">
                        <a class="nav-link position-relative notification-sidebar-toggle"
                            (click)="toggleNotificationSidebar();">
                            <i class="ft-align-left font-medium-3 blue-grey darken-4"></i>
                            <p class="d-none">Notifications Sidebar</p>
                        </a>
                    </li> -->

                </ul>
            </div>
        </div>
    </div>
</nav>
<!-- Navbar (Header) Ends -->