import { Component, Input, forwardRef, ChangeDetectionStrategy } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LanguageData } from '../model/system';
import { ResizeService } from '../services/resize.service';

@Component({
  selector: 'app-textarea-multi-language',
  templateUrl: './textarea-multi-language.component.html',
  styleUrls: ['./textarea-multi-language.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextAreaMultiLanguageComponent),
      multi: true,
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

// Reference: https://medium.com/javascript-in-plain-english/use-your-own-components-in-angular-forms-962b46025b87
export class TextAreaMultiLanguageComponent implements ControlValueAccessor {
  languageType: string = 'en'
  languageEn: string;
  languageCn: string;
  languageMy: string;

  @Input() rows: number;
  // @Input() 
  disabled: boolean;
  @Input() placeholder: string = "";

  data: LanguageData = new LanguageData();

  constructor(
    public resizeService: ResizeService,) { }
  onChange: any = () => { }
  onTouch: any = () => { }
  val = ""

  set value(val) {
    if (val !== undefined && this.val !== val) {
      this.val = val

      if (this.val) {
        try {
          this.data = JSON.parse(this.val);

          if (!this.data.cn && !this.data.en && !this.data.my) {
            this.data = new LanguageData();

            throw 'Not valid data'
          }

        } catch (e) {
          console.log('textarea: error')
          this.data.en = this.val;
          this.data.cn = this.val;
          this.data.my = this.val;
        }
      }

      this.extractLanguageFromInput();
      this.onChange(JSON.stringify(this.data))
      this.onTouch(val)
    }
  }

  writeValue(value: any) {
    this.value = value
  }

  registerOnChange(fn: any) {
    this.onChange = fn
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn
  }

  setDataLanguage(lang) {
    this.languageType = lang;
  }

  /**    
  * This function is called when the control status changes to or from "DISABLED".
  * Depending on the value, it will enable or disable the appropriate DOM element.
  * @param isDisabled
  */
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  extractLanguageFromInput() {
    this.languageCn = this.data.cn;
    this.languageEn = this.data.en;
    this.languageMy = this.data.my;
  }

  constructLanguage() {
    this.data.en = this.languageEn;
    this.data.cn = this.languageCn;
    this.data.my = this.languageMy;

    // To notify UI data has updated (JSON format)
    this.onChange(JSON.stringify(this.data))
  }
}

// import { Component, Output, EventEmitter, Input, ViewChild, NgZone, forwardRef, ChangeDetectorRef } from '@angular/core';
// import { GlobalService } from 'app/shared/services/global.service';
// import { CompanyService } from 'app/shared/services/company.service';
// import { AuthService } from 'app/shared/auth/auth.service';
// import { Router, ActivatedRoute } from '@angular/router';
// import { CompanyInfo, Customer } from 'app/shared/model/company';
// import { Member } from '../model/member';
// import { MemberService } from '../services/member.service';
// import { CommonFunction } from '../common/common_function';
// import { LocalStorageType } from '../model/system';
// import Swal from 'sweetalert2';
// import { TranslateService } from '@ngx-translate/core';
// import { interval, Subject } from 'rxjs';
// import { map, takeUntil } from 'rxjs/operators';
// import * as moment from 'moment';
// import * as bcrypt from 'bcryptjs';
// import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

// @Component({
//   selector: 'app-textarea-multi-language',
//   templateUrl: './textarea-multi-language.component.html',
//   styleUrls: ['./textarea-multi-language.component.scss'],
//   providers: [
//     {
//       provide: NG_VALUE_ACCESSOR,
//       /* tslint:disable-next-line: no-use-before-declare */
//       useExisting: forwardRef(() => TextAreaMultiLanguageComponent),
//       multi: true,
//     }
//   ]
// })

// export class TextAreaMultiLanguageComponent implements ControlValueAccessor {
//   languageType: string = 'en'
//   languageEn: string;
//   languageCn: string;
//   languageMy: string;

//   // @Input() ngModel: string;
//   @Input() rows: number;
//   @Input() placeholder: string;
//   // @Output() ngModelChange = new EventEmitter<string>();

//   data: LanguageData;

//   ngOnInit() {
//     // console.log('this.ngModel: ' + JSON.stringify(this.ngModel))
//   }

//   onModelChange($event) {
//     console.log('onModelChange: ')
//     this.writeValue($event);
//   }

//   constructor(
//     private cdr: ChangeDetectorRef) {
//     // this.rows = 1;

//     // console.log('this.ngModel: ' + JSON.stringify(this.model))

//     // this.extractLanguageFromInput();
//   }

//   private onChangeCallback = (v: any) => { };
//   private onTouchedCallback = (v: any) => { };
//   disabled: boolean;
//   model: string;

//   writeValue(obj: any): void {
//     const shadow = this.model;
//     this.model = obj;

//     if (shadow != obj) {
//       if (this.cdr)
//         this.cdr.markForCheck();

//       if (!this.model || this.model === undefined) {
//         this.data = new LanguageData();
//       } else {
//         try {
//           this.data = JSON.parse(this.model);
//         } catch (e) {
//           this.data = new LanguageData();
//           this.data.cn = this.model;
//           this.data.en = this.model;
//           this.data.my = this.model;
//         }
//         // this.ngModelChange.emit(JSON.stringify(this.data));
//       }
//       this.extractLanguageFromInput();

//       this.onChangeCallback(JSON.stringify(this.data));
//     }
//   }
//   registerOnChange(fn: any): void {
//     console.log('registerOnChange: ')
//     this.onChangeCallback = fn;
//   }
//   registerOnTouched(fn: any): void {
//     console.log('registerOnTouched: ')
//     this.onTouchedCallback = fn;
//   }
//   setDisabledState?(isDisabled: boolean): void {
//     console.log('setDisabledState: ')
//     this.disabled = isDisabled;
//   }

//   setDataLanguage(lang) {
//     this.languageType = lang;

//     // // Extract language parts out
//     // switch (this.languageType) {
//     //   case 'en':
//     //     break;
//     //   case 'cn':
//     //     break;
//     //   case 'my':
//     //     break;
//     // }
//   }

//   extractLanguageFromInput() {
//     this.languageCn = this.data.cn;
//     this.languageEn = this.data.en;
//     this.languageMy = this.data.my;
//   }

//   constructLanguage() {
//     this.data.en = this.languageEn;
//     this.data.cn = this.languageCn;
//     this.data.my = this.languageMy;
//     // switch (this.languageType) {
//     //   case 'en':
//     //     break;
//     //   case 'cn':
//     //     break;
//     //   case 'my':
//     //     break;
//     // }

//     // this.ngModelChange.emit(JSON.stringify(this.data));
//       this.onChangeCallback(JSON.stringify(this.data));
//   }
// }

// export class LanguageData {
//   en: string;
//   cn: string;
//   my: string;
// }