import { Input } from '@angular/core';
import { Component, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "app-mobile-bar",
  templateUrl: "./mobile-bar.component.html",
  styleUrls: ["./mobile-bar.component.scss"]
})
export class MobileBarComponent {
  @Input() showQROnly: boolean = false;

  mobileFunction = [{ name: "Rewards", icon: "icon-present", link: "/shop/rewards" }, { name: "Scanner", icon: "ft-maximize", link: "/shop/scanner" }, { name: "Profile", icon: "ft-user", link: "/shop/profile" }];

  constructor(public translate: TranslateService, private router: Router) {
    // const browserLang: string = translate.getBrowserLang();
  }

  ngAfterViewInit() {
    console.log('MobileBarComponent showQROnly: ' + this.showQROnly)
    if(this.showQROnly) {
      this.mobileFunction = [{ name: "Scanner", icon: "ft-maximize", link: "/pages/scanner" }];
    }
  }

  goTo(link) {
    this.router.navigateByUrl(link);
  }

}
