import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Member, IPDetail, AccessDevice } from '../model/member';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { CommonFunction } from '../common/common_function';
import { LayoutService } from './layout.service';

@Injectable({
  providedIn: 'root'
})

export class MemberService {
  
  // Base url
  baseurl = CommonFunction.ServerURL + '/api/member';

  constructor(private http: HttpClient,
    private layoutService: LayoutService,
    private authService: AuthService) { }

  // Http Headers
  httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': CommonFunction.ServerURL,
      'Content-Type': 'application/json',
    })
  }

  // POST
  Login(data): Observable<Member> {
    return this.http.post<Member>(this.baseurl + '/login?data=' + CommonFunction.encryptMember(JSON.stringify(data)), null, this.httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // GET
  GetMember(memberIDsString): Observable<Member[]> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        // 'Company': this.authService.getCompany(),
      })
    }

    return this.http.get<Member[]>(this.baseurl + '/getmember?memberref=' + memberIDsString, httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // GET
  GetMemberByMemberID(memberID): Observable<Member> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        // 'Company': this.authService.getCompany(),
      })
    }

    return this.http.get<Member>(this.baseurl + '/getbymemberid?id=' + memberID, httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // GET
  IsMemberNameValid(data): Observable<Member> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }

    return this.http.post<Member>(this.baseurl + '/IsMemberNameValid', JSON.stringify(data), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // GET
  IsEmailValid(data): Observable<Member> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }

    return this.http.post<Member>(this.baseurl + '/IsEmailValid', JSON.stringify(data), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // POST
  Register(data): Observable<Member> {
    return this.http.post<Member>(this.baseurl + '/register', JSON.stringify(data), this.httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // POST
  RegisterFromShop(data, shopCode): Observable<Member> {
    return this.http.post<Member>(this.baseurl + '/registerfromcomp?shopCode=' + shopCode, JSON.stringify(data), this.httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // POST
  RegisterShopMember(data, shopCode): Observable<Member> {
    return this.http.post<Member>(this.baseurl + '/registershopmember&shopCode=' + shopCode, JSON.stringify(data), this.httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // POST
  Activate(data): Observable<Member> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        // 'Company': this.authService.getCompany(),
      })
    }

    return this.http.post<Member>(this.baseurl + '/activateaccount', JSON.stringify(data), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // POST
  RequestCode(data): Observable<Member> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        // 'Company': this.authService.getCompany(),
      })
    }

    return this.http.post<Member>(this.baseurl + '/requestactivationcode', JSON.stringify(data), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // POST
  AddDevice(data): Observable<AccessDevice> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        // 'Company': this.authService.getCompany(),
      })
    }

    return this.http.post<AccessDevice>(this.baseurl + '/adddevice', JSON.stringify(data), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // POST
  UpdateMember(data): Observable<Member> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        // 'Company': this.authService.getCompany(),
      })
    }

    return this.http.post<Member>(this.baseurl + '/UpdateMember', JSON.stringify(data), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // // POST
  // GetExternal(): Observable<IPDetail> {
  //   return this.http.get<IPDetail>('http://ip-api.com/json')
  //     .pipe(
  //       //retry(1)
  //       catchError(this.errorHandl)
  //     )
  // }

  // POST
  GetExternal(): Observable<IPDetail> {
    return this.http.get<IPDetail>('https://ipapi.co/json/')
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // POST
  RequestEmailCode(data): Observable<Member> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany(),
      })
    }

    return this.http.post<Member>(this.baseurl + '/RequestEmailCode', JSON.stringify(data), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // POST
  RequestReset(data): Observable<Member> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }

    return this.http.post<Member>(this.baseurl + '/requestreset', JSON.stringify(data), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // POST
  ResetPassword(data): Observable<Member> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }

    return this.http.post<Member>(this.baseurl + '/resetpassword', JSON.stringify(data), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // POST
  ActivateEmail(data): Observable<Member> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany(),
      })
    }

    return this.http.post<Member>(this.baseurl + '/ActivateEmail', JSON.stringify(data), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // POST
  RequestPhoneCode(data): Observable<Member> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany(),
      })
    }

    return this.http.post<Member>(this.baseurl + '/RequestPhoneCode', JSON.stringify(data), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  // POST
  ActivatePhone(data): Observable<Member> {
    // Http Headers
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.getToken(),
        'Company': this.authService.getCompany(),
      })
    }

    return this.http.post<Member>(this.baseurl + '/ActivatePhone', JSON.stringify(data), httpOptions)
      .pipe(
        //retry(1)
        catchError(this.errorHandl)
      )
  }

  //   // POST
  //   CreateBug(data): Observable<Bug> {
  //     return this.http.post<Bug>(this.baseurl + '/bugtracking/', JSON.stringify(data), this.httpOptions)
  //     .pipe(
  //       //retry(1)
  //       catchError(this.errorHandl)
  //     )
  //   }  

  //   // GET
  //   GetIssue(id): Observable<Bug> {
  //     return this.http.get<Bug>(this.baseurl + '/bugtracking/' + id)
  //     .pipe(
  //       //retry(1)
  //       catchError(this.errorHandl)
  //     )
  //   }

  //   // GET
  //   GetIssues(): Observable<Bug> {
  //     return this.http.get<Bug>(this.baseurl + '/bugtracking/')
  //     .pipe(
  //       //retry(1)
  //       catchError(this.errorHandl)
  //     )
  //   }

  //   // PUT
  //   UpdateBug(id, data): Observable<Bug> {
  //     return this.http.put<Bug>(this.baseurl + '/bugtracking/' + id, JSON.stringify(data), this.httpOptions)
  //     .pipe(
  //       //retry(1)
  //       catchError(this.errorHandl)
  //     )
  //   }

  //   // DELETE
  //   DeleteBug(id){
  //     return this.http.delete<Bug>(this.baseurl + '/bugtracking/' + id, this.httpOptions)
  //     .pipe(
  //       //retry(1)
  //       catchError(this.errorHandl)
  //     )
  //   }

  // Error handling
  errorHandl(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      ////console.log('Member service error: ' + errorMessage);

      // Show connection error
      if(error.status == 0) {
        errorMessage = 'Failed to connect to server';
      } 
      // Do not show full detail of error, either show Internal Error from API or Error Code only
      else if(error.error) {
        errorMessage = error.error;
      } else {
        errorMessage = `Error Code: ${error.status}`;
      }
    }
    return throwError(errorMessage);
  }
}