<!--Login Page Starts-->
<section id="login">
  <div class="container-fluid">
    <div class="row m-0">

      <div class="col-12 align-items-center justify-content-center">
        <!-- Login section-->
        <div class="card overflow-hidden" *ngIf="currentType == 1">
          <div class="card-content">
            <div class="card-body auth-img">
              <div class="row m-0">
                <div class="col-lg-6 d-none d-lg-flex justify-content-center align-items-center auth-img-bg p-3">
                  <img src="assets/img/gallery/login.png" alt="" width="300" height="230" class="img-fluid"></div>
                <div class="col-lg-6 col-12 px-4 py-3">
                  <span class="float-right"><a (click)="onCloseModal()" href="javascript:void(0)"><i
                        class="ft-x font-large-1 text-muted"></i></a></span>
                  <h4 class="mb-2 card-title">Welcome back</h4>
                  <p>Login to your account.</p>
                  <form class="form">
                    <div class="form-body">
                      <div class="form-group">
                        <input type="text" [(ngModel)]="member.MemberName" id="MemberName" name="MemberName"
                          placeholder="Username / Email" class="form-control">
                      </div>
                      <div class="form-group">
                        <input type="password" minlength="6" [(ngModel)]="member.Password" id="Password" name="Password"
                          placeholder="Password" class="form-control">
                      </div>
                      <div class="form-group">
                        <button type="button" class="btn btn-raised btn-primary btn-block" (click)="onSubmit()">
                          Login
                          <span style="margin-left:10px;">
                            <i class="ft-loader spinner" [hidden]="!isSubmitting"></i>
                          </span>
                        </button>
                      </div>
                      <div class="d-sm-flex justify-content-between mb-3 font-small-2 text-center"><a
                          (click)="setType(2)" href="javascript:void(0);">Forgot
                          Password?</a>
                      </div>
                      <p class="text-center text-muted font-small-2 pt-3">Don't have an account?</p>
                      <p class="text-center" (click)="setType(3)">Register</p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Forgot Password section-->
        <div class="card overflow-hidden" *ngIf="currentType == 2">
          <div class="card-content">
            <div class="card-body auth-img">
              <div class="row m-0">
                <div class="col-lg-6 d-none d-lg-flex justify-content-center align-items-center auth-img-bg p-3">
                  <img src="assets/img/gallery/login.png" alt="" width="300" height="230" class="img-fluid"></div>
                <div class="col-lg-6 col-12 px-4 py-3">
                  <h4 class="mb-2 card-title">Reset Password</h4>
                  <p>Please enter your email address and we'll send you Reset Code to reset your password.</p>
                  <form class="form">
                    <div class="form-body">
                      <div class="form-group">
                        <input type="text" [(ngModel)]="member.Email" id="ResetPasswordEmail" name="ResetPasswordEmail"
                          placeholder="Email" class="form-control">
                      </div>

                      <p class="text-muted" *ngIf="isWaiting">Please request after <span
                          class="text-info">{{countdown.seconds}}</span> seconds.</p>

                      <div class="form-group">
                        <button type="button" class="btn btn-raised btn-warning btn-block" (click)="onSubmit()">
                          Request Code
                          <span style="margin-left:10px;">
                            <i class="ft-loader spinner" [hidden]="!isSubmitting"></i>
                          </span>
                        </button>
                      </div>
                      <div class="form-group mt-3">
                        <button type="button" class="btn btn-outline-primary btn-block" (click)="setType(1)">
                          Back To Login
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Forgot Password Verify Code section-->
        <div class="card overflow-hidden" *ngIf="currentType == 4">
          <div class="card-content">
            <div class="card-body auth-img">
              <div class="row m-0">
                <div class="col-lg-6 d-none d-lg-flex justify-content-center align-items-center auth-img-bg p-3">
                  <img src="assets/img/gallery/login.png" alt="" width="300" height="230" class="img-fluid"></div>
                <div class="col-lg-6 col-12 px-4 py-3">
                  <h4 class="mb-2 card-title">Reset Password</h4>
                  <p>Please enter Reset Code from your email {{member.Email}}.</p>
                  <form class="form">
                    <div class="form-body">
                      <div class="form-group">
                        <input type="text" [(ngModel)]="member.ResetToken" id="ResetPasswordToken"
                          name="ResetPasswordToken" placeholder="Reset Code" class="form-control">
                      </div>
                      <div class="form-group">
                        <input type="password" minlength="6" [(ngModel)]="member.Password" id="ResetPasswordPassword"
                          name="ResetPasswordPassword" placeholder="Password" class="form-control">
                      </div>
                      <div class="form-group">
                        <input type="password" minlength="6" [(ngModel)]="confirmPassword"
                          id="ResetPasswordConfirmPassword" name="ResetPasswordConfirmPassword"
                          placeholder="Confirm Password" class="form-control">
                      </div>
                      <div class="form-group">
                        <button type="button" class="btn btn-raised btn-warning btn-block" (click)="onSubmit()">
                          {{'Reset' | translate}}
                          <span style="margin-left:10px;">
                            <i class="ft-loader spinner" [hidden]="!isSubmitting"></i>
                          </span>
                        </button>
                      </div>
                      <div class="form-group mt-3">
                        <button type="button" class="btn btn-outline-primary btn-block" (click)="setType(1)">
                          Back To Login
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Register Password section-->
        <div class="card overflow-hidden" *ngIf="currentType == 3">
          <div class="card-content">
            <div class="card-body auth-img">
              <div class="row m-0">
                <div class="col-lg-6 d-none d-lg-flex justify-content-center align-items-center auth-img-bg p-3">
                  <img src="assets/img/gallery/login.png" alt="" width="300" height="230" class="img-fluid"></div>
                <div class="col-lg-6 col-12 px-4 py-3">
                  <h4 class="mb-2 card-title">Create Account</h4>
                  <p>Fill the below form to create a new account.</p>
                  <form class="form">
                    <div class="form-body">
                      <div class="form-group">
                        <input type="text" [(ngModel)]="member.MemberName" id="RegisterMemberName"
                          name="RegisterMemberName" placeholder="Username" class="form-control">
                      </div>
                      <div class="form-group">
                        <input type="text" [(ngModel)]="member.Email" id="RegisterEmail" name="RegisterEmail"
                          placeholder="Email" class="form-control">
                      </div>
                      <div class="form-group">
                        <input type="password" minlength="6" [(ngModel)]="member.Password" id="RegisterPassword"
                          name="RegisterPassword" placeholder="Password" class="form-control">
                      </div>
                      <div class="form-group">
                        <input type="password" minlength="6" [(ngModel)]="confirmPassword" id="RegisterConfirmPassword"
                          name="RegisterConfirmPassword" placeholder="Confirm Password" class="form-control">
                      </div>

                      <div class="custom-control custom-checkbox custom-control-inline">
                        <input type="checkbox" id="customCheckboxInline1" formControlName="cbTermCond"
                          [(ngModel)]="tncChecked" name="customCheckboxInline1" class="custom-control-input" />
                        <label class="custom-control-label" for="customCheckboxInline1" translate>register.tnc</label>
                      </div>

                      <div class="form-group">
                        <button type="button" class="btn btn-raised btn-primary btn-block" (click)="onSubmit()">
                          Register
                          <span style="margin-left:10px;">
                            <i class="ft-loader spinner" [hidden]="!isSubmitting"></i>
                          </span>
                        </button>
                      </div>
                      <div class="form-group mt-3">
                        <button type="button" class="btn btn-outline-primary btn-block" (click)="setType(1)">
                          Back To Login
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div id="loginRow" class="col-12 d-flex align-items-center justify-content-center">
        <div class="card">
          <div class="card-content">
            <div class="card-body login-img">
              <div class="row m-0">
                <div class="col-lg-6 d-lg-block d-none py-2 px-3 text-center align-middle">
                  <img src="../../assets/img/gallery/login.png" alt="" class="img-fluid mt-5" width="400"
                    height="230" />
                </div>
                <div class="col-lg-6 col-md-12 bg-white px-4 pt-3">
                  <span class="float-right"><a (click)="onCloseModal()" href="javascript:void(0)"><i
                        class="ft-x font-large-1 text-muted"></i></a></span>

                  <h4 class="card-title mb-2" translate>login.title</h4>
                  <p class="card-text mb-3" translate>login.desc</p>
                  <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                    <input formControlName="username" type="text" class="form-control" [placeholder]="'login.username' | translate" />
                    <control-messages class="errorMsgStyle" [control]="loginForm.get('username')"></control-messages>
                    
                    <input formControlName="password" type="password" class="form-control"
                      [placeholder]="'login.password' | translate" />
                    <control-messages class="errorMsgStyle" [control]="loginForm.get('password')"></control-messages>

                    <div class="d-flex justify-content-between mt-2">
                      <div class="remember-me">
                        <div class="custom-control custom-checkbox custom-control-inline mb-3">
                        </div>
                      </div>
                      <div class="forgot-password-option">
                        <a (click)="onForgotPassword()" href="javascript:void(0)"
                          class="text-decoration-none text-primary" translate>login.forgotPassword</a>
                      </div>
                    </div>
                    <div class="fg-actions d-flex justify-content-between">
                      <div class="login-btn">
                        <button class="btn btn-outline-primary" type="button" [disabled]="isLogging"
                          (click)="onRegister()">
                          <span class="text-decoration-none" translate>login.register</span>
                        </button>
                      </div>
                      <div class="recover-pass">
                        <button class="btn btn-primary" [disabled]="isLogging" type="submit">
                          <span class="text-decoration-none text-white" translate>login.login</span>
                          <i class="ft-loader spinner ml-1" [hidden]="!isLogging"></i>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</section>
<!--Login Page Ends-->