import { Routes, RouterModule } from '@angular/router';

// Route for content layout with sidebar, navbar and footer.

export const Shop_ROUTES: Routes = [
  {
    path: 'shop',
    loadChildren: () => import('../../pages/shop-pages/shop-pages.module').then(m => m.ShopPagesModule)
  }
];
