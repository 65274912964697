import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./shared/shared.module";
import { ToastrModule } from "ngx-toastr";
import { AgmCoreModule, GoogleMapsAPIWrapper } from "@agm/core";
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { StoreModule } from "@ngrx/store";

import { 
  PerfectScrollbarModule, 
  PERFECT_SCROLLBAR_CONFIG, 
  PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';

import { AppComponent } from "./app.component";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { FullLayoutComponent } from "./layouts/full/full-layout.component";

import { DragulaService } from "ng2-dragula";
import { AuthService } from "./shared/auth/auth.service";
import { AuthGuard } from "./shared/auth/auth-guard.service";
import { GlobalService } from "./shared/services/global.service";

// Global handle on HTTP error
import { GlobalHttpInterceptorService } from './shared/services/interceptor.service';
// import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FullVisitorLayoutComponent } from './layouts/full-visitor/full-visitor-layout.component';
import { ValidationService } from './shared/common/validation_service';
import { SizeDetectorComponent } from './shared/services/size-detector.component';
import { ResizeService } from './shared/services/resize.service';
import { FullVisitorLandingLayoutComponent } from './layouts/full-visitor-landing/full-visitor-landing-layout.component';
import { ShopAuthGuard } from './shared/auth/shop-auth-guard.service';
import { ShopLayoutComponent } from './layouts/shop/shop-layout.component';
import { ActivationComponent } from './pages/activation/activation.component';
import { FormsModule } from '@angular/forms';

// import { StripeModule } from "stripe-angular"

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false
};

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [AppComponent, FullLayoutComponent, ContentLayoutComponent, FullVisitorLayoutComponent, FullVisitorLandingLayoutComponent, SizeDetectorComponent, ShopLayoutComponent, ActivationComponent],
  imports: [
    BrowserAnimationsModule,
    StoreModule.forRoot({}),
    AppRoutingModule,
    SharedModule,
    FormsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    NgbModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyAII1XaO_AbSfs9uCKGm4M4ArnBy6CH568", //puspakom map api
      libraries: ["places"]
    }),
    PerfectScrollbarModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    // StripeModule.forRoot("pk_test_mfODgG2HpqdswzVLwCOsWG7S00TWwtLqcN")
  ],
  providers: [
    AuthService,
    AuthGuard,
    DragulaService,
    GlobalService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    { provide: HTTP_INTERCEPTORS, useClass: GlobalHttpInterceptorService, multi: true  },  // Global handle on HTTP error
    GoogleMapsAPIWrapper,
    ValidationService,
    ResizeService,
    ShopAuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
